<!--src/components/dispostion/MailClient.vue-->
<template>
  <div>
    <i class="fa-solid fa-envelope mail-icon" @click="openMailClient()">
      <v-tooltip activator="parent" location="bottom">Email öffnen</v-tooltip>
    </i>
    <div v-if="showModal">
      <v-dialog
        v-model="showModal"
        persistent
        :max-width="smAndDown ? 'var(--d-w-sd)' : 'var(--d-w-l)'"
      >
        <v-card>
          <v-card-title>
            <i class="fa-solid fa-envelope mail-icon"></i>E-Mail senden
          </v-card-title>
          <v-card-text>
            <v-select
              :variant="vStyle.input.variant || undefined"
              :rounded="vStyle.input.rounded || undefined"
              :base-color="vStyle.input.baseColor || undefined"
              :color="vStyle.input.color || undefined"
              v-model="selectedEmailObject"
              :items="emailAddresses"
              label="E-Mail wählen"
              item-title="mailaddress"
              return-object
              @update:model-value="updateFullMessage()"
            ></v-select>

            <v-text-field
              :variant="vStyle.input.variant || undefined"
              :rounded="vStyle.input.rounded || undefined"
              :base-color="vStyle.input.baseColor || undefined"
              :color="vStyle.input.color || undefined"
              v-model="mailSubject"
              label="Betreff"
            ></v-text-field>
            <div class="main-container">
              <div
                class="editor-container editor-container_classic-editor"
                ref="editorContainerElement"
              >
                <div class="editor-container__editor">
                  <div ref="editorElement">
                    <ckeditor
                      v-if="isLayoutReady"
                      v-model="fullMessage"
                      :editor="editor"
                      :config="config"
                    />
                  </div>
                </div>
              </div>
            </div>
          </v-card-text>
          <v-card-actions class="action-buttons">
            <v-btn color="abort" @click="closeModal">Abbrechen</v-btn>
            <v-spacer></v-spacer>
            <v-btn icon @click="showAiModelMenu($event)">
              <v-icon>fa-solid fa-rocket</v-icon>
              <v-tooltip activator="parent" location="bottom"
                >Mail mit AI umformulieren</v-tooltip
              >
            </v-btn>
            <v-menu
              v-model="menuAiModelVisible"
              class="ai-menu"
              :style="{
                left: `${menuPosition.x}px`,
                top: `${menuPosition.y}px`,
              }"
              ref="aiMenu"
            >
              <v-list>
                <v-list-item
                  v-for="aiOption in aiOptions"
                  :key="aiOption.value"
                  @click="handleAiOptions(aiOption)"
                >
                  <v-list-item-title>{{ aiOption.label }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
            <v-btn
              class="longpress"
              icon
              @click="showLanguageMenu($event)"
              @contextmenu.prevent="showAiModelMenu($event, true)"
              @longpress="showAiModelMenu($event, true)"
            >
              <v-icon>fa-solid fa-comments</v-icon>
              <v-tooltip activator="parent" location="bottom"
                >mit AI in eine andere Sprache übersetzen</v-tooltip
              >
            </v-btn>
            <!--language selection for Ai Translator-->
            <v-menu
              v-model="menuTranslationVisible"
              class="ai-menu"
              :style="{
                left: `${menuPosition.x}px`,
                top: `${menuPosition.y}px`,
              }"
              ref="aiMenu"
            >
              <v-list>
                <v-list-item
                  v-for="language in languages"
                  :key="language"
                  @click="selectLanguageAndTranslate(language)"
                >
                  <v-list-item-title>{{ language }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
            <v-spacer></v-spacer>
            <v-btn color="success" @click="sendMessage">Senden</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script lang="ts">
import { Customer } from "@/models/customer.model";
import { defineComponent, PropType, toRaw } from "vue";
import DialogService from "@/services/dialog.service";
import { EmailDetails } from "@/models/email-details.model";
import { EmailService } from "@/services/api/email.service";
import {
  generateEmail,
  generateEmailGDPRsave,
  translateMessage,
  translateMessageGDPRsave,
} from "@/services/ai.service";
import { Mandant } from "@/models/mandant.model";
import { SpinnerService } from "@/services/spinner.service";
import { TimelineHelperService } from "@/services/timeline-helper.service";
import { useDisplay } from "vuetify";
import { CandidateList } from "@/models/candidate-list.model";
import { Employee } from "@/models/employee.model";
import ToastService from "@/services/toast.service";
import "ckeditor5/ckeditor5.css";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import translations from "ckeditor5/translations/de.js"; // FIXME

import {
  ClassicEditor,
  AccessibilityHelp,
  Alignment,
  AutoImage,
  AutoLink,
  Autosave,
  BalloonToolbar,
  Base64UploadAdapter,
  BlockQuote,
  Bold,
  Essentials,
  FontBackgroundColor,
  FontColor,
  FontFamily,
  FontSize,
  Heading,
  Highlight,
  HorizontalLine,
  ImageBlock,
  ImageCaption,
  ImageInline,
  ImageInsert,
  ImageInsertViaUrl,
  ImageResize,
  ImageStyle,
  ImageTextAlternative,
  ImageToolbar,
  ImageUpload,
  Indent,
  IndentBlock,
  Italic,
  Link,
  LinkImage,
  List,
  ListProperties,
  Paragraph,
  SelectAll,
  SpecialCharacters,
  SpecialCharactersArrows,
  SpecialCharactersCurrency,
  SpecialCharactersEssentials,
  SpecialCharactersLatin,
  SpecialCharactersMathematical,
  SpecialCharactersText,
  Subscript,
  Superscript,
  Table,
  TableCaption,
  TableCellProperties,
  TableColumnResize,
  TableProperties,
  TableToolbar,
  TodoList,
  Underline,
  Undo,
} from "ckeditor5";
import moment from "moment";
import { getEnumOptions } from "@/helper/enum.helper";
import { AiOptions } from "@/enums/ai-options.enum";
import { Email } from "@/models/email.model";
import { MessageContent } from "@/enums/empty-message.enum";
import { LinkingEvent } from "@/models/linking.model";

export default defineComponent({
  name: "MailClient",
  props: {
    candidate: {
      type: Object as () => CandidateList | null,
      default: null,
    },
    candidateName: {
      type: String,
      default: "",
    },
    customer: {
      type: Object as () => Customer | null,
      default: null,
    },
    employee: {
      type: Object as () => Employee | null,
      default: null,
    },
    emailObject: {
      type: Object as () => Email,
      default: () => ({
        body: "",
        subject: "",
      }),
    },
    linkingStatuses: {
      type: Array as PropType<LinkingEvent[]>,
      default: () => [],
    },
    signature: {
      type: String,
      default: "",
    },
    emailAddresses: {
      type: Array as PropType<EmailDetails[]>,
      required: true,
    },
    AiMessageType: {
      type: String,
      default: "",
    },
  },
  data() {
    const { smAndDown } = useDisplay();
    return {
      smAndDown,
      awaitSelection: false,
      candidateTimelineData: {
        name: "",
        uuid: "",
        employee_id: "",
      },
      config: null as any, // CKEditor needs the DOM tree before calculating the configuration.
      editor: ClassicEditor,
      fullMessage: `Sehr geehrte Damen und Herren,<br>${this.emailObject.body}`,
      isLayoutReady: false,
      isProfileEmail: false,
      mailSubject: this.emailObject.subject,
      menuTranslationVisible: false,
      menuAiModelVisible: false,
      menuPosition: { x: 0, y: 0 },
      lastSalutation: "Sehr geehrte Damen und Herren,",
      selectedAiModel: "",
      selectedEmailObject:
        this.emailAddresses.length > 0
          ? this.emailAddresses[0]
          : {
              mailaddress: "<Mailadresse auswählen>",
              salutation: "Sehr geehrte Damen und Herren,",
            },
      showModal: false,
      targetLanguage: "",
      vStyle: this.$store.state.vStyle,
    };
  },
  computed: {
    aiOptions() {
      return getEnumOptions(AiOptions);
    },
    languages() {
      return this.$store.state.company.aiData.languages;
    },
    mailSignatur() {
      if (this.$store.state.company.loggedInUser.config.aiData.mailSignature) {
        return this.$store.state.company.loggedInUser.config.aiData
          .mailSignature;
      } else {
        return this.signature;
      }
    },
  },
  watch: {
    salutation() {
      this.updateFullMessage();
    },
    emailObject: {
      handler(newVal: Email) {
        this.updateFullMessage(newVal);
        this.mailSubject = newVal.subject;
      },
      deep: true,
    },
    signatur() {
      this.updateFullMessage();
    },
  },
  mounted() {
    this.config = {
      toolbar: {
        items: [
          "undo",
          "redo",
          "|",
          "selectAll",
          "|",
          "heading",
          "|",
          "fontSize",
          "fontFamily",
          "fontColor",
          "fontBackgroundColor",
          "|",
          "bold",
          "italic",
          "underline",
          "subscript",
          "superscript",
          "|",
          "specialCharacters",
          "horizontalLine",
          "link",
          "insertImage",
          "insertTable",
          "highlight",
          "blockQuote",
          "|",
          "alignment",
          "|",
          "bulletedList",
          "numberedList",
          "todoList",
          "indent",
          "outdent",
          "|",
          "accessibilityHelp",
        ],
        shouldNotGroupWhenFull: true,
      },
      plugins: [
        AccessibilityHelp,
        Alignment,
        AutoImage,
        AutoLink,
        Autosave,
        BalloonToolbar,
        Base64UploadAdapter,
        BlockQuote,
        Bold,
        Essentials,
        FontBackgroundColor,
        FontColor,
        FontFamily,
        FontSize,
        Heading,
        Highlight,
        HorizontalLine,
        ImageBlock,
        ImageCaption,
        ImageInline,
        ImageInsert,
        ImageInsertViaUrl,
        ImageResize,
        ImageStyle,
        ImageTextAlternative,
        ImageToolbar,
        ImageUpload,
        Indent,
        IndentBlock,
        Italic,
        Link,
        LinkImage,
        List,
        ListProperties,
        Paragraph,
        SelectAll,
        SpecialCharacters,
        SpecialCharactersArrows,
        SpecialCharactersCurrency,
        SpecialCharactersEssentials,
        SpecialCharactersLatin,
        SpecialCharactersMathematical,
        SpecialCharactersText,
        Subscript,
        Superscript,
        Table,
        TableCaption,
        TableCellProperties,
        TableColumnResize,
        TableProperties,
        TableToolbar,
        TodoList,
        Underline,
        Undo,
      ],
      balloonToolbar: [
        "bold",
        "italic",
        "|",
        "link",
        "insertImage",
        "|",
        "bulletedList",
        "numberedList",
      ],
      fontFamily: {
        supportAllValues: true,
      },
      fontSize: {
        options: [10, 12, 14, "default", 18, 20, 22],
        supportAllValues: true,
      },
      heading: {
        options: [
          {
            model: "paragraph",
            title: "Paragraph",
            class: "ck-heading_paragraph",
          },
          {
            model: "heading1",
            view: "h1",
            title: "Heading 1",
            class: "ck-heading_heading1",
          },
          {
            model: "heading2",
            view: "h2",
            title: "Heading 2",
            class: "ck-heading_heading2",
          },
          {
            model: "heading3",
            view: "h3",
            title: "Heading 3",
            class: "ck-heading_heading3",
          },
          {
            model: "heading4",
            view: "h4",
            title: "Heading 4",
            class: "ck-heading_heading4",
          },
          {
            model: "heading5",
            view: "h5",
            title: "Heading 5",
            class: "ck-heading_heading5",
          },
          {
            model: "heading6",
            view: "h6",
            title: "Heading 6",
            class: "ck-heading_heading6",
          },
        ],
      },
      image: {
        toolbar: [
          "toggleImageCaption",
          "imageTextAlternative",
          "|",
          "imageStyle:inline",
          "imageStyle:wrapText",
          "imageStyle:breakText",
          "|",
          "resizeImage",
        ],
      },
      initialData: "",
      language: "de",
      link: {
        addTargetToExternalLinks: true,
        defaultProtocol: "https://",
        decorators: {
          toggleDownloadable: {
            mode: "manual",
            label: "Downloadable",
            attributes: {
              download: "file",
            },
          },
        },
      },
      list: {
        properties: {
          styles: true,
          startIndex: true,
          reversed: true,
        },
      },
      placeholder: "Type or paste your content here!",
      table: {
        contentToolbar: [
          "tableColumn",
          "tableRow",
          "mergeTableCells",
          "tableProperties",
          "tableCellProperties",
        ],
      },
      translations: [translations],
    };

    this.isLayoutReady = true;
  },
  methods: {
    cleanMessage() {
      const salutation = this.selectedEmailObject.salutation;
      const mailSignatur = this.mailSignatur;
      const lastSalutation = this.lastSalutation;

      let message = this.fullMessage;

      const escapedSalutation = salutation.replace(
        /[.*+?^${}()|[\]\\]/g,
        "\\$&"
      );
      const escapedLastSalutation = lastSalutation.replace(
        /[.*+?^${}()|[\]\\]/g,
        "\\$&"
      );
      const escapedSignature = mailSignatur.replace(
        /[.*+?^${}()|[\]\\]/g,
        "\\$&"
      );
      const salutationRegex = new RegExp(
        `(<[^>]*>)*\\s*${escapedSalutation}\\s*(<[^>]*>)*`,
        "gi"
      );
      const lastSalutationRegex = new RegExp(
        `(<[^>]*>)*\\s*${escapedLastSalutation}\\s*(<[^>]*>)*`,
        "gi"
      );
      const mailSignaturRegex = new RegExp(
        `(<[^>]*>)*\\s*${escapedSignature
          .split("\n")
          .map((line: any) => line.trim())
          .join("\\s*")}\\s*(<[^>]*>)*`,
        "gi"
      );

      // remove all occurrences of salutation, lastSalutation and mailSignatur including surrounding HTML tags and spaces
      message = message
        .replace(salutationRegex, "")
        .replace(lastSalutationRegex, "")
        .replace(mailSignaturRegex, "")
        .trim();

      // remove any extraneous HTML tags or empty paragraphs
      message = message
        .replace(/<p>\s*<\/p>/g, "")
        .replace(/<br>\s*<br>/g, "<br>");

      return message;
    },

    closeModal() {
      this.showModal = false;
    },
    async checkDSGVO(reminderType: string) {
      const reminderDSGVO = this.$store.state.reminderDSGVO[reminderType];

      const lastReminderTime = moment(reminderDSGVO.lastReminder);
      const currentTime = moment();
      const intervalTime = lastReminderTime
        .add(reminderDSGVO.intervalHours, "hours")
        .add(reminderDSGVO.intervalDays, "days");

      const hasToCheck =
        !reminderDSGVO.lastReminder || currentTime.isAfter(intervalTime);

      if (hasToCheck) {
        const confirmed = await DialogService.confirm(
          reminderDSGVO.reminderText,
          "Abbrechen",
          "mit ChatGPT verarbeiten"
        );

        if (!confirmed) {
          return Promise.reject("DSGVO check not confirmed");
        }

        this.$store.state.reminderDSGVO[reminderType].lastReminder =
          moment().format("YYYY-MM-DD HH:mm:ss");
      }

      return Promise.resolve();
    },
    getStatusWithCompany(linkingStatuses: LinkingEvent[]): string {
      const companyEvents = linkingStatuses.filter(
        (event) => !event.description
      );

      let statusWithCompany = "aktueller Status des Kandidaten mit uns:\n";

      if (companyEvents.length > 0) {
        const lastCompanyEvent = companyEvents[companyEvents.length - 1];
        const eventDates = lastCompanyEvent.eventDate.join(", ");
        statusWithCompany += `${lastCompanyEvent.eventType} am ${eventDates}\n`;
      } else {
        statusWithCompany += "Es gibt noch keine Events mit uns.\n";
      }

      return statusWithCompany;
    },
    getStatusWithCustomers(linkingStatuses: LinkingEvent[]): string {
      const customerEvents = linkingStatuses.filter(
        (event) => event.description
      );

      let statusWithCustomers = "aktueller Status der Bewerbungsverfahren:\n";

      customerEvents.forEach((event) => {
        const eventDates = event.eventDate.join(", ");
        statusWithCustomers += `Kunde: ${event.description} - Letztes Event: ${event.eventType} am ${eventDates}\n`;
      });

      return statusWithCustomers;
    },
    handleAiOptions(aiModel: { value: string; label: string }) {
      if (this.awaitSelection) {
        ToastService.showSuccess(
          `"${aiModel.label}" wurde als AI für die Übersetzung ausgewählt`
        );
        this.selectedAiModel = aiModel.label;
        this.awaitSelection = false;
      } else {
        this.handleGenerateText(aiModel.label);
      }
    },
    async handleGenerateText(aiModel: string) {
      let additionalPrompt = "";
      if (
        this.candidate &&
        this.linkingStatuses.length > 0 &&
        this.emailObject.body === MessageContent.empty
      ) {
        const today = `Heutiges Datum: ${moment().format("DD-MM-YYYY HH:mm")}`;
        const statusWithCompany = this.getStatusWithCompany(
          this.linkingStatuses
        );
        const statusWithCustomers = this.getStatusWithCustomers(
          this.linkingStatuses
        );
        additionalPrompt = `Generiere Deine Nachricht basierend auf diesen Informationen:\n${today}\n${statusWithCompany}\n${statusWithCustomers}`;
      }

      if (aiModel === AiOptions.chatGPT) {
        try {
          await this.checkDSGVO("eMail");

          SpinnerService.showSpinner();
          const cleanedMessage = this.cleanMessage();
          const response = await generateEmail(
            cleanedMessage,
            this.AiMessageType,
            additionalPrompt
          );
          const eMail = {
            body: response.text,
          };
          this.updateFullMessage(eMail);

          if (response.subject) {
            this.mailSubject = response.subject;
          }
        } catch (error) {
          if (error === "DSGVO check not confirmed") {
            return this.fullMessage;
          } else {
            ToastService.showError("Fehler bei der Textgenerierung!");
            return this.fullMessage;
          }
        } finally {
          SpinnerService.removeSpinner();
        }
      } else if (aiModel === AiOptions.recurion) {
        SpinnerService.showSpinner();
        try {
          const cleanedMessage = this.cleanMessage();
          const response = await generateEmailGDPRsave(
            cleanedMessage,
            this.AiMessageType,
            additionalPrompt
          );
          const eMail = {
            body: response.text,
          };
          this.updateFullMessage(eMail);

          if (response.subject) {
            this.mailSubject = response.subject;
          }

          return this.fullMessage;
        } catch (error) {
          ToastService.showError("Fehler bei der Textgenerierung!");
          return this.fullMessage;
        } finally {
          SpinnerService.removeSpinner();
        }
      }
    },
    async handleTranslateText(targetLanguage: string) {
      if (this.selectedAiModel === AiOptions.chatGPT) {
        try {
          await this.checkDSGVO("translation");

          SpinnerService.showSpinner();
          const originalText = `${
            this.selectedEmailObject.salutation
          }<br>${this.cleanMessage()}`;
          const response = await translateMessage(originalText, targetLanguage);
          const translatedText = response.text;

          this.fullMessage = `${translatedText}<br><hr><strong>Original:</strong><br>${originalText}`;
        } catch (error) {
          if (error === "DSGVO check not confirmed") {
            return this.fullMessage;
          } else {
            ToastService.showError("Fehler bei der Übersetzung!");
            return this.fullMessage;
          }
        } finally {
          SpinnerService.removeSpinner();
        }
      } else {
        SpinnerService.showSpinner();
        try {
          const originalText = this.fullMessage;
          const salutationAndText = `${
            this.selectedEmailObject.salutation
          }<br>${this.cleanMessage()}`;
          const translatedText = await translateMessageGDPRsave(
            salutationAndText,
            targetLanguage
          );
          this.fullMessage = `${translatedText}}<br><hr><strong>Original:</strong><br>${originalText}`;
          return this.fullMessage;
        } catch (error) {
          ToastService.showError("Fehler bei der Übersetzung!");
          return this.fullMessage;
        } finally {
          SpinnerService.removeSpinner();
        }
      }
    },
    openMailClient() {
      this.showModal = true;
      this.updateFullMessage();
    },
    openMailTo(
      selectedEmail: any,
      mailSubject: any,
      fullMessage: any,
      attachmentUrl?: string
    ) {
      let mailtoLink = `mailto:${encodeURIComponent(
        selectedEmail
      )}?subject=${encodeURIComponent(mailSubject)}&body=${encodeURIComponent(
        fullMessage
      )}`;

      if (attachmentUrl) {
        mailtoLink += `&attachment=${encodeURIComponent(attachmentUrl)}`;
      }

      window.open(mailtoLink, "_blank");
    },
    openModal(candidateTimelineData?: any) {
      if (candidateTimelineData) {
        this.isProfileEmail = true;
        this.candidateTimelineData = candidateTimelineData;
      }
      this.showModal = true;
    },
    async selectLanguageAndTranslate(language: string) {
      this.targetLanguage = language;
      await this.handleTranslateText(language);
      this.menuTranslationVisible = false;
    },
    async sendMail(
      selectedEmail: any,
      mailSubject: any,
      fullMessage: any,
      email: Email
    ) {
      const emailMessage: Email = {
        body: fullMessage,
        pdf: toRaw(email.pdf),
        pdfId: this.emailObject.pdfId,
        subject: mailSubject,
        to: selectedEmail,
      };
      const emailService = new EmailService();
      const result = await emailService.sendMail(emailMessage);
      if (result.error) {
        ToastService.showError(
          "Fehler beim Senden der Email. Bitte kontrollieren Sie Ihre Konfiguration bzw. Email Zugangsdaten"
        );
      }
    },
    sendMessage() {
      enum EmailConfig {
        server = "server",
        mailTo = "mailTo",
      }
      const emailConfig: EmailConfig = EmailConfig.server as EmailConfig;
      switch (emailConfig) {
        case EmailConfig.server:
          this.sendMail(
            this.selectedEmailObject.mailaddress,
            this.mailSubject,
            this.fullMessage,
            this.emailObject as Email
          );
          break;
        case EmailConfig.mailTo:
          this.openMailTo(
            this.selectedEmailObject.mailaddress,
            this.mailSubject,
            this.fullMessage
            //TODO add attachment link
          );
          break;
        default:
          break;
      }
      this.$emit("collapseParentItem");
      this.closeModal();
      this.submitEntry(
        `Mail gesendet an ${this.selectedEmailObject.mailaddress}: ${this.fullMessage}`
      );
    },
    showLanguageMenu(event: MouseEvent) {
      this.menuTranslationVisible = true;
      this.menuPosition.x = event.clientX;
      this.menuPosition.y = 100;
    },
    showAiModelMenu(event: MouseEvent, awaitSelection?: boolean) {
      if (awaitSelection) this.awaitSelection = true;
      this.menuAiModelVisible = true;
      const menuHeight = 150;
      const windowHeight = window.innerHeight;

      let menuY = event.clientY;

      if (menuY + menuHeight > windowHeight) {
        menuY = windowHeight - menuHeight;
      }

      this.menuPosition.x = event.clientX;
      this.menuPosition.y = menuY;
    },
    submitEntry(noteText: string) {
      const mandants = this.$store.state.company.mandants;

      let timelineData = {
        note: noteText,
        entryType: "",
        mandant: "",
        customer: undefined as
          | undefined
          | { name: string; contact: string; customerId: string | undefined },
        participant: undefined as
          | undefined
          | { name: string; uuid: string; employeeId: string },
      };

      if (this.candidate) {
        const participantName = this.candidateName;
        const mandantUuid = this.candidate?.mandants[0]
          ? this.candidate?.mandants[0]
          : "StandardwertWennNichtGefunden";

        timelineData.entryType =
          this.$store.state.company.timelineEntryTypes.emailCandidate.description;
        timelineData.mandant = mandantUuid;
        timelineData.participant = {
          name: participantName,
          uuid: this.candidate.candidateUuid,
          employeeId: "",
        };
      } else if (this.customer) {
        let entryType =
          this.$store.state.company.timelineEntryTypes.eMailCustomer
            .description;
        if (this.isProfileEmail) {
          entryType =
            this.$store.state.company.timelineEntryTypes.candidateSuggestion
              .description;
          timelineData.participant = {
            name: this.candidateTimelineData.name,
            uuid: this.candidateTimelineData.uuid,
            employeeId: this.candidateTimelineData.employee_id,
          };
        }
        const customerName = this.customer.generalData.name;
        const customerContact = this.selectedEmailObject.mailaddress
          ? this.selectedEmailObject.mailaddress
          : "";
        const customerId = this.customer._id;
        const mandantUuid = this.customer.mandants[0] || "";

        timelineData.entryType = entryType;
        timelineData.mandant = mandantUuid;
        timelineData.customer = {
          name: customerName,
          contact: customerContact,
          customerId: customerId,
        };
      } else if (this.employee) {
        const participantName = `${this.employee.firstName} ${this.employee.lastName}`;
        const matchedMandant = mandants.find(
          (m: Mandant) => m.branchNumber === this.employee?.branchOfficeId
        );
        const mandantUuid = matchedMandant
          ? matchedMandant.uuid
          : "StandardwertWennNichtGefunden";

        timelineData.entryType =
          this.$store.state.company.timelineEntryTypes.eMailEmployee.description;
        timelineData.mandant = mandantUuid;
        timelineData.participant = {
          name: participantName,
          uuid: "",
          employeeId: this.employee.employeeNumber,
        };
      }

      const timelineHelperService = new TimelineHelperService();
      timelineHelperService
        .timelineAutoDocu(timelineData)
        .then(() => {
          ToastService.show("E-Mail Eintrag erfolgreich hinzugefügt.");
        })
        .catch((error: any) => {
          console.error(
            "Fehler beim Hinzufügen des E-Mail Timeline-Eintrags:",
            error
          );
          ToastService.showError("Fehler beim Dokumentieren der E-Mail.");
        });
    },
    updateFullMessage(email?: Email) {
      let message = this.cleanMessage();
      if (email && email.body) {
        message = email.body;
      }
      this.fullMessage = `${this.selectedEmailObject.salutation}<br>${message}<br><br>${this.mailSignatur}`;
      this.lastSalutation = this.selectedEmailObject.salutation;
    },
  },
  provide() {
    return {
      openMailClientModal: this.openModal,
    };
  },
});
</script>

<style scoped>
.mail-icon {
  margin-right: 1rem;
  font-size: 1.5rem;
  cursor: pointer;
  transition: all 0.3s ease;
}
.mail-icon:hover {
  color: var(--color-tertiary);
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}
.action-buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.translation-menu {
  position: fixed;
  top: 0%;
  left: 50%;
  z-index: 200;
}

@media print {
  body {
    margin: 0 !important;
  }
}

.main-container {
  font-family: var(--font-standard);
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
}

.ck-content {
  font-family: var(--font-standard);
  line-height: 1.6;
  word-break: break-word;
}

.editor-container_classic-editor .editor-container__editor {
  border: 1px solid var(--border-medium);
  border-radius: var(--border-radius-elements);
  max-width: 100%;
}
</style>
@/services/timeline-helper.service
