import { StatusResponse } from "@/enums/dialog-action.enum";
import { CandidateList } from "@/models/candidate-list.model";
import { AtsRecruitService } from "@/services/api/api-integration-one.service";
import ToastService from "@/services/toast.service";
import { ZorstService } from "@/services/zorst.service";
import store from "@/store/store";

export class AllAtsAdapter {
  static atsCandidateLink(candidateUuid: string): string {
    const softwareIntegration = store.getters.softwareIntegration;

    switch (true) {
      case !!softwareIntegration.zvooveRecruit:
        return `${softwareIntegration.zvooveRecruitLink}/bewerber/${candidateUuid}/dashboard`;

      default:
        return "#";
    }
  }
  static async changeAtsStatus(
    candidateUuid: string,
    targetStatus: string
  ): Promise<string | null> {
    const softwareIntegration = store.getters.softwareIntegration;
    const candidateLink = this.atsCandidateLink(candidateUuid);

    switch (true) {
      case !!softwareIntegration.zvooveRecruit: {
        try {
          if (!softwareIntegration.zorst || !store.state.zorstAlive) {
            return StatusResponse.manual;
          }

          const result =
            await ZorstService.getInstance().ZorstChangeRecruitCandidateStatus(
              candidateLink,
              targetStatus
            );
          return result;
        } catch (error) {
          console.error("Fehler bei der ATS Recruit Statusänderung:", error);
          ToastService.showError(
            "Fehler bei der ATS Recruit Statusänderung: " + error
          );
          return StatusResponse.manual;
        }
      }

      default:
        return StatusResponse.manual;
    }
  }
  static async getAtsStatusForCandidate(
    candidate: any,
    applicationUuid: string
  ): Promise<{
    error?: string;
    externalAtsStatus?: {
      status: string;
      statusUuid: string;
      internalStatusSystemName: string;
    };
  }> {
    const softwareIntegration = store.getters.softwareIntegration;
    let externalAtsStatus = {
      statusUuid: "",
      status: "",
      internalStatusSystemName: "",
    };

    switch (true) {
      case !!softwareIntegration.zvooveRecruit: {
        try {
          const atsRecruitService = new AtsRecruitService();
          const atsCandidate = await atsRecruitService.getById(
            candidate?.candidateUuid,
            candidate?.applicationId,
            candidate?.applicationUuid
          );

          const matchedApplication = atsCandidate.applications.find(
            (application) => application.uuid === applicationUuid
          );

          if (matchedApplication) {
            externalAtsStatus = matchedApplication.ats;
            return { externalAtsStatus: externalAtsStatus };
          }
        } catch (error) {
          console.error("Error fetching ATS status from zvooveRecruit:", error);
        }
        break;
      }
      default:
        return { error: "no Ats selected" };
    }

    return { error: "null" };
  }
  static async getCandidateData(candidate: any): Promise<any | null> {
    const softwareIntegration = store.getters.softwareIntegration;
    let atsCandidate = null;

    switch (true) {
      case !!softwareIntegration.zvooveRecruit: {
        try {
          const atsRecruitService = new AtsRecruitService();
          atsCandidate = await atsRecruitService.getById(
            candidate?.candidateUuid,
            candidate?.applicationId,
            candidate?.applicationUuid
          );
          return atsCandidate;
        } catch (error) {
          console.error(
            `Fehler beim Abrufen des Kandidaten #${candidate?.applicationId} von zvooveRecruit`,
            error
          );
          throw error;
        }
      }
      default:
        ToastService.show("Kein unterstütztes ATS-System ausgewählt!");
        return null;
    }
  }
  static async getCandidateList(
    searchterm: string
  ): Promise<{ error?: string; atsCandidateList?: CandidateList[] }> {
    const softwareIntegration = store.getters.softwareIntegration;
    let candidateList: CandidateList[] = [];

    try {
      switch (true) {
        case !!softwareIntegration.zvooveRecruit: {
          const atsRecruitService = new AtsRecruitService();
          candidateList = await atsRecruitService.getBewerbungenFiltered(
            searchterm
          );
          return { atsCandidateList: candidateList };
        }

        default:
          return { error: "no Ats selected" };
      }
    } catch (error) {
      console.error("Error fetching candidates from ATS:", error);
      return { error: "Error fetching candidates" };
    }
  }
  static hasAtsSystem(): boolean {
    const softwareIntegration = store.getters.softwareIntegration;

    switch (true) {
      case !!softwareIntegration.zvooveRecruit:
        return true;

      default:
        return false;
    }
  }
}
