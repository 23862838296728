<template>
  <v-sheet
    class="d-flex align-center"
    :class="mdAndDown ? 'flex-row' : 'flex-column'"
    :width="mdAndDown ? '100vw' : '5rem'"
    :height="mdAndDown ? '3rem' : getDynamicHeight()"
    color="primary-darken-1"
  >
    <v-icon
      :size="mdAndDown ? 'large' : 'x-large'"
      :class="mdAndDown ? 'ml-10 mr-15' : 'mt-10 mb-15'"
      >fas fa-home</v-icon
    >
    <v-sheet
      class="d-flex justify-center align-center"
      :class="mdAndDown ? 'ml-10 mr-5' : 'mt-10 mb-5'"
      :width="mdAndDown ? '5rem' : '100%'"
      :height="mdAndDown ? '100%' : '3rem'"
      :color="$route.path === '/dashboard' ? 'secondary' : 'primary-darken-1'"
      @click="navigateTo('/dashboard')"
    >
      <v-icon class="menu-icon" :size="mdAndDown ? 'large' : 'x-large'"
        >fas fa-tachometer-alt</v-icon
      >
    </v-sheet>

    <v-sheet
      class="d-flex justify-center align-center"
      :class="mdAndDown ? 'mr-5' : 'mb-5'"
      :width="mdAndDown ? '5rem' : '100%'"
      :height="mdAndDown ? '100%' : '3rem'"
      :color="$route.path === '/recruiting' ? 'secondary' : 'primary-darken-1'"
      @click="navigateTo('/recruiting')"
    >
      <v-icon class="menu-icon" :size="mdAndDown ? 'large' : 'x-large'"
        >fas fa-people-arrows</v-icon
      >
    </v-sheet>

    <v-sheet
      class="d-flex justify-center align-center"
      :class="mdAndDown ? 'mr-5' : 'mb-5'"
      :width="mdAndDown ? '5rem' : '100%'"
      :height="mdAndDown ? '100%' : '3rem'"
      :color="$route.path === '/disposition' ? 'secondary' : 'primary-darken-1'"
      @click="navigateTo('/disposition')"
    >
      <v-icon class="menu-icon" :size="mdAndDown ? 'large' : 'x-large'"
        >fas fa-headset</v-icon
      >
    </v-sheet>

    <v-sheet
      class="d-flex justify-center align-center"
      :class="mdAndDown ? 'mr-5' : 'mb-5'"
      :width="mdAndDown ? '5rem' : '100%'"
      :height="mdAndDown ? '100%' : '3rem'"
      :color="$route.path === '/data' ? 'secondary' : 'primary-darken-1'"
      @click="navigateTo('/data')"
    >
      <v-icon class="menu-icon" :size="mdAndDown ? 'large' : 'x-large'"
        >far fa-folder-open</v-icon
      >
    </v-sheet>
  </v-sheet>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useDisplay } from "vuetify";

export default defineComponent({
  name: "LeftHeader",
  data() {
    const { mdAndDown } = useDisplay();
    return {
      mdAndDown,
    };
  },
  methods: {
    getDynamicHeight() {
      const windowHeight = window.innerHeight;
      const bodyHeight = document.body.scrollHeight;
      return windowHeight > bodyHeight ? `99vh` : `100%`;
    },
    navigateTo(route: string) {
      this.$router.push(route);
    },
  },
});
</script>
<style scoped>
.menu-icon {
  cursor: pointer;
  transition: all 0.3s ease;
}
.menu-icon:hover {
  scale: 1.1;
}
</style>
