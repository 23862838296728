//src/router/index.ts
import { createRouter, createWebHistory } from "vue-router";
import { UserHelperService } from "@/services/user-helper.service";
import AuthService from "@/services/auth.service";
import ConfigAi from "@/components/config/ai/ConfigAi.vue";
import ConfigCompany from "@/components/config/company/company.vue";
import ConfigMandant from "@/components/config/mandant/mandant.vue";
import ConfigUser from "@/components/config/user/ConfigUser.vue";
import DashboardView from "@/views/dashboard-view.vue";
import DataCleaning from "@/components/config/admin/DataCleaning.vue";
import DebugInfo from "@/components/debug-info/debug-info.vue";
import DispositionView from "@/views/disposition-view.vue";
import DocumentationView from "@/views/documentation-view.vue";
import LoginView from "@/views/login-view.vue";
import LogoutView from "@/views/logout-view.vue";
import RecruitingView from "@/views/recruiting-view.vue";
import RegistrationSuccessView from "@/views/registration-success.vue";
import RegistrationView from "@/views/registration.vue";
import SoftwareIntegration from "@/components/config/software-integration/SoftwareIntegration.vue";
import SubscriptionView from "@/views/subscription-view.vue";
import { Routes } from "@/enums/routes.enum";

function isTokenValid() {
  const userHelper = UserHelperService.getInstance();
  return userHelper.isLoggedIn;
}

export function requireAuth(to: any, from: any, next: any) {
  if (isTokenValid()) {
    next();
  } else {
    next("/");
  }
}

export function requireLoggedOut(to: any, from: any, next: any) {
  if (isTokenValid()) {
    // Der Benutzer hat ein gültiges JWT-Token, erlauben Sie den Zugriff
    next(Routes.dashboard);
  } else {
    // Der Benutzer hat kein gültiges JWT-Token, leiten Sie ihn zur Anmeldeseite weiter
    next();
  }
}

const routes = [
  {
    path: "/",
    name: "Login",
    component: LoginView,
    // beforeEnter: requireLoggedOut,
  },
  {
    path: Routes.dashboard,
    name: "Dashboard",
    component: DashboardView,
    beforeEnter: requireAuth,
  },
  {
    path: Routes.dataCleaning,
    name: "DataCleaning",
    component: DataCleaning,
    beforeEnter: requireAuth,
  },
  {
    path: Routes.configCompany,
    name: "ConfigCompany",
    component: ConfigCompany,
    beforeEnter: requireAuth,
  },
  {
    path: Routes.configMandant,
    name: "ConfigMandant",
    component: ConfigMandant,
    beforeEnter: requireAuth,
  },
  {
    path: Routes.configUser,
    name: "ConfigUser",
    component: ConfigUser,
    beforeEnter: requireAuth,
  },
  {
    path: Routes.configAi,
    name: "ConfigAi",
    component: ConfigAi,
    beforeEnter: requireAuth,
  },
  {
    path: Routes.configApi,
    name: "ConfigApi",
    component: SoftwareIntegration,
    beforeEnter: requireAuth,
  },
  {
    path: Routes.version,
    name: "Version",
    component: DebugInfo,
  },
  {
    path: Routes.registration,
    name: "Registration",
    component: RegistrationView,
  },
  {
    path: Routes.registrationSuccess,
    name: "RegistrationSuccess",
    component: RegistrationSuccessView,
  },
  {
    path: Routes.recruiting,
    name: "Recruiting",
    component: RecruitingView,
    beforeEnter: requireAuth,
  },
  {
    path: Routes.dispostion,
    name: "Dispostion",
    component: DispositionView,
    beforeEnter: requireAuth,
  },
  {
    path: Routes.administration,
    name: "Administration",
    component: DocumentationView,
    beforeEnter: requireAuth,
  },
  {
    path: Routes.subscription,
    name: "Subscription",
    component: SubscriptionView,
    beforeEnter: requireAuth,
  },
  {
    path: Routes.logout,
    name: "Logout",
    component: LogoutView,
  },
];

const router = createRouter({
  history: createWebHistory("/"),
  routes,
});

router.beforeEach((to, from, next) => {
  const publicPages = [
    "/",
    Routes.registration,
    Routes.registrationSuccess,
    Routes.version,
  ];
  const authRequired = !publicPages.includes(to.path);
  const authService = AuthService.getInstance(router);
  const token = localStorage.getItem("accessToken");
  const loggedIn = token && !authService.isExpired();

  if (authRequired && !loggedIn) {
    next("/");
  } else {
    next();
  }
});

export default router;
