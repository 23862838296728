<!--src/components/JobMatrix.vue-->
<template>
  <div class="my-10">
    <v-row>
      <v-col cols="12" sm="3">
        <v-select
          :variant="vStyle.input.variant || undefined"
          :rounded="vStyle.input.rounded || undefined"
          :base-color="vStyle.input.baseColor || undefined"
          :color="vStyle.input.color || undefined"
          v-model="selectedMandants"
          :items="mandants"
          clearable
          item-title="name"
          item-value="uuid"
          label="Nach Niederlassung filtern"
          placeholder="Alle Mandanten"
          density="compact"
          @update:model-value="loadJobMatrixData(selectedMandants)"
          id="dropdown-mandants"
          multiple
        >
        </v-select>
      </v-col>
      <v-col cols="12" sm="2"></v-col>
      <v-col cols="12" sm="4" class="d-flex align-center">
        <v-checkbox
          v-model="publishOnHomepage"
          label="auf allen eigenen Stellenbörsen veröffentlichen"
          density="compact"
          style="font-size: 0.7em"
          id="checkbox-publish-on-homepage"
        ></v-checkbox>
        <v-checkbox
          v-model="publishOnBfA"
          label="auf Bundesagentur für Arbeit veröffentlichen"
          density="compact"
          style="font-size: 0.7em"
          id="checkbox-publish-on-bfa"
        ></v-checkbox>
      </v-col>
      <v-col cols="12" sm="3" class="d-flex align-center">
        <v-btn
          :variant="vStyle.btn.variant || undefined"
          :rounded="vStyle.btn.rounded || undefined"
          :border="vStyle.btn.border || undefined"
          @click="postJobMatrix"
          color="warning"
          id="button-post-job-matrix"
        >
          alle Stellen ausschreiben
        </v-btn>
      </v-col>
    </v-row>
  </div>
  <v-card
    class="mx-4 job-matrix"
    :variant="vStyle.card.variant || undefined"
    :rounded="vStyle.card.rounded || undefined"
    :border="vStyle.card.border || undefined"
  >
    <table class="job-matrix-table">
      <thead>
        <tr>
          <th class="top-left-cell">PLZ Ort</th>
          <th
            class="jobtitles longpress"
            v-for="jobCategory in jobCategories"
            :key="'category-' + jobCategory._id"
            @contextmenu.prevent="
              showContextMenuForJobCategory($event, jobCategory)
            "
            @longpress="showContextMenuForJobCategory($event, jobCategory)"
            @mouseover="showInfoboxJobCategory($event, jobCategory)"
            @mouseleave="hideInfobox"
            :id="`${jobCategory.generalTitle}`"
          >
            <div class="category-name">{{ jobCategory.generalTitle }}</div>
          </th>
          <th class="top-right-cell">
            <v-icon
              class="plus-add-button"
              color="primary"
              @click="openAddJobCategoryModal"
              id="jobtitle-add-btn"
              >fa-solid fa-circle-plus</v-icon
            >
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(jobLocation, index) in jobLocations"
          :key="'location-' + index"
        >
          <td
            class="longpress"
            v-if="jobLocation && jobLocation._id"
            @contextmenu.prevent="
              showContextMenuForLocation($event, jobLocation)
            "
            @longpress="showContextMenuForLocation($event, jobLocation)"
            @mouseover="showInfoboxJobLocation($event, jobLocation)"
            @mouseleave="hideInfobox"
            :id="`location-${formatLocationName(jobLocation.city)}`"
          >
            {{ jobLocation.postalCode }} {{ jobLocation.city }}
          </td>
          <td
            v-for="jobCategory in jobCategories"
            :key="'jobad-' + jobLocation._id + '-' + jobCategory._id"
            @click="handleClickOnJobAd(jobLocation._id, jobCategory._id)"
            @contextmenu.prevent="
              showContextMenuForJobAd($event, jobLocation._id, jobCategory._id)
            "
            @longpress="
              showContextMenuForJobAd($event, jobLocation._id, jobCategory._id)
            "
            @mouseover="
              showInfoboxJobAd($event, jobCategory._id, jobLocation._id)
            "
            @mouseleave="hideInfobox"
            class="job-ad-cell longpress"
            :id="`jobad-${jobLocation._id}-${jobCategory._id}`"
          >
            <v-icon size="small">
              {{ showIconForJobAd(jobLocation._id, jobCategory._id) }}
            </v-icon>
          </td>
          <td></td>
          <!-- Spacer for new jobtitle -->
        </tr>
        <tr>
          <td>
            <v-icon
              class="fa-solid plus-add-button"
              color="primary"
              @click="openAddLocationModal"
              id="add-location"
              >fa-solid fa-circle-plus</v-icon
            >
          </td>
        </tr>
      </tbody>
    </table>
  </v-card>
  <!-- Contextmenu -->
  <v-card
    v-if="contextMenu.visible"
    :style="{
      'max-width': '15rem',
      top: contextMenu.y + 'px',
      left: contextMenu.x + 'px',
    }"
    class="px-0 context-menu"
    id="context-menu"
  >
    <v-list
      v-if="currentJobAd.uuid && softwareIntegration.zvooveRecruit"
      class="px-2 context-menu-item"
      @click="openJodAdInAtsRecruit(currentJobAd.uuid)"
      id="context-menu-open-ats"
    >
      in ATS Recruit ansehen
    </v-list>
    <v-list
      v-if="contextMenuType !== JobMatrixMenu.jobAd"
      class="px-2 context-menu-item"
      @click="handleContextMenuSelection(ModalMode.edit)"
      id="context-menu-edit"
    >
      Bearbeiten
    </v-list>
    <v-list
      v-if="contextMenuType !== JobMatrixMenu.jobAd"
      class="px-2 context-menu-item"
      @click="handleContextMenuSelection(ModalMode.delete)"
      id="context-menu-delete"
    >
      Löschen
    </v-list>
    <v-list
      v-if="contextMenuType === JobMatrixMenu.jobAd"
      class="px-2 context-menu-item"
      @click="handleContextMenuSelection(ModalMode.edit)"
      id="context-menu-edit"
    >
      Bearbeiten
    </v-list>
    <v-list
      v-if="
        contextMenuType === JobMatrixMenu.jobAd &&
        currentJobAd.status === PostingStatus.posted
      "
      class="px-2 context-menu-item"
      @click="handleContextMenuSelection(ModalMode.removePosted)"
    >
      <i class="fa-regular fa-square-check"></i> entfernen
    </v-list>
    <v-list
      v-if="
        contextMenuType === JobMatrixMenu.jobAd &&
        currentJobAd.status === PostingStatus.posted
      "
      class="px-2 context-menu-item"
      @click="handleContextMenuSelection(ModalMode.removeAllPosted)"
      id="context-menu-remove-all-posted"
    >
      Alle <i class="fa-regular fa-square-check"></i> auf
      <i class="fa-regular fa-newspaper"></i> stellen
    </v-list>
    <v-list
      class="px-2 context-menu-item"
      @click="handleContextMenuSelection(ModalMode.delete)"
      id="context-menu-remove-all-posted"
    >
      <i class="fa-solid fa-triangle-exclamation mr-1"></i>Alle löschen
      <i class="fa-solid fa-triangle-exclamation"></i>
    </v-list>
  </v-card>
  <!-- Infobox (Tooltip)-->
  <v-card
    v-if="infobox.visible"
    class="border-sm infobox"
    :style="{ left: infobox.x + 'px', top: infobox.y + 'px' }"
    id="infobox"
  >
    <v-card-title>
      {{ infobox.title }}
    </v-card-title>
    <v-card-subtitle> (Rechtsklick um zu bearbeiten) </v-card-subtitle>
    <v-card-text>
      <pre>{{ infobox.content }}</pre>
    </v-card-text>
  </v-card>
  <!-- Modal for editing/adding job titles -->
  <v-dialog
    v-model="showJobCategoryEditModal"
    persistent
    :max-width="smAndDown ? 'var(--d-w-sd)' : 'var(--d-w-sm)'"
  >
    <v-card>
      <v-container class="d-flex">
        <v-card-title>
          {{
            actionType === ModalMode.add
              ? "Neuen Jobtitel hinzufügen"
              : "Jobtitel bearbeiten"
          }}
        </v-card-title>
        <v-spacer></v-spacer>
        <v-icon @click="closeModals" class="close-icon">
          fa-solid fa-xmark
        </v-icon>
      </v-container>
      <v-card-text>
        <v-form>
          <v-text-field
            :variant="vStyle.input.variant || undefined"
            :rounded="vStyle.input.rounded || undefined"
            :base-color="vStyle.input.baseColor || undefined"
            :color="vStyle.input.color || undefined"
            label="Name"
            v-model="currentJobCategory.generalTitle"
            placeholder="Name"
            id="jobcategory-name"
            density="compact"
            @keydown.enter="
              checkForBfAJobCategories(
                currentJobCategory.generalTitle,
                JobMatrixMenu.jobCategory
              )
            "
          ></v-text-field>
          <v-textarea
            :variant="vStyle.input.variant || undefined"
            :rounded="vStyle.input.rounded || undefined"
            :base-color="vStyle.input.baseColor || undefined"
            :color="vStyle.input.color || undefined"
            label="Aufgaben"
            v-model="currentJobCategory.generalTasks"
            placeholder="Aufgaben"
            density="compact"
            rows="3"
            auto-grow
          ></v-textarea>
          <v-textarea
            :variant="vStyle.input.variant || undefined"
            :rounded="vStyle.input.rounded || undefined"
            :base-color="vStyle.input.baseColor || undefined"
            :color="vStyle.input.color || undefined"
            label="Profil"
            v-model="currentJobCategory.generalProfile"
            placeholder="Profil"
            density="compact"
            rows="3"
            auto-grow
          ></v-textarea>
          <v-autocomplete
            :variant="vStyle.input.variant || undefined"
            :rounded="vStyle.input.rounded || undefined"
            :base-color="vStyle.input.baseColor || undefined"
            :color="vStyle.input.color || undefined"
            chips
            label="Bundesagentur für Arbeit Berufsbezeichnungen"
            v-model="currentJobCategory.generalCategoriesBfA"
            :items="allCategoriesBfA"
            multiple
            density="compact"
          ></v-autocomplete>
          <v-container class="ma-0 pa-0 d-flex">
            <v-text-field
              :variant="vStyle.input.variant || undefined"
              :rounded="vStyle.input.rounded || undefined"
              :base-color="vStyle.input.baseColor || undefined"
              :color="vStyle.input.color || undefined"
              label="Gehalt von"
              v-model="currentJobCategory.generalSalaryFrom"
              :style="{ width: '25%' }"
              type="number"
              placeholder="Gehalt von"
              id="jobcategory-salary-from"
              density="compact"
            ></v-text-field>
            <v-text-field
              :variant="vStyle.input.variant || undefined"
              :rounded="vStyle.input.rounded || undefined"
              :base-color="vStyle.input.baseColor || undefined"
              :color="vStyle.input.color || undefined"
              label="Gehalt bis"
              v-model="currentJobCategory.generalSalaryTo"
              :style="{ width: '25%' }"
              type="number"
              placeholder="Gehalt bis"
              id="jobcategory-salary-to"
              density="compact"
            ></v-text-field>
            <v-text-field
              :variant="vStyle.input.variant || undefined"
              :rounded="vStyle.input.rounded || undefined"
              :base-color="vStyle.input.baseColor || undefined"
              :color="vStyle.input.color || undefined"
              label="Gehaltszeitraum"
              :style="{ width: '50%' }"
              v-model="currentJobCategory.generalSalaryPeriod"
              placeholder="Gehaltszeitraum"
              id="jobcategory-salary-period"
              density="compact"
            ></v-text-field>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn color="abort" @click="closeModals()">Abbrechen</v-btn>
        <v-spacer></v-spacer>
        <v-btn
          v-if="actionType === ModalMode.add"
          color="primary"
          @click="addJobCategory"
          id="jobcategory-add"
        >
          Hinzufügen
        </v-btn>

        <v-btn
          :variant="vStyle.btn.variant || undefined"
          :rounded="vStyle.btn.rounded || undefined"
          :border="vStyle.btn.border || undefined"
          v-if="actionType === ModalMode.edit"
          color="primary"
          @click="editJobCategory"
          id="jobcategory-edit"
        >
          Speichern
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <!-- Modal for editing/adding locations -->
  <v-dialog
    v-model="showLocationEditModal"
    persistent
    :max-width="smAndDown ? 'var(--d-w-sd)' : 'var(--d-w-sm)'"
  >
    <v-card>
      <v-container class="d-flex">
        <v-card-title>
          {{
            actionType === ModalMode.add
              ? "Neuen Ort hinzufügen"
              : "Ort bearbeiten"
          }}
        </v-card-title>
        <v-spacer></v-spacer>
        <v-icon @click="closeModals" class="close-icon">
          fa-solid fa-xmark
        </v-icon>
      </v-container>
      <v-card-text>
        <v-form>
          <v-text-field
            :variant="vStyle.input.variant || undefined"
            :rounded="vStyle.input.rounded || undefined"
            :base-color="vStyle.input.baseColor || undefined"
            :color="vStyle.input.color || undefined"
            label="PLZ"
            v-model="currentJobLocation.postalCode"
            type="number"
            placeholder="PLZ"
            id="postalcode"
            density="compact"
          ></v-text-field>
          <v-text-field
            :variant="vStyle.input.variant || undefined"
            :rounded="vStyle.input.rounded || undefined"
            :base-color="vStyle.input.baseColor || undefined"
            :color="vStyle.input.color || undefined"
            label="Stadt"
            v-model="currentJobLocation.city"
            placeholder="Stadt"
            id="city"
            density="compact"
          ></v-text-field>
          <v-select
            :variant="vStyle.input.variant || undefined"
            :rounded="vStyle.input.rounded || undefined"
            :base-color="vStyle.input.baseColor || undefined"
            :color="vStyle.input.color || undefined"
            :items="mandants"
            item-value="uuid"
            item-title="name"
            label="Mandant wählen"
            v-model="currentJobLocation.mandants[0]"
            id="select-mandant"
            density="compact"
          ></v-select>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn color="abort" @click="closeModals">Abbrechen</v-btn>
        <v-spacer></v-spacer>
        <v-btn
          :variant="vStyle.btn.variant || undefined"
          :rounded="vStyle.btn.rounded || undefined"
          :border="vStyle.btn.border || undefined"
          v-if="actionType === ModalMode.add"
          color="primary"
          @click="addLocation"
          id="post-add-location"
        >
          Hinzufügen
        </v-btn>
        <v-btn
          v-else
          :variant="vStyle.btn.variant || undefined"
          :rounded="vStyle.btn.rounded || undefined"
          :border="vStyle.btn.border || undefined"
          color="primary"
          @click="editLocation"
          id="post-save-location"
        >
          Speichern
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <!-- Modal for editing job advertisements -->
  <v-dialog
    v-model="showJobAdEditModal"
    persistent
    :max-width="smAndDown ? 'var(--d-w-sd)' : 'var(--d-w-sm)'"
  >
    <v-card v-if="currentJobAd && currentJobAd._id">
      <v-container class="d-flex">
        <v-card-title>Jobanzeige bearbeiten</v-card-title>
        <v-spacer></v-spacer>
        <v-icon @click="closeModals()" class="close-icon">
          fa-solid fa-xmark
        </v-icon>
      </v-container>
      <v-card-text>
        <v-form>
          <v-text-field
            :variant="vStyle.input.variant || undefined"
            :rounded="vStyle.input.rounded || undefined"
            :base-color="vStyle.input.baseColor || undefined"
            :color="vStyle.input.color || undefined"
            label="Name"
            v-model="currentJobAd.title"
            placeholder="Name"
            density="compact"
            @keydown.enter="
              checkForBfAJobCategories(currentJobAd.title, JobMatrixMenu.jobAd)
            "
          ></v-text-field>
          <v-textarea
            :variant="vStyle.input.variant || undefined"
            :rounded="vStyle.input.rounded || undefined"
            :base-color="vStyle.input.baseColor || undefined"
            :color="vStyle.input.color || undefined"
            label="Aufgaben"
            v-model="currentJobAd.tasks"
            placeholder="Aufgaben"
            density="compact"
            rows="3"
            auto-grow
          ></v-textarea>
          <v-textarea
            :variant="vStyle.input.variant || undefined"
            :rounded="vStyle.input.rounded || undefined"
            :base-color="vStyle.input.baseColor || undefined"
            :color="vStyle.input.color || undefined"
            label="Profil"
            v-model="currentJobAd.profile"
            placeholder="Profil"
            density="compact"
            rows="3"
            auto-grow
          ></v-textarea>
          <v-container class="ma-0 pa-0 d-flex">
            <v-text-field
              :variant="vStyle.input.variant || undefined"
              :rounded="vStyle.input.rounded || undefined"
              :base-color="vStyle.input.baseColor || undefined"
              :color="vStyle.input.color || undefined"
              label="Gehalt von"
              v-model="currentJobAd.salaryFrom"
              :style="{ width: '25%' }"
              type="number"
              placeholder="Gehalt von"
              density="compact"
            ></v-text-field>
            <v-text-field
              :variant="vStyle.input.variant || undefined"
              :rounded="vStyle.input.rounded || undefined"
              :base-color="vStyle.input.baseColor || undefined"
              :color="vStyle.input.color || undefined"
              label="Gehalt bis"
              v-model="currentJobAd.salaryTo"
              :style="{ width: '25%' }"
              type="number"
              placeholder="Gehalt bis"
              density="compact"
            ></v-text-field>
            <v-text-field
              :variant="vStyle.input.variant || undefined"
              :rounded="vStyle.input.rounded || undefined"
              :base-color="vStyle.input.baseColor || undefined"
              :color="vStyle.input.color || undefined"
              label="Gehaltszeitraum"
              :style="{ width: '50%' }"
              v-model="currentJobAd.salaryPeriod"
              placeholder="Gehaltszeitraum"
              density="compact"
            ></v-text-field>
          </v-container>
          <v-autocomplete
            :variant="vStyle.input.variant || undefined"
            :rounded="vStyle.input.rounded || undefined"
            :base-color="vStyle.input.baseColor || undefined"
            :color="vStyle.input.color || undefined"
            chips
            label="Bundesagentur für Arbeit Berufsbezeichnungen"
            v-model="currentJobAd.categoriesBfA"
            :items="allCategoriesBfA"
            multiple
            density="compact"
          ></v-autocomplete>
          <v-checkbox
            v-model="currentJobAd.supportedBfAJobAd"
            label="in Betreuung bei der Bundesagentur für Arbeit geben"
            density="compact"
            data-tid="new-form-bfa-support"
          ></v-checkbox>
          <v-select
            :variant="vStyle.input.variant || undefined"
            :rounded="vStyle.input.rounded || undefined"
            :base-color="vStyle.input.baseColor || undefined"
            :color="vStyle.input.color || undefined"
            :items="mandants"
            item-value="uuid"
            item-title="name"
            label="Mandant wählen"
            v-model="currentJobAd.mandants[0]"
            density="compact"
          ></v-select>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn color="abort" @click="closeModals()">Abbrechen</v-btn>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="editJobAd(currentJobAd._id ?? '')"
          >Speichern</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { mapGetters } from "vuex";
import {
  JobAd,
  JobCategory,
  JobLocation,
  PostingStatus,
  jobAdType,
} from "@/models/job-ad.model";
import { JobAdService } from "@/services/api/job-ad.service";
import { JobPosterService } from "@/services/job-poster.service";
import { BfaService } from "@/services/api/bfa.service";
import { useDisplay } from "vuetify";
import DialogService from "@/services/dialog.service";
import Fuse, { FuseResult } from "fuse.js";
import { JobMatrixMenu, ModalMode } from "@/enums/dialog-action.enum";

export default defineComponent({
  name: "JobMatrix",
  data() {
    const { smAndDown } = useDisplay();
    return {
      smAndDown,
      allCategoriesBfA: [] as string[],
      bfaService: new BfaService(),
      contextMenu: { visible: false, content: "", x: 0, y: 0 },
      selectedMandants: [] as string[],
      jobCategories: [] as JobCategory[],
      jobLocations: [] as JobLocation[],
      jobAds: [] as JobAd[],
      infobox: { visible: false, title: "", content: "", x: 0, y: 0 },
      jobAdService: new JobAdService(),
      jobPosterService: new JobPosterService(),
      actionType: ModalMode.add,
      ModalMode: ModalMode,
      contextMenuType: "",
      JobMatrixMenu: JobMatrixMenu,
      currentJobCategory: {
        _id: undefined,
        generalCategoriesBfA: [],
        generalProfile: "",
        generalSalaryFrom: null,
        generalSalaryTo: null,
        generalSalaryPeriod: "",
        generalTasks: "",
        generalTitle: "",
      } as JobCategory,
      currentJobLocation: {
        _id: undefined,
        postalCode: null,
        city: "",
        mandants: [],
      } as JobLocation,
      currentJobAd: {
        _id: undefined,
        type: jobAdType.jobmatrix,
        locationId: "",
        categoryId: "",
        mandants: [],
        status: PostingStatus.requested,
        categoriesBfA: [],
        profile: "",
        salaryFrom: null,
        salaryPeriod: "",
        salaryTo: null,
        tasks: "",
        title: "",
        socialMedia: false,
        supportedBfAJobAd: false,
      } as JobAd,
      PostingStatus: PostingStatus,
      showJobAdEditModal: false,
      showJobCategoryEditModal: false,
      showLocationEditModal: false,
      vStyle: this.$store.state.vStyle,
    };
  },
  computed: {
    ...mapGetters({
      mandants: "reducedMandants",
      softwareIntegration: "softwareIntegration",
    }),
    publishOnHomepage: {
      get(): boolean {
        return this.$store.getters.isJobAdPublishOnHomepage;
      },
      set(value: boolean) {
        this.$store.commit("SET_JOB_AD_PUBLISH_HOMEPAGE", value);
      },
    },
    publishOnBfA: {
      get(): boolean {
        return this.$store.getters.isJobAdPublishOnBfA;
      },
      set(value: boolean) {
        this.$store.commit("SET_JOB_AD_PUBLISH_ON_BFA", value);
      },
    },
  },
  watch: {
    "$store.state.jobPosterState.postedJobAds"() {
      this.loadJobAds(this.selectedMandants);
    },
  },
  async mounted() {
    this.loadJobMatrixData();
    this.allCategoriesBfA = await this.bfaService.getBfaJoblist();
    window.addEventListener("click", this.handleClickOutside);
  },
  beforeUnmount() {
    window.removeEventListener("click", this.handleClickOutside);
  },
  methods: {
    async addJobAd(locationId: string, categoryId: string) {
      const jobAd = this.getJobAdByLocationIdAndCategoryId(
        locationId,
        categoryId
      );
      const jobLocation = this.getLocationById(locationId);
      const jobCategory = this.getCategoryById(categoryId);

      if (!jobAd || jobAd._id === undefined) {
        const newAd: JobAd = {
          _id: undefined,
          type: jobAdType.jobmatrix,
          locationId,
          categoryId,
          mandants: jobLocation?.mandants ?? [],
          status: PostingStatus.requested,
          categoriesBfA: jobCategory?.generalCategoriesBfA ?? [],
          profile: jobCategory?.generalProfile ?? "",
          salaryFrom: jobCategory?.generalSalaryFrom ?? null,
          salaryPeriod: jobCategory?.generalSalaryPeriod ?? "",
          salaryTo: jobCategory?.generalSalaryTo ?? null,
          tasks: jobCategory?.generalTasks ?? "",
          title: jobCategory?.generalTitle ?? "",
          socialMedia: false,
          supportedBfAJobAd: false,
        };
        this.jobAdService.addJobAd(newAd).then(() => {
          this.loadJobAds(this.selectedMandants);
        });
      }
    },
    addJobCategory() {
      this.jobAdService.addJobCategory(this.currentJobCategory).then(() => {
        this.loadJobCategories();
      });
      this.closeModals();
    },
    async addLocation() {
      if (
        !this.currentJobLocation.mandants ||
        !this.currentJobLocation.mandants[0]
      ) {
        DialogService.alert("Mandant bitte noch eintragen!");
        return;
      }
      this.jobAdService.addJobLocation(this.currentJobLocation).then(() => {
        this.loadJobLocations(this.selectedMandants);
      });
      this.closeModals();
    },
    closeContextMenu() {
      this.contextMenu.visible = false;
    },
    closeModals() {
      this.showJobCategoryEditModal = false;
      this.showLocationEditModal = false;
      this.showJobAdEditModal = false;
    },
    checkForBfAJobCategories(jobTitle: string, modalMode: JobMatrixMenu) {
      const fuseOptions = {
        includeScore: true,
        keys: ["name"],
      };

      const fuse = new Fuse(this.allCategoriesBfA, fuseOptions);
      const result: FuseResult<string>[] = fuse.search(jobTitle);
      const top3 = result.slice(0, 3);
      const similarJobs = top3.map((item) => item.item);
      if (modalMode === JobMatrixMenu.jobAd)
        this.currentJobAd.categoriesBfA = similarJobs;
      if (modalMode === JobMatrixMenu.jobAd)
        this.currentJobCategory.generalCategoriesBfA = similarJobs;
    },
    async deleteAllJobAds(mandants: string[]) {
      const confirmed = await DialogService.confirm(
        "Wirklich alle Stellendaten aller weißen Zellen löschen?",
        "Nein",
        "Alle löschen!"
      );
      if (confirmed) {
        await this.jobAdService
          .deleteAllJobAds(jobAdType.jobmatrix, mandants)
          .then(() => {
            this.loadJobAds(mandants);
          });
      }
    },
    async deleteJobAd(id: string) {
      await this.jobAdService.deleteJobAd(id).then(() => {
        this.loadJobAds(this.selectedMandants);
      });
    },
    async deleteJobCategory(id: string) {
      const confirmed = await DialogService.confirm(
        "Die Stellenkategorie wirklich entfernen",
        "Nein",
        "Ja"
      );
      if (confirmed) {
        await this.jobAdService.deleteJobCategory(id).then(() => {
          const jobAdsToDelete = this.jobAds.filter(
            (jobAd) => jobAd.categoryId === id
          );
          for (const jobAd of jobAdsToDelete) {
            if (jobAd._id) this.jobAdService.deleteJobAd(jobAd._id);
          }
          this.loadJobMatrixData(this.selectedMandants);
        });
      }
    },
    async deleteLocation(id: string) {
      const confirmed = await DialogService.confirm(
        "Den Ort wirklich entfernen",
        "Nein",
        "Ja"
      );
      if (confirmed) {
        await this.jobAdService.deleteJobLocation(id).then(() => {
          const jobAdsToDelete = this.jobAds.filter(
            (jobAd) => jobAd.locationId === id
          );
          for (const jobAd of jobAdsToDelete) {
            if (jobAd._id) this.jobAdService.deleteJobAd(jobAd._id);
          }
          this.loadJobMatrixData(this.selectedMandants);
        });
      }
    },
    async editJobAd(id: string) {
      if (id === this.currentJobAd._id)
        this.jobAdService.updateJobAd(this.currentJobAd).then(() => {
          this.loadJobAds(this.selectedMandants);
          this.closeModals();
        });
    },
    editJobAdStatus(jobAd: JobAd, newStatus: PostingStatus) {
      if (jobAd) {
        jobAd.status = newStatus;
        this.jobAdService.updateJobAd(jobAd).then(() => {
          this.loadJobAds(this.selectedMandants);
        });
      }
    },
    editJobCategory() {
      this.jobAdService.editJobCategory(this.currentJobCategory).then(() => {
        this.loadJobCategories();
        this.closeModals();
      });
    },
    async editLocation() {
      if (this.currentJobLocation._id !== null) {
        this.jobAdService.editJobLocation(this.currentJobLocation).then(() => {
          this.loadJobLocations();
        });
      }
      this.closeModals();
    },
    formatLocationName(input: string): string {
      let transformedString = "";
      if (input) {
        transformedString = input.toLowerCase().replace(/\s/g, "");
      }
      return transformedString;
    },
    getJobAdByLocationIdAndCategoryId(locationId: string, categoryId: string) {
      return this.jobAds.find(
        (jobAd) =>
          jobAd.locationId === locationId && jobAd.categoryId === categoryId
      ) as JobAd;
    },
    getCategoryById(id: string) {
      return this.jobCategories.find((jobCategory) => jobCategory._id === id);
    },
    getLocationById(id: string) {
      return this.jobLocations.find((jobLocation) => jobLocation._id === id);
    },
    getMandantName(uuid: string) {
      return this.$store.getters.getMandantNameByUuid(uuid);
    },
    async handleClickOnJobAd(
      locationId: string | undefined,
      categoryId: string | undefined
    ) {
      if (!locationId || !categoryId) return;
      const jobAd = this.getJobAdByLocationIdAndCategoryId(
        locationId,
        categoryId
      );
      if (!jobAd) this.addJobAd(locationId, categoryId);

      if (jobAd && jobAd._id) {
        switch (jobAd.status) {
          case PostingStatus.initial:
            this.editJobAdStatus(jobAd, PostingStatus.requested);
            break;
          case PostingStatus.requested:
            this.editJobAdStatus(jobAd, PostingStatus.initial);
            break;
          case PostingStatus.posted:
            DialogService.alert("Jobanzeige bereits ins ATS System geposted!");
            break;
          default:
            console.warn("Unbekannter Status:", jobAd.status);
            break;
        }
      }
    },
    handleClickOutside(event: MouseEvent) {
      const target = event.target as Element;
      if (
        this.contextMenu.visible &&
        target &&
        !target.closest(".context-menu")
      ) {
        this.closeContextMenu();
      }
    },
    handleContextMenuSelection(action: string) {
      if (this.contextMenuType === JobMatrixMenu.jobCategory) {
        if (action === ModalMode.edit) {
          this.openJobCategoryEditModal();
        } else if (
          action === ModalMode.delete &&
          this.currentJobCategory &&
          this.currentJobCategory._id
        ) {
          this.deleteJobCategory(this.currentJobCategory._id);
        }
      } else if (this.contextMenuType === JobMatrixMenu.jobLocation) {
        if (action === ModalMode.edit) {
          this.openLocationEditModal();
        } else if (
          action === ModalMode.delete &&
          this.currentJobLocation &&
          this.currentJobLocation._id
        ) {
          this.deleteLocation(this.currentJobLocation?._id);
        }
      } else if (this.contextMenuType === JobMatrixMenu.jobAd) {
        if (action === ModalMode.edit) {
          this.openJobAdEditModal();
        } else if (action === ModalMode.removePosted) {
          this.editJobAdStatus(this.currentJobAd, PostingStatus.requested);
        } else if (action === ModalMode.removeAllPosted) {
          this.resetAllJobAdStatuses();
        } else if (action === ModalMode.delete) {
          this.deleteAllJobAds(this.selectedMandants);
        }
      }
      this.closeContextMenu();
    },
    hideInfobox() {
      this.infobox.visible = false;
    },
    async loadJobMatrixData(mandants?: string[]) {
      const jobCategories = await this.jobAdService.getCategories();
      const jobLocations = await this.jobAdService.getLocations(mandants);
      const jobAds = await this.jobAdService.getJobAds(
        jobAdType.jobmatrix,
        mandants
      );

      this.jobCategories = jobCategories.sort((a, b) =>
        a.generalTitle.localeCompare(b.generalTitle)
      );
      this.jobLocations = jobLocations.sort((a, b) => {
        if (a.postalCode === null) return 1;
        if (b.postalCode === null) return -1;
        return a.postalCode - b.postalCode;
      });
      this.jobAds = jobAds;
    },
    async loadJobCategories() {
      const jobCategories = await this.jobAdService.getCategories();
      this.jobCategories = jobCategories.sort((a, b) =>
        a.generalTitle.localeCompare(b.generalTitle)
      );
    },
    async loadJobLocations(mandants?: string[]) {
      const jobLocations = await this.jobAdService.getLocations(mandants);
      this.jobLocations = jobLocations.sort((a, b) => {
        if (a.postalCode === null) return 1;
        if (b.postalCode === null) return -1;
        return a.postalCode - b.postalCode;
      });
    },
    async loadJobAds(mandants?: string[]) {
      const jobAds = await this.jobAdService.getJobAds(
        jobAdType.jobmatrix,
        mandants
      );
      this.jobAds = jobAds;
    },
    openAddJobCategoryModal() {
      this.actionType = ModalMode.add;
      this.currentJobCategory = {
        _id: undefined,
        generalCategoriesBfA: [],
        generalProfile: "",
        generalSalaryFrom: null,
        generalSalaryTo: null,
        generalSalaryPeriod: "",
        generalTasks: "",
        generalTitle: "",
      };
      this.showJobCategoryEditModal = true;
    },
    openAddLocationModal() {
      this.actionType = ModalMode.add;
      this.currentJobLocation = {
        _id: undefined,
        postalCode: null,
        city: "",
        mandants: [],
      };
      this.showLocationEditModal = true;
    },
    openJobAdEditModal() {
      this.showJobAdEditModal = true;
    },
    openJobCategoryEditModal() {
      this.actionType = ModalMode.edit;
      this.showJobCategoryEditModal = true;
    },
    openJodAdInAtsRecruit(uuid: string) {
      const externalLink = `${this.softwareIntegration.zvooveRecruitLink}/stelle/${uuid}/publish/history`;
      window.open(externalLink, "_blank", "width=950,height=900"); //Width 950 supress zvoove Recruits Side Menu and TODO: use responsive dimensions from service
    },
    openLocationEditModal() {
      this.actionType = ModalMode.edit;
      this.showLocationEditModal = true;
    },
    async postJobMatrix() {
      try {
        if (this.selectedMandants) {
          await this.jobPosterService.jobPostingLoop(
            jobAdType.jobmatrix,
            this.selectedMandants
          );
        } else {
          await this.jobPosterService.jobPostingLoop(jobAdType.jobmatrix);
        }
      } catch (error: any) {
        console.error("Fehler beim Ausschreiben der Konzeptstellen:", error);
      }
    },
    async resetAllJobAdStatuses() {
      const confirmed = await DialogService.confirm(
        "Wirklich alle geposteten Jobanzeigen auf 'beantragt' zurücksetzen?",
        "Nein",
        "Ja"
      );
      if (confirmed) {
        try {
          this.jobAdService
            .setAllPostedJobAdsToRequested(
              jobAdType.jobmatrix,
              this.selectedMandants
            )
            .then(() => {
              this.loadJobMatrixData(this.selectedMandants);
            });
        } catch (error) {
          console.error(error);
        }
      }
    },
    showIconForJobAd(
      locationId: string | undefined,
      categoryId: string | undefined
    ) {
      if (!locationId || !categoryId) return;
      const jobAd = this.getJobAdByLocationIdAndCategoryId(
        locationId,
        categoryId
      );
      if (!jobAd || !jobAd.status) return "";
      switch (jobAd.status) {
        case PostingStatus.requested:
          return "fa-solid fa-newspaper";
        case PostingStatus.posted:
          return "fa-solid fa-square-check";
        default:
          return "";
      }
    },
    showInfobox(event: MouseEvent, content: string, title?: string) {
      if (content.trim() !== "") {
        this.infobox.content = content;
        this.infobox.title = title ?? "";
        this.infobox.x = event.clientX + 20;
        this.infobox.y = event.clientY + 20;
        this.infobox.visible = true;
      } else {
        this.infobox.visible = false;
      }
    },
    showInfoboxJobCategory(event: MouseEvent, jobCategory: JobCategory) {
      let tasks = "<generiere Standartaufgaben>";
      let profile = "<generiere Standartanforderungen>";
      if (jobCategory.generalTasks) tasks = jobCategory.generalTasks;
      if (jobCategory.generalProfile) profile = jobCategory.generalProfile;
      this.showInfobox(
        event,
        `Aufgaben:\n${tasks}\n\nProfil:\n${profile}\n\nGehaltsspektrum:\n${jobCategory.generalSalaryFrom} - ${jobCategory.generalSalaryTo} ${jobCategory.generalSalaryPeriod}`,
        jobCategory.generalTitle
      );
    },
    showInfoboxJobLocation(event: MouseEvent, jobLocation: JobLocation) {
      if (!jobLocation || !jobLocation.mandants?.[0]) return;
      let city = "";
      if (jobLocation && jobLocation.city) city = jobLocation.city;
      const mandantName = this.getMandantName(jobLocation.mandants[0]);
      this.showInfobox(event, `Zuordnung:\n${mandantName}`, city);
    },
    showInfoboxJobAd(
      event: MouseEvent,
      categoryId: string,
      locationId: string
    ) {
      if (!categoryId || !locationId) return;
      const jobAd = this.getJobAdByLocationIdAndCategoryId(
        locationId,
        categoryId
      );
      if (!jobAd) return;
      const mandantName = this.getMandantName(jobAd.mandants[0]);
      let tasks = "<generiere Standartaufgaben>";
      let profile = "<generiere Standartanforderungen>";
      if (jobAd.tasks) tasks = jobAd.tasks;
      if (jobAd.profile) profile = jobAd.profile;
      this.showInfobox(
        event,
        `Zugeordnet zu:\n${mandantName}\n\nAufgaben:\n${tasks}\n\nProfil:\n${profile}\n\nGehaltsspektrum:\n${jobAd.salaryFrom} - ${jobAd.salaryTo} ${jobAd.salaryPeriod}`,
        jobAd.title
      );
    },
    showContextMenuForJobCategory(event: MouseEvent, jobCategory: JobCategory) {
      event.preventDefault();
      event.stopPropagation();
      if (jobCategory._id) this.currentJobCategory = jobCategory;
      this.currentJobCategory = jobCategory;
      this.contextMenuType = JobMatrixMenu.jobCategory;
      this.contextMenu.visible = true;
      this.contextMenu.x = event.pageX;
      this.contextMenu.y = event.pageY;
    },
    showContextMenuForLocation(event: any, jobLocation: JobLocation) {
      event.preventDefault();
      event.stopPropagation();
      this.infobox.visible = false;
      this.contextMenuType = JobMatrixMenu.jobLocation;
      this.currentJobLocation = jobLocation;
      this.contextMenu.visible = true;
      this.contextMenu.x = event.pageX;
      this.contextMenu.y = event.pageY;
    },
    showContextMenuForJobAd(
      event: MouseEvent,
      locationId: string | undefined,
      categoryId: string | undefined
    ) {
      if (!locationId || !categoryId) return;
      const jobAd = this.getJobAdByLocationIdAndCategoryId(
        locationId,
        categoryId
      );
      if (!jobAd) return;
      event.preventDefault();
      event.stopPropagation();
      this.contextMenuType = JobMatrixMenu.jobAd;
      this.currentJobAd = jobAd;
      this.contextMenu.visible = true;
      this.contextMenu.x = event.pageX;
      this.contextMenu.y = event.pageY;
    },
  },
});
</script>

<style scoped>
.job-matrix {
  overflow: auto;
}

.dropdown-mandants {
  border: 2px solid var(--color-primary);
  width: 150px;
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;
  background-color: var(--modal-background);
  color: var(--color-primary);
  outline: none;
}

.dropdown-mandants:hover,
.dropdown-mandants:focus {
  border-color: var(--color-accent);
  transform: scale(1.1);
  z-index: 10;
  position: relative;
  transition: transform 0.3s ease, border-color 0.3s ease;
}

.dropdown-mandants option {
  padding: 5px;
  background-color: var(--modal-background);
  color: var(--color-primary);
}

.job-matrix-table {
  width: 50rem;
  max-width: 100%;
  background-color: var(--table-background);
  border-collapse: collapse;
  position: relative;
}

.job-matrix-table th,
.job-matrix-table td {
  border: 1px solid var(--border-light);
  padding: 0.5rem 1rem;
  text-align: left;
}

/* Sticky Header */
.job-matrix-table th {
  background-color: var(--table-header);
  height: 15rem;
  position: sticky;
  top: 0;
  z-index: 2;
}

.category-name {
  transform: rotate(270deg);
  transform-origin: bottom left; /* Adjust origin as needed */
  display: inline-block; /* Ensure the element respects transform */
  position: relative;
  left: 1.5rem;
  top: 5.5rem;
  width: 14rem;
  max-width: 14rem;
}

/* Sticky first column */
.job-matrix-table td:first-child,
.job-matrix-table th:first-child {
  position: sticky;
  max-width: 40rem;
  width: 35rem;
  left: 0;
  background-color: var(--table-header);
  z-index: 1;
}

/* Sticky last column */
.job-matrix-table td:last-child,
.job-matrix-table th:last-child {
  position: sticky;
  width: 15rem;
  right: 0;
  background-color: var(--table-header);
  z-index: 1;
}

/* Set width for all columns except the first and last */
.job-matrix-table th:not(:first-child):not(:last-child),
.job-matrix-table td:not(:first-child):not(:last-child) {
  width: 4rem;
  max-width: 4rem;
}

/* Set height for all rows except the first */
.job-matrix-table tr:not(:first-child) th,
.job-matrix-table tr:not(:first-child) td {
  height: 4rem;
  max-height: 4rem;
}

/* Row-Hover-Effect */
.job-matrix-table tr:hover {
  background-color: var(--table-hover);
  color: var(--color-primary);
}

.top-left-cell {
  text-align: right;
  vertical-align: bottom;
}

.plus-add-button {
  font-size: 1.5em;
  cursor: pointer;
  text-shadow: var(--text-shadow);
  transition: 0.3s ease;
}

.plus-add-button:hover {
  scale: var(--medium-scale-up);
  color: var(--color-tertiary);
  text-shadow: var(--text-shadow);
}

.jobtitles {
  font-size: 0.8rem;
  word-wrap: break-word; /* OR overflow-wrap: break-word; */
}

.job-ad-cell {
  cursor: pointer;
  text-align: center;
  font-size: 1.4em;
  transition: 0.3s ease;
}

.job-ad-cell:hover {
  color: var(--color-tertiary);
}

.infobox {
  position: fixed;
  z-index: 100;
  pointer-events: none;
  box-shadow: var(--box-shadow-dialog);
}

.infobox pre {
  font-family: var(--font-standard);
  white-space: pre-wrap; /* CSS property to make \n work as line breaks */
}

.context-menu {
  position: absolute;
  border: 1px solid var(--color-primary);
  z-index: 1000;
  box-shadow: var(--box-shadow-dialog);
}

.context-menu-item {
  cursor: pointer;
  transition: all 0.3s ease;
}

.context-menu-item:hover {
  background-color: var(--table-hover);
}

.top-right-cell {
  width: 220px;
}

.txt-job-salary-from {
  width: 60px;
}

.txt-job-salary-to {
  width: 60px;
}

.txt-job-salary-period {
  margin-left: 5px;
  width: 68px;
}

.txt-jobdetails {
  border-radius: 5px;
  min-width: 490px;
  min-height: 150px;
}
</style>
