import { User, UserConfig } from "@/models/user.model";
import { Api } from "./api.class";
import store from "@/store/store";
import { PasswordChange } from "@/models/password-change.model";
import { ApiResponse } from "@/models/api-response.model";
import { useRouter } from "vue-router";
import { Routes } from "@/enums/routes.enum";

export class UserService {
  private api = Api.getInstance();
  private urlPath = "/user";
  private router = useRouter();

  async getUser() {
    const response = await this.api
      .getBackendApi()
      .get<User>(`${this.urlPath}`);
    return Promise.resolve(response.data);
  }

  async getUsers() {
    const response = await this.api
      .getBackendApi()
      .get<User[]>(`${this.urlPath}/all`);
    return Promise.resolve(response.data);
  }

  async addUser(user: User) {
    const response = await this.api
      .getBackendApi()
      .post<User>(`${this.urlPath}`, user);
    return response.data;
  }

  async editUser(user: User) {
    if (user.config.mandants?.length === 0) {
      console.error(
        "Speichern von Benutzer ohne Mandantenberechtigung verhindert ..."
      );
      return null;
    }
    const response = await this.api.getBackendApi().put<User>(`/user`, user);
    store.commit("SET_USER", user);
    return response.data;
  }

  async updateUserConfig(userConfig: UserConfig) {
    if (userConfig.mandants?.length === 0) {
      console.error(
        "Speichern von Benutzer ohne Mandantenberechtigung verhindert ..."
      );
      this.router.push(Routes.dashboard); //FIXME: when user mandant deletion bug from dispatcherboard is fixed
      return null;
    }
    const response = await this.api
      .getBackendApi()
      .put<UserConfig>(`/user/config`, userConfig);
    store.commit("SET_USER_CONFIG", userConfig);
    return response.data;
  }

  async updateUserProfile(
    userProfileData: Partial<User>
  ): Promise<ApiResponse> {
    const response = await this.api
      .getBackendApi()
      .put<ApiResponse>(`/user/profile`, userProfileData);
    store.commit("SET_USER_PROFILE", userProfileData);
    return response.data;
  }

  async changePassword(passwordChange: PasswordChange): Promise<ApiResponse> {
    return (
      await this.api.getBackendApi().put(`/user/password`, passwordChange)
    ).data as ApiResponse;
  }

  async removeUser(usermail: string): Promise<void> {
    await this.api.getBackendApi().delete(`/user/${usermail}`);
  }
}
