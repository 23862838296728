import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withModifiers as _withModifiers, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "scroll-container"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_card_subtitle = _resolveComponent("v-card-subtitle")!
  const _component_v_tooltip = _resolveComponent("v-tooltip")!
  const _component_v_chip = _resolveComponent("v-chip")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createBlock(_component_v_container, {
    class: "dispatcherboard columns ma-0 px-1",
    fluid: ""
  }, {
    default: _withCtx(() => [
      (_ctx.smAndDown)
        ? (_openBlock(), _createBlock(_component_v_row, {
            key: 0,
            class: "closed-icons-row"
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.columns, (col) => {
                return (_openBlock(), _createBlock(_component_v_col, {
                  key: `icon-${col.id}`,
                  cols: "3"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_icon, {
                      onClick: _withModifiers(($event: any) => (_ctx.activateColumn(col.id)), ["stop"]),
                      class: _normalizeClass({
            'control-icon': true,
            'ml-2': true,
            'icon-highlight': _ctx.isClosed(col.id),
          }),
                      color: "primary",
                      size: "x-large"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(col.icon), 1)
                      ]),
                      _: 2
                    }, 1032, ["onClick", "class"])
                  ]),
                  _: 2
                }, 1024))
              }), 128))
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      _createVNode(_component_v_row, { class: "my-0 py-0" }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.columns, (col) => {
            return (_openBlock(), _createBlock(_component_v_col, {
              key: col.id,
              cols: _ctx.columnWidth(col.id),
              class: _normalizeClass(_ctx.columnClasses(col.id)),
              onMouseover: ($event: any) => (_ctx.hoveredColumn = col.id),
              onMouseleave: _cache[1] || (_cache[1] = ($event: any) => (_ctx.hoveredColumn = null))
            }, {
              default: _withCtx(() => [
                (!_ctx.smAndDown)
                  ? (_openBlock(), _createBlock(_component_v_container, {
                      key: 0,
                      class: "column-controll-icon d-flex ma-0 pa-0"
                    }, {
                      default: _withCtx(() => [
                        (_ctx.isClosed(col.id))
                          ? (_openBlock(), _createBlock(_component_v_icon, {
                              key: 0,
                              onClick: _withModifiers(($event: any) => (_ctx.reopenColumn(col.id)), ["stop"]),
                              elevation: 15,
                              class: "control-icon ml-2",
                              color: "primary"
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(col.icon), 1)
                              ]),
                              _: 2
                            }, 1032, ["onClick"]))
                          : _createCommentVNode("", true),
                        (_ctx.isClosed(col.id))
                          ? (_openBlock(), _createBlock(_component_v_spacer, { key: 1 }))
                          : _createCommentVNode("", true),
                        (
              _ctx.activeColumn !== col.id &&
              !_ctx.isClosed(col.id) &&
              _ctx.hoveredColumn === col.id
            )
                          ? (_openBlock(), _createBlock(_component_v_icon, {
                              key: 2,
                              onClick: _withModifiers(($event: any) => (_ctx.activateColumn(col.id)), ["stop"]),
                              class: "control-icon",
                              color: "grey-lighten-0",
                              size: "x-small"
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(" fa-solid fa-chevron-right ")
                              ]),
                              _: 2
                            }, 1032, ["onClick"]))
                          : _createCommentVNode("", true),
                        (_ctx.activeColumn === col.id)
                          ? (_openBlock(), _createBlock(_component_v_icon, {
                              key: 3,
                              onClick: _withModifiers(($event: any) => (_ctx.deactivateColumn(col.id)), ["stop"]),
                              class: "control-icon",
                              color: "grey-lighten-0",
                              size: "x-small"
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(" fa-solid fa-chevron-left ")
                              ]),
                              _: 2
                            }, 1032, ["onClick"]))
                          : _createCommentVNode("", true),
                        _createVNode(_component_v_spacer),
                        (
              !_ctx.smAndDown &&
              !_ctx.user.config.hideTip?.onDispatcherBoard &&
              !_ctx.isActive(col.id)
            )
                          ? (_openBlock(), _createBlock(_component_v_chip, {
                              key: 4,
                              variant: _ctx.vStyle.input.variant || undefined,
                              rounded: _ctx.vStyle.input.rounded || undefined,
                              label: "",
                              size: "large",
                              class: "pa-4",
                              closable: "",
                              color: "primary",
                              border: "md opacity-50",
                              "prepend-icon": col.icon,
                              "onClick:close": _cache[0] || (_cache[0] = ($event: any) => (_ctx.countDeactivateHeadlines()))
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_card_subtitle, null, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(col.headline), 1)
                                  ]),
                                  _: 2
                                }, 1024),
                                _createVNode(_component_v_tooltip, {
                                  activator: "parent",
                                  location: "bottom"
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(col.tooltip), 1)
                                  ]),
                                  _: 2
                                }, 1024)
                              ]),
                              _: 2
                            }, 1032, ["variant", "rounded", "prepend-icon"]))
                          : _createCommentVNode("", true),
                        _createVNode(_component_v_spacer),
                        (!_ctx.isClosed(col.id) && _ctx.hoveredColumn === col.id)
                          ? (_openBlock(), _createBlock(_component_v_icon, {
                              key: 5,
                              onClick: _withModifiers(($event: any) => (_ctx.closeColumn(col.id)), ["stop"]),
                              class: "control-icon",
                              color: "grey-lighten-0",
                              size: "x-small"
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(" fa-solid fa-xmark ")
                              ]),
                              _: 2
                            }, 1032, ["onClick"]))
                          : _createCommentVNode("", true)
                      ]),
                      _: 2
                    }, 1024))
                  : _createCommentVNode("", true),
                (!_ctx.isClosed(col.id))
                  ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                      (_openBlock(), _createBlock(_resolveDynamicComponent(col.component), {
                        isActive: _ctx.isActive(col.id),
                        interComponentMessage: _ctx.interComponentMessage,
                        lastUpdateTimeline: _ctx.lastUpdateTimeline,
                        loggedInMandantUuids: _ctx.loggedInMandantUuids,
                        mandants: _ctx.mandants,
                        softwareIntegration: _ctx.softwareIntegration,
                        user: _ctx.user
                      }, null, 8, ["isActive", "interComponentMessage", "lastUpdateTimeline", "loggedInMandantUuids", "mandants", "softwareIntegration", "user"]))
                    ]))
                  : _createCommentVNode("", true)
              ]),
              _: 2
            }, 1032, ["cols", "class", "onMouseover"]))
          }), 128))
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}