// src/adapter/anzeigendaten.adapter.ts
import moment from "moment";
import { IndexJobAd } from "@/models/external/index-anzeigendaten.model";
import { JobAdLead } from "@/models/job-ad-lead.model";
import store from "@/store/store";
import { ContactStatus } from "@/enums/contact-status.enum";

export class AnzeigendatenAdapter {
  static adaptIndexCustomerToCustomer(item: IndexJobAd, index: number): any {
    let mandant = store.state.company.mandants[0].uuid;
    const loggedInMandants =
      store.state.company.loggedInUser.config.loggedInMandants;
    if (loggedInMandants.length > 0) mandant = loggedInMandants[0];

    const uniqueNumber = `index_${Date.now()}_${index}`;
    let contacts = [];

    const contactPersons = Array.isArray(item["COMPANY-CONTACT-PERSON"])
      ? item["COMPANY-CONTACT-PERSON"]
      : [];

    contacts = contactPersons.map((person) => ({
      salutation: person["CONTACT-PERSON-SALUTATION"] || null,
      title: person["CONTACT-PERSON-TITLE"] || null,
      role: person["CONTACT-PERSON-FUNCTION"] || null,
      firstName: person["CONTACT-PERSON-FIRSTNAME"] || null,
      lastName: person["CONTACT-PERSON-LASTNAME"] || null,
      phone: person["CONTACT-PERSON-PHONE"] || null,
      email: person["CONTACT-PERSON-EMAIL"] || null,
      status: ContactStatus.AusIndex,
    }));

    return {
      customerNumber: uniqueNumber,
      mandants: [mandant],
      generalData: {
        name: item.COMPANY,
        name2: null,
        name3: null,
        searchTerm: item.COMPANY,
        costCenter: null,
        customerSince: null,
        accountsReceivable: null,
        createdOn: null,
        status: "Interessent",
      },
      addressAndCommunication: {
        street:
          item["COMPANY-ADDRESS"] +
          " " +
          (item["COMPANY-ADDRESS-NUMBER"] || ""),
        postalCode: item["COMPANY-POSTAL-CODE"],
        city: item["COMPANY-CITY"],
        country: item["COMPANY-COUNTRY"] === "D" ? "Deutschland" : "Unbekannt",
        state: null,
        phone1: item["COMPANY-PHONE"],
        phone2: null,
        email: item["COMPANY-EMAIL"],
        website: item["COMPANY-INTERNET"],
      },
      furtherInformation: {
        dispatcher: null,
        industries: item.BRANCHE,
        WZ08: item.WZ08,
        Siret: item["COMPANY-SIRET"],
      },
      additionalInfo: {
        info1: null,
        info2: null,
        info3: null,
        indexLink: item["COMPANY-LINK"],
        indexCompanyId: item["COMPANY-ID"],
      },
      reporting: {
        jobs: item["COMPANY-REPORT-JOBS"],
        company: item["COMPANY-REPORT"],
        groupMedia: item["COMPANY-REPORT-GROUP-MEDIA"],
        groupFlexible: item["COMPANY-REPORT-GROUP-FLEXIBLE"],
        group: item["COMPANY-REPORT-GROUP"],
        media: item["COMPANY-REPORT-MEDIA"],
        flexible: item["COMPANY-REPORT-FLEXIBLE"],
      },
      contacts,
      jobAds: [],
    };
  }
  static adaptIndexJobAdToJobAd(indexJobAd: IndexJobAd): JobAdLead {
    const retrievalDate = moment().format("DD.MM.YYYY");

    const jobAd: JobAdLead = {
      adId: indexJobAd["AD-ID"] || "not found",
      retrievalDate: retrievalDate,
      jobTitle: indexJobAd["POSITION"] || "",
      jobAdText: indexJobAd["TEXT"] || "",
      company: {
        address: indexJobAd["COMPANY-ADDRESS"] ?? undefined,
        addressNumber: indexJobAd["COMPANY-ADDRESS-NUMBER"] ?? undefined,
        city: indexJobAd["COMPANY-CITY"] || "",
        country: indexJobAd["COMPANY-COUNTRY"] ?? undefined,
        email: indexJobAd["COMPANY-EMAIL"] ?? undefined,
        employees: indexJobAd["COMPANY-EMPLOYEES"] ?? undefined,
        internet: indexJobAd["COMPANY-INTERNET"] ?? undefined,
        latitude: indexJobAd["COMPANY-LATITUDE"] ?? undefined,
        link: indexJobAd["COMPANY-LINK"] ?? undefined,
        longitude: indexJobAd["COMPANY-LONGITUDE"] ?? undefined,
        name: indexJobAd.COMPANY ?? undefined,
        phone: indexJobAd["COMPANY-PHONE"] ?? undefined,
        postalCode: indexJobAd["COMPANY-POSTAL-CODE"] || "",
        report: indexJobAd["COMPANY-REPORT"] ?? undefined,
        reportGroup: indexJobAd["COMPANY-REPORT-GROUP"] ?? undefined,
        reportGroupFlexible:
          indexJobAd["COMPANY-REPORT-GROUP-FLEXIBLE"] ?? undefined,
        reportJobs: indexJobAd["COMPANY-REPORT-JOBS"] ?? undefined,
        reportMedia: indexJobAd["COMPANY-REPORT-MEDIA"] ?? undefined,
      },
      adContactPerson: {
        email: indexJobAd["AD-CONTACT-PERSON-EMAIL"] ?? undefined,
        firstname: indexJobAd["AD-CONTACT-PERSON-FIRSTNAME"] ?? undefined,
        function: indexJobAd["AD-CONTACT-PERSON-FUNCTION"] ?? undefined,
        lastname: indexJobAd["AD-CONTACT-PERSON-LASTNAME"] ?? undefined,
        phone: indexJobAd["AD-CONTACT-PERSON-PHONE"] ?? undefined,
        salutation: indexJobAd["AD-CONTACT-PERSON-SALUTATION"] ?? undefined,
        title: indexJobAd["AD-CONTACT-PERSON-TITLE"] ?? undefined,
      },
      bfa: {
        avgAdvertisementCost:
          indexJobAd["BA-AVG-ADVERTISEMENT-COST"] ?? undefined,
        avgVacancyTime: indexJobAd["BA-AVG-VACANCY-TIME"] ?? undefined,
        companiesWithExpenditure:
          indexJobAd["BA-COMPANIES-WITH-EXPENDITURE"] ?? undefined,
        jobClassification: indexJobAd["BA-JOB-CLASSIFICATION"] ?? undefined,
        jobClassificationRegion:
          indexJobAd["BA-JOB-CLASSIFICATION-REGION"] ?? undefined,
        jobSeekers: indexJobAd["BA-JOB-SEEKERS"] ?? undefined,
        jobVacancies: indexJobAd["BA-JOB-VACANCIES"] ?? undefined,
        professionalShortage:
          indexJobAd["BA-PROFESSIONAL-SHORTAGE"] ?? undefined,
        searchingPerPosition:
          indexJobAd["BA-SEARCHING-PER-POSITION"] ?? undefined,
        tempRate: indexJobAd["BA-TEMP-RATE"] ?? undefined,
      },
      branche: indexJobAd["BRANCHE"] ?? undefined,
      careerLevel: indexJobAd["CAREER-LEVEL"] ?? undefined,
      date: indexJobAd["DATE"] ?? undefined,
      internLinkPdf: indexJobAd["INTERN-LINK-PDF"] ?? undefined,
      internLinkTxt: indexJobAd["INTERN-LINK-TXT"] ?? undefined,
      jobCategory: indexJobAd["JOB-CATEGORY"] ?? undefined,
      jobCategoryCode: indexJobAd["JOB-CATEGORY-CODE"] ?? undefined,
      kld5: indexJobAd["KLD5"] ?? undefined,
      kldb5: indexJobAd["KLDB5"] ?? undefined,
      link: indexJobAd["LINK"] ?? undefined,
      listPrice: indexJobAd["LIST-PRICE"] ?? undefined,
      location: indexJobAd["LOCATION"] ?? undefined,
      source: indexJobAd["SOURCE"] ?? undefined,
      sourceText: indexJobAd["SOURCE-TEXT"] ?? undefined,
      wz08: indexJobAd["WZ08"] ?? undefined,
    };

    return jobAd;
  }
}
