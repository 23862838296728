<template>
  <GetPdHubUserToken ref="getPdHubUserTokenComponent"></GetPdHubUserToken>
  <v-card
    :variant="vStyle.card.variant || undefined"
    :rounded="vStyle.card.rounded || undefined"
    :border="vStyle.card.border || undefined"
  >
    <v-card-title>{{ cardTitle }}</v-card-title>
    <v-card-subtitle>{{ cardSubtitle }}</v-card-subtitle>
    <v-card-text>
      <v-btn
        variant="outlined"
        :rounded="vStyle.btn.rounded || undefined"
        :border="vStyle.btn.border || undefined"
        @click="openGetUserTokenModal"
        >{{ buttonText }}</v-btn
      >
    </v-card-text>
  </v-card>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import moment from "moment";
import { User } from "@/models/user.model";
import GetPdHubUserToken from "@/components/modals/GetPdHubUserToken.vue";

export default defineComponent({
  name: "TokenStatusCard",
  components: {
    GetPdHubUserToken,
  },
  props: {
    user: {
      type: Object as PropType<User>,
      required: true,
    },
  },
  data() {
    return {
      vStyle: this.$store.state.vStyle,
    };
  },
  computed: {
    cardTitle(): string {
      const token = this.user.config?.pdHub?.token;
      const tokenValidTill = this.user.config?.pdHub?.tokenValidTill;

      if (token && tokenValidTill) {
        if (moment().isBefore(moment(tokenValidTill))) {
          return "Aktuell bei PD-Hub angemeldet";
        } else {
          return "Token abgelaufen";
        }
      } else {
        return "Nicht angemeldet";
      }
    },
    cardSubtitle(): string {
      const tokenValidTill = this.user.config?.pdHub?.tokenValidTill;

      if (tokenValidTill) {
        if (moment().isBefore(moment(tokenValidTill))) {
          return `Gültig bis ${moment(tokenValidTill).format(
            "DD.MM.YYYY HH:mm"
          )}`;
        } else {
          return `Abgelaufen seit ${moment(tokenValidTill).format(
            "DD.MM.YYYY HH:mm"
          )}`;
        }
      } else {
        return "";
      }
    },
    buttonText(): string {
      const token = this.user.config?.pdHub?.token;
      const tokenValidTill = this.user.config?.pdHub?.tokenValidTill;

      if (token && tokenValidTill) {
        if (moment().isBefore(moment(tokenValidTill))) {
          return "Neuer Token";
        } else {
          return "Token erneuern";
        }
      } else {
        return "Anmelden";
      }
    },
  },
  methods: {
    openGetUserTokenModal() {
      if (this.$refs.getPdHubUserTokenComponent) {
        (this.$refs.getPdHubUserTokenComponent as any).openModal();
      }
    },
  },
});
</script>
