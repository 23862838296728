import { LinkingEvent } from "@/models/linking.model";
import moment from "moment";

export function checkExpiredReminder(
  latestEvent: LinkingEvent,
  reminderConfig: any
) {
  const now = moment();
  for (const dateString of latestEvent.eventDate) {
    const eventMoment = moment(dateString)
      .add(reminderConfig.daysAfter, "days")
      .add(reminderConfig.hoursAfter, "hours");

    if (eventMoment.isBefore(now)) {
      const duration = moment.duration(now.diff(eventMoment));
      const days = Math.floor(duration.asDays());
      const hours = Math.floor(duration.asHours() % 24);

      let expiredSince = "";

      if (days > 0) {
        expiredSince += `${days} Tag${days > 1 ? "e" : ""}`;
      }

      if (hours > 0) {
        if (expiredSince.length > 0) expiredSince += " und ";
        expiredSince += `${hours} Stunde${hours > 1 ? "n" : ""}`;
      }

      return expiredSince;
    }
  }
  return null;
}
