<template>
  <AdminMenu />
  <PdMasterHeader v-if="layout === AppLayout.pdmaster"></PdMasterHeader>
  <v-container :fluid="true">
    <v-row>
      <v-col cols="12" md="4">
        <v-img
          class="mb-2"
          :width="100"
          aspect-ratio="1/1"
          cover
          :src="assetsSettings"
        ></v-img>
        <v-card
          :variant="vStyle.card.variant || undefined"
          :rounded="vStyle.card.rounded || undefined"
          :border="vStyle.card.border || undefined"
        >
          <v-card-title>Recurion.ai ist eine fortschrittliche KI</v-card-title>
          <v-card-subtitle>
            die die Ihr Unternehmen revolutioniert!
          </v-card-subtitle>
          <v-card-text>
            Sie übernimmt die Erstellung Nachrichten die speziell auf Ihr
            Unternehmen eingestellt sind, betreut somit Kandidaten, Kunden und
            Zeitarbeiter, generiert Angebote und Mitarbeiterprofile, schreibt
            Stellen aus und rekrutiert damit passende Kandidaten.
          </v-card-text>
          <v-card-text>
            Als digitaler Vertriebsassistent entlastet Recurion
            Vertriebsdisponenten von administrativen Aufgaben, sodass diese sich
            auf die Kommunikation mit Kunden und Kandidaten konzentrieren
            können. Dadurch werden Effizienz und Qualität in der erheblich
            gesteigert.
          </v-card-text>
          <v-card-subtitle></v-card-subtitle>
        </v-card>
        <v-card
          :variant="vStyle.card.variant || undefined"
          :rounded="vStyle.card.rounded || undefined"
          :border="vStyle.card.border || undefined"
          class="mt-5"
          v-if="getUserRole() === 0"
        >
          <v-card-title>uberAdmin ONLY</v-card-title>
          <v-card-text>
            <v-text-field
              :variant="vStyle.input.variant || undefined"
              :rounded="vStyle.input.rounded || undefined"
              :base-color="vStyle.input.baseColor || undefined"
              :color="vStyle.input.color || undefined"
              v-if="companyConfigModel.apiKeys"
              v-model="companyConfigModel.apiKeys.openAi"
              label="api key for openAi"
              class="my-2"
            ></v-text-field>
            <v-text-field
              :variant="vStyle.input.variant || undefined"
              :rounded="vStyle.input.rounded || undefined"
              :base-color="vStyle.input.baseColor || undefined"
              :color="vStyle.input.color || undefined"
              v-if="companyConfigModel.apiKeys"
              v-model="companyConfigModel.apiKeys.mistralAi"
              label="api key for mistralAi"
              class="my-2"
            ></v-text-field>
            <v-btn
              :variant="vStyle.btn.variant || undefined"
              :rounded="vStyle.btn.rounded || undefined"
              :border="vStyle.btn.border || undefined"
              @click="updateCompanyConfig"
              color="success"
              >SET</v-btn
            >
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="8">
        <div class="d-flex">
          <v-spacer></v-spacer>
          <v-menu>
            <template v-slot:activator="{ props }">
              <v-btn
                :variant="vStyle.btn.variant || undefined"
                :rounded="vStyle.btn.rounded || undefined"
                :border="vStyle.btn.border || undefined"
                v-bind="props"
                ><v-icon>fa-solid fa-bars</v-icon>Einstellungen</v-btn
              >
            </template>
            <v-list>
              <v-list-item
                v-for="(item, index) in filteredItems"
                :key="index"
                @click="setActiveComponent(item.component)"
              >
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
        <component
          :is="activeConfigComponent"
          v-model="aiData"
          @save="updateCompanyConfig"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import AdminMenu from "@/components/menus/admin-menu.component.vue";
import AiConfigEmailTemplates from "./AiConfigEmailTemplates.vue";
import AiConfigCompanyPrompts from "./AiConfigCompanyPrompts.vue";
import AiConfigUserPrompts from "./AiConfigUserPrompts.vue";
import AiConfigJobAdTemplate from "./AiConfigJobAdTemplate.vue";
import { CompanyConfigComponentTypes } from "@/enums/company-config-components.enum";
import { CompanyConfig } from "@/models/company-config.model";
import DialogService from "@/services/dialog.service";
import Recurion from "@/components/config/ai/Recurion.vue";
import { CompanyConfigService } from "@/services/api/company-config.service";
import { UserHelperService } from "@/services/user-helper.service";
import PdMasterHeader from "@/components/header/PdMasterHeader.vue";
import { AppLayout } from "@/enums/app-layout.enum";

export default defineComponent({
  name: "ConfigAi",
  components: {
    AdminMenu,
    AiConfigEmailTemplates,
    AiConfigJobAdTemplate,
    AiConfigCompanyPrompts,
    AiConfigUserPrompts,
    PdMasterHeader,
    Recurion,
  },
  data() {
    const userRole = this.getUserRole();

    return {
      assetsSettings: `${this.$store.getters.getEnv.VUE_APP_ASSETS_URL}/dp_settings.gif`,
      apiService: new CompanyConfigService(),
      items: [
        {
          title: "Nachrichten Vorlagen",
          component: CompanyConfigComponentTypes.AiConfigEmailTemplates,
          minRole: 5,
        },
        {
          title: "Vorlage Stellenanzeige",
          component: CompanyConfigComponentTypes.AiConfigJobAdTemplate,
          minRole: 5,
        },
        {
          title: "Prompts: Unternehmensebene",
          component: CompanyConfigComponentTypes.AiConfigCompanyPrompts,
          minRole: 5,
        },
        {
          title: "Prompts: Benutzerebene",
          component: CompanyConfigComponentTypes.AiConfigUserPrompts,
          minRole: 15,
        },
      ],
      companyConfigModel: {} as CompanyConfig,
      CompanyConfigComponentTypes: CompanyConfigComponentTypes,
      activeConfigComponent: "Recurion",
      AppLayout: AppLayout,
      layout: this.$store.getters.getEnv.VUE_APP_LAYOUT,
      userRole: userRole,
      vStyle: this.$store.state.vStyle,
    };
  },
  computed: {
    aiData: {
      get() {
        return this.companyConfigModel.aiData;
      },
      set(value: any) {
        this.companyConfigModel.aiData = value;
      },
    },
    filteredItems() {
      return this.items.filter((item) => this.userRole <= item.minRole);
    },
  },
  created() {
    this.apiService.getConfig().then((companyConfig: CompanyConfig) => {
      this.companyConfigModel = companyConfig;
    });
    this.getUserRole();
  },
  methods: {
    getUserRole() {
      const userService = UserHelperService.getInstance();
      const userRole = userService.getUserRole();
      return userRole;
    },

    setActiveComponent(component: CompanyConfigComponentTypes) {
      this.activeConfigComponent = component;
    },
    updateCompanyConfig() {
      this.apiService
        .putCompanyConfig(this.companyConfigModel)
        .then(() => {
          this.$store.commit("UPDATE_COMPANY_CONFIG", this.companyConfigModel);
          DialogService.alert("Configuration updated successfully.");
        })
        .catch((err) => {
          console.error(err);
          DialogService.alert("Failed to update configuration.");
        });
    },
  },
});
</script>

<style scoped>
.v-row {
  padding: 2rem 1rem;
}

.title {
  display: flex;
  justify-content: space-between;
  color: var(--color-primary);
}
</style>
