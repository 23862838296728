<!--src/components/disposition/DemandItem.vue-->
<template>
  <v-container class="ma-0 pa-0">
    <v-hover>
      <template v-slot:default="{ isHovering, props }">
        <v-container class="ma-0 pa-0 item" v-if="isFullyCollapsed">
          <v-card-title
            :style="{
              backgroundColor: isHovering ? itemHoverColor : itemColor,
            }"
            class="d-flex justify-space-between pa-0"
          >
            <span
              class="ml-x font-weight-light text-caption text-medium-emphasis"
            >
              <v-tooltip activator="parent" location="bottom">{{
                getDemandStateInfo(parseInt(demand.demandState)).label
              }}</v-tooltip>
              <i
                :class="`fa-solid ${
                  getDemandStateInfo(parseInt(demand.demandState)).icon
                }`"
              ></i>
            </span>
            <span class="pa-0 text-body-2">
              {{ demand.demands[0].quantity }}x
              {{ demand.demands[0].position }}
              <span
                class="ml-1 font-weight-light text-caption text-medium-emphasis"
              >
                {{ demand.customer }}</span
              >
            </span>
            <span
              class="mx-1 font-weight-light text-caption text-medium-emphasis"
            >
              {{ demand.demands[0].location.city }}</span
            ></v-card-title
          >
          <v-divider class="my-1"></v-divider>
        </v-container>
        <v-card
          v-if="!isFullyCollapsed"
          :variant="vStyle.boardItem.variant || undefined"
          :rounded="vStyle.boardItem.rounded || undefined"
          :border="vStyle.boardItem.border || undefined"
          :elevation="vStyle.boardItem.elevation || undefined"
          class="item pa-2 mb-2"
          v-bind="props"
          :color="isHovering ? itemHoverColor : itemColor"
          :class="{
            'fully-collapsed': isFullyCollapsed,
            expanded: isExpanded,
            'fully-expanded': isFullyExpanded,
          }"
          @dblclick="toggleFullExpansion"
          @dragover.prevent
        >
          <!-- Basierende Informationen zum Demand -->
          <v-card-title class="pa-0 text-body-2">
            {{ demand.demands[0].quantity }}x
            <strong>{{ demand.demands[0].position }}</strong>
            <span style="font-size: 0.7rem">
              ({{ demand.demands[0].location.postcode }}
              {{ demand.demands[0].location.city }})</span
            >
          </v-card-title>
          <v-card-subtitle class="pl-0"> {{ demand.customer }}</v-card-subtitle>
          <button @click="toggleExpansion" class="toggle-button">
            {{ isExpanded ? "▲" : "▼" }}
          </button>
          <div class="demand-state-hover">
            <v-tooltip activator="parent" location="bottom">{{
              getDemandStateInfo(parseInt(demand.demandState)).label
            }}</v-tooltip>
            <i
              :class="`fa-solid ${
                getDemandStateInfo(parseInt(demand.demandState)).icon
              }`"
            ></i>
          </div>
          <v-divider class="mt-2"></v-divider>
          <v-container class="ma-0 px-0 d-flex">
            <v-chip
              v-if="
                demand.demandContactPerson.firstName ||
                demand.demandContactPerson.lastName
              "
              prepend-icon="fa-solid fa-address-book"
              class="text-body-2"
            >
              {{ demand.demandContactPerson.firstName }}
              {{ demand.demandContactPerson.lastName }}
              <v-tooltip activator="parent" location="bottom">{{
                demand.demandContactPerson.position
              }}</v-tooltip>
            </v-chip>
            <v-spacer></v-spacer>
            <PhoneClient
              v-if="
                isExpanded &&
                demand.demandContactPerson.contactDetails.phoneNumber
              "
              :phoneNumbers="filteredPhoneNumbers"
            ></PhoneClient>
            <MailClient
              v-if="
                isExpanded && demand.demandContactPerson.contactDetails.email
              "
              :emailAddresses="filteredEmail"
              :AiMessageType="'mailCandidate'"
              :emailObject="email"
              :signature="signatureText()"
            ></MailClient>
          </v-container>
          <v-divider class="mb-3"></v-divider>
          <p class="pl-0 mb-2 text-body-2">Anfragen</p>
          <div v-if="isExpanded">
            <v-card
              :variant="vStyle.card.variant || undefined"
              :rounded="vStyle.card.rounded || undefined"
              :border="vStyle.card.border || undefined"
              class="mb-4"
              v-for="(detail, index) in demand.demands"
              :key="`demand-${demand.demandId}-detail-${index}`"
            >
              <v-card-title
                >{{ detail.quantity }}x {{ detail.position }}</v-card-title
              >
              <v-chip
                color="primary"
                density="compact"
                class="ml-4 mt-2"
                prepend-icon="fa-solid fa-location-dot"
              >
                {{ detail.location.postcode }}
                {{ detail.location.city }}
              </v-chip>
              <v-chip
                color="var(--color-tertiary)"
                density="compact"
                class="ml-4 mt-2"
                prepend-icon="fa-solid fa-calendar-xmark"
              >
                {{ formatApplicationDeadline(detail.applicationDeadline) }}
              </v-chip>
              <v-chip
                color="var(--color-green)"
                density="compact"
                class="ml-4 mt-2"
                prepend-icon="fa-solid fa-coins"
              >
                {{ detail.budget.amount }}
                {{ detail.budget.currency }}
              </v-chip>
              <div class="mt-5" v-if="detail.description">
                <v-card-subtitle
                  >Beschreibung
                  <v-divider></v-divider>
                </v-card-subtitle>
                <v-card-text>
                  {{ detail.description }}
                </v-card-text>
              </div>
              <div v-if="detail.additionalInformation">
                <v-card-subtitle
                  >Weitere Informationen
                  <v-divider></v-divider>
                </v-card-subtitle>
                <v-card-text>
                  {{ detail.additionalInformation }}
                </v-card-text>
              </div>
              <v-card-subtitle v-if="detail.requirements.length > 0"
                >Vorraussetzungen
                <v-divider></v-divider>
              </v-card-subtitle>
              <v-container class="mx-1">
                <v-chip
                  v-for="(requirement, rIndex) in detail.requirements"
                  :key="`detail-${index}-requirement-${rIndex}`"
                  density="compact"
                  class="ma-1"
                >
                  {{ requirement }}
                </v-chip>
              </v-container>
            </v-card>
          </div>
        </v-card>
      </template>
    </v-hover>
  </v-container>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { Demand } from "@/models/demand.model";
import moment from "moment";
import MailClient from "./elements/MailClient.vue";
import PhoneClient from "./elements/PhoneClient.vue";
import { Salutation } from "@/enums/salutation.enum";
import { DemandService } from "@/services/api/demand.service";
import DialogService from "@/services/dialog.service";
import { DemandStates, getDemandStates } from "@/enums/demand-states.enum";
import { MessageContent } from "@/enums/empty-message.enum";

const demandStateIcons: { [key in DemandStates]: string } = {
  [DemandStates.Passive]: "fa-rectangle-ad",
  [DemandStates.Active]: "fa-circle-user",
  [DemandStates.Urgent]: "fa-exclamation-circle",
  [DemandStates.Priority]: "fa-star",
  [DemandStates.ExclusiveConfidential]: "fa-user-secret",
};

export default defineComponent({
  name: "DemandItem",
  emits: ["insertUpdatedDemand", "delete"],
  components: {
    MailClient,
    PhoneClient,
  },
  props: {
    demand: {
      type: Object as PropType<Demand>,
      required: true,
    },
    isFullyCollapsed: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      filteredEmail: [
        {
          mailaddress: this.demand.demandContactPerson.contactDetails.email,
          salutation: `Guten Tag ${this.demand.demandContactPerson.firstName} ${this.demand.demandContactPerson.lastName}`,
        },
      ] as any,
      filteredPhoneNumbers: [
        {
          label: "Telefon",
          value: this.demand.demandContactPerson.contactDetails.phoneNumber,
        },
      ] as any,
      isExpanded: false,
      isFullyExpanded: false,
      itemColor: "card",
      itemHoverColor: "cardHover",
      email: {
        subject: MessageContent.emptySubject as string,
        body: MessageContent.empty as string,
      },
      demandService: new DemandService(),
      vStyle: this.$store.state.vStyle,
    };
  },
  mounted() {
    this.checkDemandDates();
  },
  methods: {
    async checkDemandDates() {
      const askForDeletionAfterDays = 14;
      const today = moment();
      const demandCopy = JSON.parse(JSON.stringify(this.demand));

      for (let i = 0; i < demandCopy.demands.length; i++) {
        const detail = demandCopy.demands[i];
        const deadline = moment(detail.applicationDeadline);
        const diffDays = today.diff(deadline, "days");

        if (deadline.isBefore(today)) {
          this.itemColor = "tertiary-darken-1";
          this.itemHoverColor = "tertiary";

          if (diffDays > askForDeletionAfterDays) {
            const confirmed = await DialogService.confirm(
              `Die Anfrage ${detail.position} von ${this.demand.customer} ist seit mehr als ${askForDeletionAfterDays} Tagen abgelaufen!`,
              "Abbrechen",
              "Löschen"
            );

            if (confirmed) {
              if (
                demandCopy.demands.length === 1 ||
                demandCopy.demands.length === 0
              ) {
                this.$emit("delete", demandCopy.demandId);
              } else {
                demandCopy.demands.splice(i, 1);
                i--;
                this.demandService.updateDemand(demandCopy).then(() => {
                  this.$emit("insertUpdatedDemand", demandCopy);
                });
              }
            }
          }
        }
      }
    },
    formatApplicationDeadline(date: string) {
      return moment(date).format("DD.MM.YYYY");
    },
    getDemandStateInfo(state: DemandStates) {
      return {
        icon: demandStateIcons[state],
        label: getDemandStates(DemandStates)[state],
      };
    },
    signatureText() {
      const user = this.$store.state.company.loggedInUser;
      return `freundliche Grüße<br>${Salutation[user.salutation]} ${
        user.forename
      } ${user.lastname}<br>${this.$store.state.company.name}`;
    },
    toggleExpansion() {
      this.isExpanded = !this.isExpanded;
      if (!this.isExpanded) {
        this.isFullyExpanded = false;
      }
    },
    toggleFullExpansion() {
      if (this.isExpanded) {
        this.isFullyExpanded = !this.isFullyExpanded;
      }
    },
  },
});
</script>
<style scoped>
.demand-state-hover {
  background: none;
  border: none;
  color: grey;
  cursor: pointer;
  padding: 0;
  position: absolute;
  right: 0.75rem;
  text-shadow: var(--text-shadow);
  bottom: 0;
  z-index: 10;
}
</style>
