import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, withKeys as _withKeys, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-69c961cc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "d-flex" }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = {
  key: 0,
  class: "d-flex justify-start"
}
const _hoisted_4 = {
  key: 2,
  class: "d-flex justify-start"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_img = _resolveComponent("v-img")!
  const _component_v_tooltip = _resolveComponent("v-tooltip")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_select = _resolveComponent("v-select")!
  const _component_ZorstStatus = _resolveComponent("ZorstStatus")!
  const _component_ReportBug = _resolveComponent("ReportBug")!
  const _component_UserProfile = _resolveComponent("UserProfile")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_badge = _resolveComponent("v-badge")!
  const _component_v_list_item_title = _resolveComponent("v-list-item-title")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_menu = _resolveComponent("v-menu")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_label = _resolveComponent("v-label")!
  const _component_SpinnerElementHeader = _resolveComponent("SpinnerElementHeader")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: _normalizeClass(["d-flex justify-space-between align-end", _ctx.smAndDown ? 'flex-end' : ''])
    }, [
      _createElementVNode("div", null, [
        (!_ctx.smAndDown)
          ? (_openBlock(), _createBlock(_component_v_img, {
              key: 0,
              class: "mx-5 mb-4 mt-4 pd-master",
              width: _ctx.mdAndDown ? 50 : 200,
              "aspect-ratio": "1/1",
              cover: "",
              src: _ctx.mdAndDown ? _ctx.appLogoSmall : _ctx.appLogo,
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.toggleMenu('/dashboard')))
            }, null, 8, ["width", "src"]))
          : _createCommentVNode("", true),
        _createVNode(_component_v_tooltip, {
          activator: "parent",
          location: "right"
        }, {
          default: _withCtx(() => [
            _createTextVNode("zurück zum Dashboard")
          ]),
          _: 1
        })
      ]),
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.smAndDown ? 'ml-1 d-flex' : 'd-flex')
        }, [
          (_ctx.showDispositionTabs)
            ? (_openBlock(), _createBlock(_component_v_text_field, {
                key: 0,
                width: "200",
                class: _normalizeClass(_ctx.smAndDown ? 'mr-2' : 'mr-6'),
                variant: _ctx.vStyle.input.variant || undefined,
                rounded: _ctx.vStyle.input.rounded || undefined,
                "base-color": _ctx.vStyle.input.baseColor || undefined,
                color: _ctx.vStyle.input.color || undefined,
                modelValue: _ctx.searchTerm,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.searchTerm) = $event)),
                density: "compact",
                label: "Suchbegriff",
                onKeydown: _cache[2] || (_cache[2] = _withKeys(($event: any) => (_ctx.askColumnsForMatches()), ["enter"]))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_tooltip, {
                    activator: "parent",
                    location: "bottom"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("Einen oder mehrere Suchbegriffe eingeben und mit Enter bestätigen")
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["class", "variant", "rounded", "base-color", "color", "modelValue"]))
            : _createCommentVNode("", true),
          _createVNode(_component_v_select, {
            class: _normalizeClass(_ctx.smAndDown ? 'mr-2' : 'mr-10'),
            width: "200",
            variant: _ctx.vStyle.input.variant || undefined,
            rounded: _ctx.vStyle.input.rounded || undefined,
            "base-color": _ctx.vStyle.input.baseColor || undefined,
            color: _ctx.vStyle.input.color || undefined,
            modelValue: _ctx.selectedMandants,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.selectedMandants) = $event)),
            density: "compact",
            items: _ctx.mandants,
            "item-title": "branchInitials",
            "item-value": "uuid",
            label: "Eingeloggte Niederlassungen",
            clearable: "",
            multiple: ""
          }, null, 8, ["class", "variant", "rounded", "base-color", "color", "modelValue", "items"])
        ], 2),
        (!_ctx.mdAndDown && _ctx.$store.state.company.softwareIntegration.zorst)
          ? (_openBlock(), _createBlock(_component_ZorstStatus, { key: 0 }))
          : _createCommentVNode("", true),
        (!_ctx.smAndDown)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createVNode(_component_ReportBug),
              _createVNode(_component_UserProfile)
            ]))
          : _createCommentVNode("", true),
        (_ctx.globalExpiredReminders.length > 0)
          ? (_openBlock(), _createBlock(_component_v_badge, {
              key: 2,
              color: "abort",
              content: _ctx.globalExpiredReminders.length,
              overlap: "",
              "offset-x": "0"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_icon, {
                  size: "small",
                  onMouseover: _ctx.showTooltip,
                  onMouseleave: _ctx.hideTooltip
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(" fa-solid fa-bell ")
                  ]),
                  _: 1
                }, 8, ["onMouseover", "onMouseleave"])
              ]),
              _: 1
            }, 8, ["content"]))
          : _createCommentVNode("", true),
        (_ctx.globalExpiredReminders.length === 0)
          ? (_openBlock(), _createBlock(_component_v_icon, {
              key: 3,
              size: "small"
            }, {
              default: _withCtx(() => [
                _createTextVNode(" fa-solid fa-bell ")
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        _createVNode(_component_v_btn, {
          class: "ml-4 mr-2",
          icon: "",
          density: "compact",
          variant: "text"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_icon, { class: "menu-icon" }, {
              default: _withCtx(() => [
                _createTextVNode(" fa-solid fa-ellipsis-vertical ")
              ]),
              _: 1
            }),
            _createVNode(_component_v_menu, {
              activator: "parent",
              "offset-y": ""
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_list, null, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredItems, (item) => {
                      return (_openBlock(), _createBlock(_component_v_list_item, {
                        key: item.text,
                        class: "d-flex",
                        onClick: ($event: any) => (_ctx.toggleMenu(item.route))
                      }, {
                        prepend: _withCtx(() => [
                          _createVNode(_component_v_icon, { size: "xs" }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(item.icon), 1)
                            ]),
                            _: 2
                          }, 1024)
                        ]),
                        default: _withCtx(() => [
                          _createVNode(_component_v_list_item_title, null, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(item.text), 1)
                            ]),
                            _: 2
                          }, 1024)
                        ]),
                        _: 2
                      }, 1032, ["onClick"]))
                    }), 128))
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ])
    ], 2),
    _createVNode(_component_v_divider, {
      thickness: 2,
      class: "border-opacity-25",
      color: "primary"
    }),
    (_ctx.showRecruitTabs)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(_component_v_btn, {
            variant: _ctx.smAndDown ? 'text' : 'outlined',
            class: _normalizeClass(["text-none text-subtitle-2 mr-2 px-2", _ctx.mdAndDown ? 'ml-2 mt-2' : 'mt-6']),
            rounded: _ctx.smAndDown ? undefined : _ctx.vStyle.input.rounded || undefined,
            "base-color": _ctx.vStyle.input.baseColor || undefined,
            color: _ctx.vStyle.input.color || 'primary',
            border: _ctx.smAndDown ? undefined : 'sm opacity-50',
            density: "compact",
            width: _ctx.smAndDown ? undefined : '200',
            size: _ctx.mdAndDown ? undefined : 'large',
            "prepend-icon": "fa-solid fa-table-cells",
            onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.selectTab(_ctx.HeaderTab.JobMatrix))),
            "data-tid": "recruit-link-jobmatrix"
          }, {
            default: _withCtx(() => [
              (_ctx.smAndDown)
                ? (_openBlock(), _createBlock(_component_v_label, {
                    key: 0,
                    class: "text-caption"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("Jobs Matrix")
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              (!_ctx.smAndDown)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                    _createTextVNode(" Jobanzeigen Matrix ")
                  ], 64))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["variant", "class", "rounded", "base-color", "color", "border", "width", "size"]),
          _createVNode(_component_v_btn, {
            variant: _ctx.smAndDown ? 'text' : 'outlined',
            class: _normalizeClass(["text-none text-subtitle-2 mr-2 px-2", _ctx.mdAndDown ? 'ml-2 mt-2' : 'mt-6']),
            rounded: _ctx.smAndDown ? undefined : _ctx.vStyle.input.rounded || undefined,
            "base-color": _ctx.vStyle.input.baseColor || undefined,
            color: _ctx.vStyle.input.color || 'primary',
            border: _ctx.smAndDown ? undefined : 'sm opacity-50',
            density: "compact",
            width: _ctx.smAndDown ? undefined : '200',
            size: _ctx.mdAndDown ? undefined : 'large',
            "prepend-icon": "fa-solid fa-table-list",
            onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.selectTab(_ctx.HeaderTab.JobList))),
            "data-tid": "recruit-link-joblist"
          }, {
            default: _withCtx(() => [
              (_ctx.smAndDown)
                ? (_openBlock(), _createBlock(_component_v_label, {
                    key: 0,
                    class: "text-caption"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("Jobs Liste")
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              (!_ctx.smAndDown)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                    _createTextVNode(" Jobanzeigen Liste ")
                  ], 64))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["variant", "class", "rounded", "base-color", "color", "border", "width", "size"])
        ]))
      : _createCommentVNode("", true),
    (_ctx.showDispositionTabs)
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: _normalizeClass(_ctx.mdAndDown ? 'd-flex justify-space-between' : 'd-flex justify-start')
        }, [
          _createVNode(_component_v_btn, {
            variant: _ctx.smAndDown ? 'text' : 'outlined',
            class: _normalizeClass(["text-none text-subtitle-2 mr-2 px-2", _ctx.mdAndDown ? 'ml-2 mt-2' : 'mt-6']),
            rounded: _ctx.smAndDown ? undefined : _ctx.vStyle.input.rounded || undefined,
            "base-color": _ctx.vStyle.input.baseColor || undefined,
            color: _ctx.vStyle.input.color || 'primary',
            border: _ctx.smAndDown ? undefined : 'sm opacity-50',
            density: "compact",
            width: _ctx.smAndDown ? undefined : '200',
            size: _ctx.mdAndDown ? undefined : 'large',
            "prepend-icon": "fa-solid fa-envelope-open-text",
            onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.openZvooveRecruitLink()))
          }, {
            default: _withCtx(() => [
              (_ctx.smAndDown)
                ? (_openBlock(), _createBlock(_component_v_label, {
                    key: 0,
                    class: "text-caption"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("Neu")
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              (!_ctx.smAndDown)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                    _createTextVNode(" Bewerbungen ATS ")
                  ], 64))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["variant", "class", "rounded", "base-color", "color", "border", "width", "size"]),
          _createVNode(_component_v_btn, {
            variant: _ctx.smAndDown ? 'text' : 'outlined',
            class: _normalizeClass(["text-none text-subtitle-2 mr-2 px-2", _ctx.mdAndDown ? 'ml-2 mt-2' : 'mt-6 ']),
            rounded: _ctx.smAndDown ? undefined : _ctx.vStyle.input.rounded || undefined,
            "base-color": _ctx.vStyle.input.baseColor || undefined,
            color: _ctx.vStyle.input.color || 'primary',
            border: _ctx.smAndDown ? undefined : 'sm opacity-50',
            density: "compact",
            width: _ctx.smAndDown ? undefined : '200',
            size: _ctx.mdAndDown ? undefined : 'large',
            "prepend-icon": "fa-solid fa-at",
            onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.openZvooveRecruitLink(true)))
          }, {
            default: _withCtx(() => [
              (_ctx.smAndDown)
                ? (_openBlock(), _createBlock(_component_v_label, {
                    key: 0,
                    class: "text-caption"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("Mail")
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              (!_ctx.smAndDown)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                    _createTextVNode(" E-Mails ATS ")
                  ], 64))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["variant", "class", "rounded", "base-color", "color", "border", "width", "size"]),
          _createVNode(_component_v_btn, {
            variant: _ctx.smAndDown ? 'text' : 'outlined',
            class: _normalizeClass(["text-none text-subtitle-2 mr-2 px-2", _ctx.mdAndDown ? 'ml-2 mt-2' : 'mt-6']),
            rounded: _ctx.smAndDown ? undefined : _ctx.vStyle.input.rounded || undefined,
            "base-color": _ctx.vStyle.input.baseColor || undefined,
            color: _ctx.vStyle.input.color || 'primary',
            border: _ctx.smAndDown ? undefined : 'sm opacity-50',
            density: "compact",
            width: _ctx.smAndDown ? undefined : '200',
            size: _ctx.mdAndDown ? undefined : 'large',
            "prepend-icon": "fa-solid fa-people-group",
            onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.selectTab(_ctx.HeaderTab.DispatcherBoard)))
          }, {
            default: _withCtx(() => [
              (_ctx.smAndDown)
                ? (_openBlock(), _createBlock(_component_v_label, {
                    key: 0,
                    class: "text-caption"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("Dispo")
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              (!_ctx.smAndDown)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                    _createTextVNode("Dispoboard")
                  ], 64))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["variant", "class", "rounded", "base-color", "color", "border", "width", "size"]),
          _createVNode(_component_v_btn, {
            variant: _ctx.smAndDown ? 'text' : 'outlined',
            class: _normalizeClass(["text-none text-subtitle-2 mr-2 px-2", _ctx.mdAndDown ? 'ml-2 mt-2' : 'mt-6']),
            rounded: _ctx.smAndDown ? undefined : _ctx.vStyle.input.rounded || undefined,
            "base-color": _ctx.vStyle.input.baseColor || undefined,
            color: _ctx.vStyle.input.color || 'primary',
            border: _ctx.smAndDown ? undefined : 'sm opacity-50',
            density: "compact",
            width: _ctx.smAndDown ? undefined : '200',
            size: _ctx.mdAndDown ? undefined : 'large',
            "prepend-icon": "fa-solid fa-calendar-days",
            onClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.selectTab(_ctx.HeaderTab.Calendar)))
          }, {
            default: _withCtx(() => [
              (_ctx.smAndDown)
                ? (_openBlock(), _createBlock(_component_v_label, {
                    key: 0,
                    class: "text-caption"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("Cal")
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              (!_ctx.smAndDown)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                    _createTextVNode(" Kalender ")
                  ], 64))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["variant", "class", "rounded", "base-color", "color", "border", "width", "size"])
        ], 2))
      : _createCommentVNode("", true),
    (_ctx.showAdministrationTabs)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createVNode(_component_v_btn, {
            variant: _ctx.smAndDown ? 'text' : 'outlined',
            class: _normalizeClass(["text-none text-subtitle-2 mr-2 px-2", _ctx.mdAndDown ? 'ml-2 mt-2' : 'mt-6']),
            rounded: _ctx.smAndDown ? undefined : _ctx.vStyle.input.rounded || undefined,
            "base-color": _ctx.vStyle.input.baseColor || undefined,
            color: _ctx.vStyle.input.color || 'primary',
            border: _ctx.smAndDown ? undefined : 'sm opacity-50',
            density: "compact",
            width: _ctx.smAndDown ? undefined : '200',
            size: _ctx.mdAndDown ? undefined : 'large',
            "prepend-icon": "fa-solid fa-file-lines",
            onClick: _cache[10] || (_cache[10] = ($event: any) => (_ctx.selectTab(_ctx.HeaderTab.Documentation)))
          }, {
            default: _withCtx(() => [
              (_ctx.smAndDown)
                ? (_openBlock(), _createBlock(_component_v_label, {
                    key: 0,
                    class: "text-caption"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("Doku")
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              (!_ctx.smAndDown)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                    _createTextVNode(" Dokumentation ")
                  ], 64))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["variant", "class", "rounded", "base-color", "color", "border", "width", "size"]),
          (_ctx.softwareIntegration.payFlow)
            ? (_openBlock(), _createBlock(_component_v_btn, {
                key: 0,
                variant: _ctx.smAndDown ? 'text' : 'outlined',
                class: _normalizeClass(["text-none text-subtitle-2 mr-2 px-2", _ctx.mdAndDown ? 'ml-2 mt-2' : 'mt-6']),
                rounded: _ctx.smAndDown ? undefined : _ctx.vStyle.input.rounded || undefined,
                "base-color": _ctx.vStyle.input.baseColor || undefined,
                color: _ctx.vStyle.input.color || 'primary',
                border: _ctx.smAndDown ? undefined : 'sm opacity-50',
                density: "compact",
                width: _ctx.smAndDown ? undefined : '200',
                size: _ctx.mdAndDown ? undefined : 'large',
                "prepend-icon": "fa-solid fa-coins",
                onClick: _cache[11] || (_cache[11] = ($event: any) => (_ctx.selectTab(_ctx.HeaderTab.PayFlowOverview)))
              }, {
                default: _withCtx(() => [
                  (_ctx.smAndDown)
                    ? (_openBlock(), _createBlock(_component_v_label, {
                        key: 0,
                        class: "text-caption"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode("PayFlow")
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  (!_ctx.smAndDown)
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                        _createTextVNode(" PayFlow Übersicht ")
                      ], 64))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }, 8, ["variant", "class", "rounded", "base-color", "color", "border", "width", "size"]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_SpinnerElementHeader)
  ], 64))
}