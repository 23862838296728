<!--src/components/disposition/CustomerColumn.vue-->
<template>
  <div class="column">
    <div v-if="!isActive" class="spacer-dispo-column"></div>
    <div v-if="isActive" class="header-dispo-column">
      <div>
        <FilterMenu>
          <template #filter-menu-left-column>
            <div class="status-options">
              <v-checkbox
                density="compact"
                v-for="status in customerStates"
                :key="status"
                v-model="selectedStatuses"
                :label="status"
                :value="status"
              ></v-checkbox>
              <div class="d-flex">
                <v-text-field
                  :variant="vStyle.input.variant || undefined"
                  :rounded="vStyle.input.rounded || undefined"
                  :base-color="vStyle.input.baseColor || undefined"
                  :color="vStyle.input.color || undefined"
                  v-model="searchTerm"
                  density="compact"
                  label="Suchbegriff"
                  @keydown.enter="filterSearchterm(searchTerm)"
                >
                  <v-tooltip activator="parent" location="top"
                    >Einen oder mehrere Suchbegriffe eingeben und mit Enter
                    bestätigen</v-tooltip
                  >
                </v-text-field>
                <v-btn
                  class="mx-2"
                  density="compact"
                  variant="text"
                  icon
                  @click="loadCustomers()"
                >
                  <v-icon> fa-solid fa-arrows-rotate </v-icon>
                  <v-tooltip activator="parent" location="top"
                    >alle Firmen wieder anzeigen</v-tooltip
                  >
                </v-btn>
              </div>
            </div>
          </template>
          <template #filter-menu-right-column>
            <div class="mandants-options">
              <v-checkbox
                density="compact"
                v-for="mandant in mandants"
                :key="mandant.uuid"
                v-model="selectedMandants"
                :label="mandant.name"
                :value="mandant.uuid"
              ></v-checkbox>
            </div>
            <v-divider class="mb-10"> </v-divider>
            <v-select
              :variant="vStyle.input.variant || undefined"
              :rounded="vStyle.input.rounded || undefined"
              :base-color="vStyle.input.baseColor || undefined"
              :color="vStyle.input.color || undefined"
              v-model="filterCustomersCurrent"
              density="compact"
              :items="FilterCustomers"
              label="sortieren nach ..."
              item-title="label"
              item-value="label"
              @update:model-value="
                filterCustomers(customersAll, filterCustomersCurrent)
              "
            >
            </v-select>
          </template>
        </FilterMenu>
      </div>
      <v-range-slider
        v-model="sliderCustomerStatesValue"
        class="text-caption"
        :step="1"
        max="3"
        min="0"
        show-ticks="always"
        tick-size="10"
        color="primary"
        @update:model-value="handleSliderChange()"
      >
        <template v-slot:tick-label="{ index }">
          <v-icon
            v-if="mdAndDown"
            :key="index"
            color="primary-darken-1"
            class="mt-3"
            >{{
              customerStateIcons[index as keyof typeof customerStateIcons]
            }}</v-icon
          >
          <span v-else>{{
            customerStatesLabels[index as keyof typeof customerStatesLabels]
          }}</span>
        </template>
      </v-range-slider>
      <v-icon>{{ isDebounceActive ? "fa-regular fa-clock" : "none" }}</v-icon>
      <v-menu>
        <template v-slot:activator="{ props }">
          <v-btn icon variant="text" v-bind="props"
            ><v-icon size="large" class="filter-icon"
              >fa-solid fa-circle-plus</v-icon
            >
            <v-tooltip activator="parent" location="top left"
              >neuen Kunden anlegen</v-tooltip
            ></v-btn
          >
        </template>
        <v-card
          :variant="vStyle.card.variant || undefined"
          :rounded="vStyle.card.rounded || undefined"
          :border="vStyle.card.border || undefined"
        >
          <v-card-title class="">Kunde / Interessent</v-card-title>
          <v-card-subtitle class="mb-2"
            >aus Quelle importieren:</v-card-subtitle
          >
          <v-list dense>
            <v-list-item
              v-if="softwareIntegration.zvooveOne"
              @click="openAddCustomerModal(ModalMode.zvooveOne)"
            >
              <div>
                <img
                  src="@/assets/icon-zvoove-one.svg"
                  class="context-menu-icons"
                />
                ERP One
              </div>
            </v-list-item>
            <v-list-item
              v-if="softwareIntegration.pdHub"
              @click="openAddCustomerModal(ModalMode.pdHub)"
            >
              <div>
                <img
                  src="@/assets/icon-pd-hub.svg"
                  class="context-menu-icons"
                />
                PD-Hub
              </div>
            </v-list-item>
            <v-list-item
              v-if="softwareIntegration.indexAnzeigendaten"
              @click="openAddCustomerModal(ModalMode.indexAnzeigendaten)"
            >
              <div>
                <img
                  src="@/assets/icon-index-anzeigendaten.png"
                  class="context-menu-icons"
                />
                INDEX Anzeigendaten
              </div>
            </v-list-item>
            <v-list-item @click="openAddCustomerModal(ModalMode.googleSearch)">
              <div>
                <i class="fa-brands fa-google context-menu-icons"></i>
                Kunde anlegen
              </div>
            </v-list-item>
            <v-list-item
              class="mt-10"
              v-if="
                softwareIntegration.zvooveOne &&
                loggedInMandantUuids.length > 0 &&
                user.role <= 10
              "
              @click="getAllCustomerFromERP"
            >
              <div>
                <img
                  src="@/assets/icon-zvoove-one.svg"
                  class="context-menu-icons"
                />
                alle aus ERP One
              </div>
            </v-list-item>
            <v-list-item
              class="mt-10"
              v-if="
                softwareIntegration.pdHub &&
                loggedInMandantUuids.length > 0 &&
                user.role <= 10
              "
              @click="getAllCustomerFromERP"
            >
              <div>
                <img
                  src="@/assets/icon-pd-hub.svg"
                  class="context-menu-icons"
                />
                alle aus PD-Hub
              </div>
            </v-list-item>
          </v-list>
        </v-card>
      </v-menu>
    </div>
    <v-infinite-scroll
      :onLoad="loadMoreCustomers"
      :disabled="isLoadingCustomers"
      :distance="20"
      class="infinite-scroll-hidden-scrollbar ma-0 pa-0"
    >
      <draggable
        :list="customersOrdered"
        group="customer"
        @start="dragStart"
        @end="dragEnd"
        item-key="id"
      >
        <template #item="{ element }">
          <CustomerItem
            class="longpress"
            :customer="element"
            :interComponentMessage="interComponentMessage"
            :isActive="isActive"
            :isFullyCollapsed="minimizeAllItems"
            :lastUpdateTimeline="lastUpdateTimeline"
            :loggedInMandantUuids="loggedInMandantUuids"
            :mandants="mandants"
            :softwareIntegration="softwareIntegration"
            :user="user"
            @contextmenu.prevent="openContextMenu($event, element)"
            @longpress="openContextMenu($event, element)"
            @createInErp="createExitstingCustomerInErp"
            @insertUpdatedCustomer="insertUpdatedCustomer"
          />
        </template>
      </draggable>
      <template v-slot:loading>
        <v-icon class="spinner-icon">fas fa-spinner</v-icon>
        <v-card-subtitle>
          Lade weitere {{ iLoader.incrementBy }} Kunden ...
        </v-card-subtitle>
      </template>

      <template v-slot:empty>
        <v-alert variant="tonal" closable color="secondary">
          Alle Kunden wurden geladen!
        </v-alert>
      </template>
    </v-infinite-scroll>
    <div
      v-if="showContextMenu"
      :style="{
        top: contextMenuPosition.y + 'px',
        left: contextMenuPosition.x + 'px',
      }"
      class="context-menu"
      ref="ContextMenu"
      @click.stop
    >
      <v-list dense>
        <v-list-item @click="openEditCustomerModal()"
          ><v-icon class="text-medium-emphasis mr-1" size="xs">
            fa-solid fa-pen-to-square
          </v-icon>
          bearbeiten</v-list-item
        >
        <v-list-item @click="confirmDeleteCustomer(customerIdForDelete)">
          <v-icon class="text-medium-emphasis mr-1" size="xs">
            fa-solid fa-trash-can
          </v-icon>
          löschen
        </v-list-item>
        <v-list-item @click="toggleItemsMinimized()">
          <v-icon class="text-medium-emphasis mr-1" size="xs">
            {{
              minimizeAllItems
                ? "fa-solid fa-chevron-up"
                : "fa-solid fa-chevron-down"
            }}
          </v-icon>
          {{ minimizeAllItems ? "Karten normal" : "Karten minimieren" }}
        </v-list-item>
      </v-list>
    </div>
    <ModalAddCustomer
      :showModal="showModal"
      @update:showModal="showModal = $event"
      :results="results"
      :resultsModal="resultsModal"
      @update:resultsModal="resultsModal = $event"
      :modalTitle="modalTitle"
      :modalType="modalType"
      :addCustomerModalInput1="addCustomerModalInput1"
      :addCustomerModalInput2="addCustomerModalInput2"
      :addCustomerModalLabel1="addCustomerModalLabel1"
      :addCustomerModalLabel2="addCustomerModalLabel2"
      :modalActionButtonText="modalActionButtonText"
      @submit="handleModalSubmit"
      @selectedResult="handleSelectedResult"
      @addFromZvooveModal="openAddCustomerModal(ModalMode.zvooveOne)"
      @addFromPdHubModal="openAddCustomerModal(ModalMode.pdHub)"
    />
    <DialogEditCustomer
      :customer="customer"
      ref="modalEditCustomerComponent"
      @insertUpdatedCustomer="insertUpdatedCustomer"
    />
  </div>
</template>

<script lang="ts">
import { AllErpAdapter } from "@/adapter/all-erp.adapter";
import { AnzeigedatenService } from "@/services/api/anzeigendaten.service";
import { CompanyWebSearchService } from "@/services/api/company-web-search.service";
import { Customer, CustomerList } from "@/models/customer.model";
import { CustomerService } from "@/services/api/customer.service";
import { defineComponent, PropType } from "vue";
import { FilterCustomers } from "@/enums/board-filters.enum";
import { getEnumOptions } from "@/helper/enum.helper";
import { InterComponentMessage } from "@/enums/inter-component-messagin.enum";
import { LinkingService } from "@/services/api/linking.service";
import { Mandant } from "@/models/mandant.model";
import { mapGetters, mapMutations } from "vuex";
import { ModalMode } from "@/enums/dialog-action.enum";
import { MutationType } from "@/enums/vuex-mutationtype.enum";
import { SoftwareIntegration } from "@/models/company-config.model";
import { SpinnerService } from "@/services/spinner.service";
import { useDisplay } from "vuetify";
import { User } from "@/models/user.model";
import { UserHelperService } from "@/services/user-helper.service";
import { UserService } from "@/services/api/user.service";
import CustomerItem from "@/components/disposition/CustomerItem.vue";
import debounce from "lodash/debounce";
import DialogEditCustomer from "@/components/disposition/elements/DialogEditCustomer.vue";
import DialogService from "@/services/dialog.service";
import draggable from "vuedraggable";
import FilterMenu from "./elements/FilterMenu.vue";
import ModalAddCustomer from "@/components/disposition/elements/DialogAddCustomer.vue";
import ToastService from "@/services/toast.service";

export default defineComponent({
  name: "CustomerColumn",
  components: {
    FilterMenu,
    ModalAddCustomer,
    DialogEditCustomer,
    CustomerItem,
    draggable,
  },
  props: {
    isActive: {
      type: Boolean,
      required: true,
    },
    interComponentMessage: {
      type: Object as PropType<any>,
      required: true,
    },
    lastUpdateTimeline: {
      type: String,
      required: true,
    },
    loggedInMandantUuids: {
      type: Object as PropType<string[]>,
      required: true,
    },
    mandants: {
      type: Object as PropType<Mandant[]>,
      required: true,
    },
    softwareIntegration: {
      type: Object as PropType<SoftwareIntegration>,
      required: true,
    },
    user: {
      type: Object as PropType<User>,
      required: true,
    },
  },
  data() {
    const { mdAndDown } = useDisplay();
    return {
      mdAndDown,
      addCustomerModalInput1: "",
      addCustomerModalInput2: "",
      addCustomerModalLabel1: "",
      addCustomerModalLabel2: "",
      anzeigedatenService: AnzeigedatenService.getInstance(),
      companyWebSearchService: new CompanyWebSearchService(),
      contextMenuPosition: { x: 0, y: 0 },
      customersAll: [] as Customer[],
      customersOrdered: [] as Customer[],
      customer: {} as Customer,
      customerStatesLabels: {
        0: "Interessent",
        1: "Kunde",
        2: "ehemaliger Kunde",
        3: "erledigt",
      },
      customerStateIcons: {
        0: "fas fa-building-flag",
        1: "fas fa-building",
        2: "fas fa-building-circle-xmark",
        3: "fas fa-building-circle-check",
      },
      customerIdForDelete: "",
      customerService: new CustomerService(),
      drag: false,
      FilterCustomers: getEnumOptions(FilterCustomers),
      filterCustomersCurrent: "",
      isDebounceActive: false,
      isLoadingCustomers: false,
      iLoader: {
        candidatesCountOnInit: parseInt(
          this.$store.getters.getEnv.VUE_APP_COLUMN_ITEM_COUNT
        ),
        candidatesCount: parseInt(
          this.$store.getters.getEnv.VUE_APP_COLUMN_ITEM_COUNT
        ),
        incrementBy: parseInt(
          this.$store.getters.getEnv.VUE_APP_COLUMN_ITEM_INCREMENT
        ),
      },
      minimizeAllItems: false,
      modalActionButtonText: "aus ERP importieren",
      modalTitle: "",
      ModalMode: ModalMode,
      modalType: "",
      results: [] as Customer[],
      resultsModal: false,
      searchTerm: "",
      selectedMandants:
        this.user.config.dispatcherBoard.columnCustomer.filterMandants,
      selectedStatuses:
        this.user.config.dispatcherBoard.columnCustomer.filterStatus,
      showContextMenu: false,
      showFilters: false,
      showModal: false,
      sliderCustomerStatesValue: [1, 1],
      userService: new UserService(),
      userHelperService: UserHelperService.getInstance(),
      vStyle: this.$store.state.vStyle,
    };
  },
  computed: {
    ...mapGetters({
      customerStates: "customerStates",
      zvooveCustomers: "zvooveCustomers",
    }),
  },
  created() {
    this.loadCustomers();
  },
  mounted() {
    document.addEventListener("click", this.handleClickOutside, true);
    this.setSelectedMandantsFromLoggedInMandants();
  },
  beforeUnmount() {
    document.removeEventListener("click", this.handleClickOutside, true);
  },
  watch: {
    selectedStatuses: {
      handler: debounce(function (this: any, newValue, oldValue) {
        if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
          this.loadCustomers();
          this.$store
            .dispatch("updateDispatcherBoardFilter", {
              columnName: "columnCustomer",
              property: "filterStatus",
              value: newValue,
            })
            .then(() => {
              this.userService.updateUserConfig(
                this.$store.state.company.loggedInUser.config
              );
            });
        }
      }, 1500),
      deep: true,
    },
    selectedMandants: {
      handler: debounce(function (this: any, newValue, oldValue) {
        if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
          this.loadCustomers();
          this.$store
            .dispatch("updateDispatcherBoardFilter", {
              columnName: "columnCustomer",
              property: "filterMandants",
              value: newValue,
            })
            .then(() => {
              this.userService.updateUserConfig(
                this.$store.state.company.loggedInUser.config
              );
            });
        }
      }, 1500),
      deep: true,
    },
    interComponentMessage: {
      handler(newVal) {
        switch (newVal.message) {
          case InterComponentMessage.filterCustomers:
            this.handleFilterCustomers(newVal.payload.customerNumbers);
            break;

          case InterComponentMessage.openCustomer:
            this.handleOpenCustomer(newVal.payload.customerName);
            break;

          case InterComponentMessage.addCustomerFromZvoove:
            this.handleAddCustomerFromZvoove(newVal.payload.customerNumber);
            break;

          case InterComponentMessage.addCustomerFromIndex:
            this.handleAddCustomerFromIndex(
              newVal.payload.name,
              newVal.payload.postalCode
            );
            break;
          case InterComponentMessage.matchMeFromGlobalSearch:
            this.filterSearchterm(newVal.payload.searchTerm);
            break;
          default:
            break;
        }
      },
      deep: true,
    },
  },
  methods: {
    ...mapMutations({
      clearICM: MutationType.clearICM,
      setPostcodeList: MutationType.setPostcodeCustomers,
    }),
    closeModal() {
      this.showModal = false;
    },
    closeContextMenu() {
      this.showContextMenu = false;
    },
    async confirmDeleteCustomer(id: string) {
      if (this.customerIdForDelete) {
        const confirmed = await DialogService.confirm(
          "Möchten Sie diesen Kunden wirklich löschen?",
          "Abbrechen",
          "Löschen"
        );

        if (confirmed) {
          this.deleteCustomer(id);
        }
      }
    },

    async createCustomerInErp(selectedCustomer: Customer) {
      const updatedCustomer = await AllErpAdapter.addCustomerInErp(
        selectedCustomer,
        this.user,
        true
      );

      if (updatedCustomer) {
        const apiService = new CustomerService();
        const responseEdit = await apiService.addCustomer(updatedCustomer);

        if (responseEdit.customerNumber) {
          ToastService.showSuccess(
            `${responseEdit.generalData?.name} erfolgreich im ERP One mit der Nummer ${responseEdit.customerNumber} angelegt ...`
          );
        }
        return updatedCustomer;
      }

      return null;
    },
    async createExitstingCustomerInErp(customer: Customer) {
      const updatedCustomer = await AllErpAdapter.addCustomerInErp(
        customer,
        this.user,
        false
      );

      if (updatedCustomer) {
        const apiService = new CustomerService();
        const responseEdit = await apiService.editCustomer(updatedCustomer);

        if (responseEdit.customerNumber) {
          ToastService.showSuccess(
            `${responseEdit.generalData?.name} erfolgreich im ERP One mit der Nummer ${responseEdit.customerNumber} angelegt ...`
          );
        }
        return updatedCustomer;
      }

      return null;
    },
    async deleteCustomer(id: string) {
      const customerService = new CustomerService();
      const linkingService = new LinkingService();
      try {
        const responseLinking = await linkingService.deleteLinkingsByTypeAndId(
          "customer",
          id
        );
        if (responseLinking.error) {
          ToastService.showError(
            "Fehler beim Löschen des Kunden: Verlinkungen konnten nicht entfernt werden!" +
              responseLinking.error
          );
          return;
        }
        const response = await customerService.deleteCustomer(id);
        if (response.error) {
          ToastService.showError(
            "Fehler beim Löschen des Kunden: " + response.error
          );
        } else {
          this.loadCustomers();
          this.customerIdForDelete = "";
        }
      } catch (error) {
        ToastService.showError("Fehler beim Löschen des Kunden: " + error);
      }
    },
    dragStart() {
      this.drag = true;
    },
    dragEnd() {
      this.drag = false;
    },
    extractAndSetPostcodeList() {
      const customers = this.customersOrdered;
      if (customers.length > 0) {
        const postcodes = customers
          .map((customer) => customer.addressAndCommunication?.postalCode)
          .filter(
            (postalCode) => postalCode !== undefined && postalCode !== ""
          );

        this.setPostcodeList(postcodes);
      }
    },
    filterCustomers(customerList: Customer[], filterOption?: string) {
      if (!filterOption) {
        customerList = customerList.sort((a, b) =>
          (a.customerNumber || "").localeCompare(b.customerNumber || "")
        );
      }

      switch (filterOption) {
        case FilterCustomers.HasJobAds:
          customerList = customerList.sort(
            (a, b) => b.jobAds.length - a.jobAds.length
          );
          this.customersOrdered = customerList;
          this.iLoaderInitCards();
          break;
        case FilterCustomers.HasAssignments:
          customerList = customerList.sort((a, b) => {
            const aAssignments = a.assignments ? a.assignments.length : 0;
            const bAssignments = b.assignments ? b.assignments.length : 0;
            return bAssignments - aAssignments;
          });
          this.customersOrdered = customerList;
          this.iLoaderInitCards();
          break;
        case FilterCustomers.CustomerNumber:
          customerList = customerList.sort((a, b) =>
            (a.customerNumber || "").localeCompare(b.customerNumber || "")
          );
          this.customersOrdered = customerList;
          this.iLoaderInitCards();
          break;
        case FilterCustomers.LastEdited:
          customerList = customerList.sort((a, b) => {
            const dateA = new Date(a.lastEdit?.data || 0).getTime();
            const dateB = new Date(b.lastEdit?.data || 0).getTime();
            return dateB - dateA; // Sortiere nach letzter Bearbeitung
          });
          this.customersOrdered = customerList;
          this.iLoaderInitCards();
          break;
        case FilterCustomers.Postcode:
          customerList = customerList.sort((a, b) =>
            (a.addressAndCommunication.postalCode || "").localeCompare(
              b.addressAndCommunication.postalCode || ""
            )
          );
          this.customersOrdered = customerList;
          this.iLoaderInitCards();
          break;
        case FilterCustomers.Name:
          customerList = customerList.sort((a, b) =>
            (a.generalData.name || "").localeCompare(b.generalData.name || "")
          );
          this.customersOrdered = customerList;
          this.iLoaderInitCards();
          break;

        default:
          break;
      }

      this.customersOrdered = customerList.slice(
        0,
        this.iLoader.candidatesCount
      );
      return customerList;
    },
    async filterSearchterm(searchTerm: string) {
      if (searchTerm === "") {
        this.loadCustomers();
        return;
      }
      const terms = searchTerm.toLowerCase().split(" ");

      const filteredCustomers = this.customersAll.filter((customer) => {
        const {
          customerNumber,
          tags,
          generalData: { name, name2, name3, searchTerm: generalSearchTerm },
          addressAndCommunication: {
            street,
            postalCode,
            city,
            phone1,
            phone2,
            email,
            website,
          },
          additionalInfo: { indexCompanyId },
          contacts,
          jobAds,
          assignments,
        } = customer;

        const contactMatches =
          contacts &&
          contacts.some((contact) =>
            terms.every(
              (term) =>
                (contact.firstName &&
                  contact.firstName.toLowerCase().includes(term)) ||
                (contact.lastName &&
                  contact.lastName.toLowerCase().includes(term)) ||
                (contact.phone && contact.phone.toLowerCase().includes(term)) ||
                (contact.email && contact.email.toLowerCase().includes(term))
            )
          );

        const jobAdMatches =
          jobAds &&
          jobAds.some((jobAd) =>
            terms.every(
              (term) =>
                (Array.isArray(jobAd.jobCategory) &&
                  jobAd.jobCategory.some((category: string) =>
                    category.toLowerCase().includes(term)
                  )) ||
                (jobAd.jobTitle &&
                  jobAd.jobTitle.toLowerCase().includes(term)) ||
                (jobAd.careerLevel &&
                  jobAd.careerLevel.toLowerCase().includes(term)) ||
                (jobAd.adContactPerson?.phone &&
                  jobAd.adContactPerson?.phone.toLowerCase().includes(term)) ||
                (jobAd.jobAdText &&
                  jobAd.jobAdText.toLowerCase().includes(term))
            )
          );

        const assignmentMatches =
          assignments &&
          assignments.some((assignment) =>
            terms.every((term) => {
              const fullName = `${assignment.firstName} ${assignment.lastName}`;
              return (
                this.lowerCaseIncludes(fullName, term) ||
                this.lowerCaseIncludes(assignment.jobTitle, term)
              );
            })
          );

        const matches = terms.every(
          (term) =>
            this.lowerCaseIncludes(customerNumber, term) ||
            (tags && tags.some((tag) => this.lowerCaseIncludes(tag, term))) ||
            this.lowerCaseIncludes(name, term) ||
            this.lowerCaseIncludes(name2, term) ||
            this.lowerCaseIncludes(name3, term) ||
            this.lowerCaseIncludes(generalSearchTerm, term) ||
            this.lowerCaseIncludes(street, term) ||
            this.lowerCaseIncludes(postalCode, term) ||
            this.lowerCaseIncludes(city, term) ||
            this.lowerCaseIncludes(phone1, term) ||
            this.lowerCaseIncludes(phone2, term) ||
            this.lowerCaseIncludes(email, term) ||
            this.lowerCaseIncludes(website, term) ||
            this.lowerCaseIncludes(indexCompanyId, term)
        );

        return matches || contactMatches || jobAdMatches || assignmentMatches;
      });

      this.customersOrdered = filteredCustomers;
    },
    lowerCaseIncludes(name: string | undefined, term: string) {
      return name && name.toLowerCase().includes(term);
    },
    async getAllCustomerFromERP() {
      const response = await AllErpAdapter.getAllCustomersFromErp();

      if (response) {
        this.initAllCustomerData(response);
      }
    },
    handleClickOutside(event: MouseEvent) {
      const ContextMenu = this.$refs.ContextMenu as HTMLElement;

      if (
        ContextMenu &&
        !ContextMenu.contains(event.target as Node) &&
        this.showContextMenu
      ) {
        this.closeContextMenu();
        this.customerIdForDelete = "";
      }
    },
    handleFilterCustomers(customerNumbers: string[]) {
      this.loadCustomers("", customerNumbers);
      this.clearICM;
    },
    handleOpenCustomer(customerName: string) {
      const customerNumber =
        this.$store.getters.getCustomerNumberByNameFromCustomerList(
          customerName
        );
      this.loadCustomers(undefined, [customerNumber]);
      this.clearICM;
    },
    async handleAddCustomerFromZvoove(customerNumber: string) {
      this.modalType = ModalMode.zvooveOne;
      this.addCustomerModalInput1 = customerNumber;
      this.submitModal();
      this.clearICM;
    },
    async handleAddCustomerFromPdHub(customerNumber: string) {
      this.modalType = ModalMode.pdHub;
      this.addCustomerModalInput1 = customerNumber;
      this.submitModal();
      this.clearICM;
    },
    handleAddCustomerFromIndex(name: string, postalCode: string) {
      this.modalType = ModalMode.indexAnzeigendaten;
      this.addCustomerModalInput1 = postalCode;
      this.addCustomerModalInput2 = name;
      this.submitModal();
      this.clearICM;
    },
    async handleModalSubmit({
      input1,
      input2,
    }: {
      input1: string;
      input2: string;
    }) {
      this.addCustomerModalInput1 = input1;
      this.addCustomerModalInput2 = input2;
      await this.submitModal();
    },
    handleSelectedResult(selectedResult: Customer) {
      SpinnerService.showSpinner();
      this.selectResult(selectedResult).then(() =>
        SpinnerService.removeSpinner()
      );
    },
    async initAllCustomerData(customersFromErp?: Customer[]) {
      const confirmed = await DialogService.confirm(
        "Wirklich alle lokalen Kundendaten mit den ERP Daten überschreiben?",
        "Abbrechen",
        "alles Überschreiben"
      );

      if (!confirmed) {
        return;
      }
      let customerAll = this.zvooveCustomers;
      if (customersFromErp) customerAll = customersFromErp;

      try {
        const response = await this.customerService.addCustomers(customerAll);
        const { added, updated, skipped } = response;

        let successSummary = `${added} Kunde(n) erfolgreich hinzugefügt.`;
        if (updated > 0) {
          successSummary += `\n${updated} Kunde(n) erfolgreich aktualisiert.`;
        }
        if (skipped > 0) {
          successSummary += `\n${skipped} Kunde(n) wurden übersprungen, da sie bereits vorhanden waren.`;
        }
        ToastService.showReminder(successSummary);
      } catch (error) {
        ToastService.showError(
          "Fehler beim Hinzufügen oder Aktualisieren der Kunden."
        );
      }

      this.loadCustomers();
    },
    insertUpdatedCustomer(updatedCustomer: Customer) {
      const index = this.customersOrdered.findIndex(
        (customer) => customer._id === updatedCustomer._id
      );

      if (index !== -1) {
        this.customersOrdered[index] = updatedCustomer;
      }
    },
    async iLoaderInitCards() {
      if (this.customersAll.length > 0) {
        this.customersOrdered = this.customersAll.slice(
          0,
          this.iLoader.candidatesCountOnInit
        );
      }
    },
    setSelectedMandantsFromLoggedInMandants() {
      if (this.mandants && this.loggedInMandantUuids) {
        if (
          this.user.config.dispatcherBoard.columnCustomer.filterMandants
            .length > 0
        ) {
          //Do not set if other filters are setted
          return;
        }
        this.selectedMandants = this.mandants
          .filter((mandant: Mandant) =>
            this.loggedInMandantUuids.includes(mandant.uuid)
          )
          .map((mandant: Mandant) => mandant.uuid);
      }
    },
    async loadCustomers(id?: string, customerNumbers?: string[]) {
      if (this.isLoadingCustomers) return;
      try {
        SpinnerService.showSpinner();
        this.isLoadingCustomers = true;
        if (customerNumbers) {
          this.customersAll = await this.customerService.getByCustomerNumbers(
            customerNumbers
          );
          return;
        }
        if (id) {
          this.customersAll = [await this.customerService.getById(id)];
          return;
        }
        if (
          this.selectedMandants.length === 0 &&
          this.selectedStatuses.length === 0
        ) {
          this.customersAll = await this.customerService.getAll();
          return;
        }
        this.customersAll = await this.customerService.getAllFiltered(
          this.selectedMandants,
          this.selectedStatuses
        );
      } catch (error) {
        console.error("Error loading customers: ", error);
      } finally {
        this.customersOrdered = this.customersAll;
        this.extractAndSetPostcodeList();
        SpinnerService.removeSpinner();
        this.isLoadingCustomers = false;
      }
    },
    handleSliderChange() {
      this.isDebounceActive = true;
      this.updateselectedStatuses(this.sliderCustomerStatesValue);
    },
    async loadMoreCustomers({ done }: any) {
      setTimeout(() => {
        if (this.iLoader.candidatesCount < this.customersAll.length) {
          this.iLoader.candidatesCount += this.iLoader.incrementBy;
          this.customersOrdered = this.customersAll.slice(
            0,
            this.iLoader.candidatesCount
          );
        } else if (
          this.customersOrdered.length === this.customersAll.length &&
          this.customersAll.length > 0
        ) {
          done("empty");
          return;
        }
        done("ok");
      }, 100);
    },
    updateselectedStatuses: debounce(function (this: any, range: number[]) {
      this.selectedStatuses = [];
      for (let i = range[0]; i <= range[1]; i++) {
        switch (i) {
          case 0:
            this.selectedStatuses.push(this.customerStates[1]);
            break;
          case 1:
            this.selectedStatuses.push(this.customerStates[2]);
            break;
          case 2:
            this.selectedStatuses.push(this.customerStates[3]);
            break;
          case 3:
            this.selectedStatuses.push(this.customerStates[6]);
            break;
          default:
            break;
        }
      }
      this.isDebounceActive = false;
    }, 1500),
    openContextMenu(event: MouseEvent, customer: Customer) {
      event.preventDefault();
      const customerId = customer._id;
      this.customer = customer;
      if (customerId) {
        this.customerIdForDelete = customerId;
      }
      this.showContextMenu = true;
      this.contextMenuPosition = { x: event.clientX, y: event.clientY };
    },
    openEditCustomerModal() {
      if (this.$refs.modalEditCustomerComponent) {
        (this.$refs.modalEditCustomerComponent as any).openModal();
      }
    },
    openAddCustomerModal(context: string) {
      switch (context) {
        case ModalMode.zvooveOne:
          this.modalTitle = "Aus zvoove One importieren";
          this.modalType = ModalMode.zvooveOne;
          this.addCustomerModalLabel1 = "zvoove Kundennummer";
          this.addCustomerModalInput1 = "";
          this.addCustomerModalLabel2 = "";
          this.modalActionButtonText = "in zvoove One suchen";
          break;
        case ModalMode.pdHub:
          this.modalTitle = "Aus PD-Hub importieren";
          this.modalType = ModalMode.pdHub;
          this.addCustomerModalLabel1 = "Kundennummer";
          this.addCustomerModalInput1 = "";
          this.addCustomerModalLabel2 = "";
          this.modalActionButtonText = "in PD-Hub suchen";
          break;
        case ModalMode.indexAnzeigendaten:
          this.modalTitle = "Aus INDEX Anzeigendaten importieren";
          this.modalType = ModalMode.indexAnzeigendaten;
          this.addCustomerModalLabel1 = "Postleitzahl";
          this.addCustomerModalInput1 = "";
          this.addCustomerModalLabel2 = "Firmenname / Suchbegriff";
          this.addCustomerModalInput2 = "";
          this.modalActionButtonText = "Importieren";
          break;
        case ModalMode.googleSearch:
          this.modalTitle = "Internet Suche nach dem Kunden";
          this.modalType = ModalMode.googleSearch;
          this.addCustomerModalLabel1 = "Suchbegriff";
          this.addCustomerModalInput1 = "";
          this.addCustomerModalLabel2 = "";
          this.modalActionButtonText = "Suchen";
          break;
      }
      this.showModal = true;
    },
    async selectResult(selectedCustomer: Customer) {
      if (selectedCustomer.mandants.length === 0) {
        let mandant = this.$store.state.company.mandants[0].uuid;
        const firstLoggedInMandant =
          this.$store.state.company.loggedInUser.config.loggedInMandants[0];
        if (firstLoggedInMandant.length > 0) mandant = firstLoggedInMandant;
        selectedCustomer.mandants = [mandant];
      }
      try {
        if (
          isNaN(Number(selectedCustomer.customerNumber)) &&
          (this.softwareIntegration.zvooveOne || this.softwareIntegration.pdHub)
        ) {
          const erpCustomer = await this.createCustomerInErp(selectedCustomer);
          if (erpCustomer) {
            await this.customerService.addCustomer(erpCustomer);

            const customerReduced = {
              customerNumber: erpCustomer.customerNumber,
              name: erpCustomer.generalData.name,
              address: `${erpCustomer.addressAndCommunication.street} ${erpCustomer.addressAndCommunication.postalCode} ${erpCustomer.addressAndCommunication.city}`,
            } as CustomerList;
            this.$store.dispatch(
              "addCustomerReducedToCustomerList",
              customerReduced
            );
          } else {
            await this.customerService.addCustomer(selectedCustomer);
          }
        } else {
          await this.customerService.addCustomer(selectedCustomer);
        }
        this.loadCustomers();
        this.resultsModal = false;
      } catch (error) {
        console.error("Fehler beim Speichern des ausgewählten Kunden:", error);
      }
    },
    async submitModal() {
      this.showModal = false;

      try {
        SpinnerService.showSpinner();
        let fetchedCustomers = [] as Customer[] | null;

        if (this.modalType === ModalMode.indexAnzeigendaten) {
          fetchedCustomers =
            await this.anzeigedatenService.getCustomerFromIndex(
              this.addCustomerModalInput1,
              this.addCustomerModalInput2
            );
        } else if (this.modalType === ModalMode.googleSearch) {
          fetchedCustomers =
            await this.companyWebSearchService.companyWebSearch(
              this.addCustomerModalInput1
            );
        } else {
          fetchedCustomers = await AllErpAdapter.getCustomerByNumber(
            this.addCustomerModalInput1,
            this.modalType
          );
        }

        if (fetchedCustomers && fetchedCustomers.length > 0) {
          this.results = fetchedCustomers;
          this.resultsModal = true;
        }
      } catch (error) {
        console.error("Fehler beim Abrufen der Daten: ", error);
        DialogService.alert("Fehler beim Abrufen der Daten.");
      } finally {
        SpinnerService.removeSpinner();
      }
    },
    toggleItemsMinimized() {
      this.minimizeAllItems = !this.minimizeAllItems;
      this.closeContextMenu();
    },
  },
});
</script>
<style scoped>
.rotated-labels .v-slider__tick-label {
  transform: rotate(-45deg);
  transform-origin: center;
}

.context-menu {
  position: fixed;
  z-index: 1000;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  min-width: 10rem;
}

.context-menu .v-list-item:not(:last-child) {
  border-bottom: 0.1rem solid var(--table-hover);
}

.context-menu .v-list-item--active {
  background-color: var(--table-hover);
}

.context-menu-icons {
  width: 1rem;
  height: 1rem;
  margin-right: 0.5rem;
}
</style>
