<!--src/components/disposition/elements/DialogAddCustomer.vue-->
<!-- eslint-disable vue/no-mutating-props -->
<template>
  <div>
    <v-dialog
      v-model="showModal"
      persistent
      :max-width="smAndDown ? 'var(--d-w-sd)' : 'var(--d-w-s)'"
    >
      <v-card>
        <v-card-title>{{ modalTitle }}</v-card-title>
        <v-card-text
          :class="{
            'is-expanded': filteredResults.length > 0,
            'is-contracted': filteredResults.length === 0,
          }"
        >
          <v-text-field
            :variant="vStyle.input.variant || undefined"
            :rounded="vStyle.input.rounded || undefined"
            :base-color="vStyle.input.baseColor || undefined"
            :color="vStyle.input.color || undefined"
            v-if="addCustomerModalLabel1"
            v-model="localInput1"
            :label="addCustomerModalLabel1"
          ></v-text-field>
          <v-text-field
            :variant="vStyle.input.variant || undefined"
            :rounded="vStyle.input.rounded || undefined"
            :base-color="vStyle.input.baseColor || undefined"
            :color="vStyle.input.color || undefined"
            v-if="addCustomerModalLabel2"
            v-model="localInput2"
            :label="addCustomerModalLabel2"
          ></v-text-field>
          <div v-if="showSearchResultsFromErp">
            <v-label v-if="filteredResults.length > 0"
              >In Zvoove gefunden:</v-label
            >
            <v-list dense class="scrollable-list">
              <v-list-item
                v-for="(result, index) in filteredResults"
                :key="index"
                class="clickable"
                @click="displayCustomerNumber(result.customerNumber)"
              >
                <div>
                  <v-list-item-title>{{ result.name }}</v-list-item-title>
                  <v-list-item-subtitle>{{
                    result.address
                  }}</v-list-item-subtitle>
                </div>
              </v-list-item>
            </v-list>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn color="abort" @click="closeModal">Abbrechen</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="success" @click="submitModal">{{
            modalActionButtonText
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="resultsModal"
      persistent
      :max-width="smAndDown ? 'var(--d-w-sd)' : 'var(--d-w-m)'"
    >
      <v-card>
        <v-card-title>Ergebnisse auswählen</v-card-title>
        <v-card-text>
          <v-list>
            <v-list-item
              v-for="(result, index) in results"
              :key="index"
              @click="selectResult(result)"
            >
              <div>
                <v-list-item-title>{{
                  result.generalData.name
                }}</v-list-item-title>
                <v-list-item-subtitle>
                  {{ result.addressAndCommunication.street }},
                  {{ result.addressAndCommunication.postalCode }},
                  {{ result.addressAndCommunication.city }}
                </v-list-item-subtitle>
              </div>
            </v-list-item>
          </v-list>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="abort" @click="closeResultsModal">Schließen</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script lang="ts">
import { Customer, CustomerList } from "@/models/customer.model";
import { defineComponent, PropType } from "vue";
import { mapGetters } from "vuex";
import { ModalMode } from "@/enums/dialog-action.enum";
import DialogService from "@/services/dialog.service";
import { useDisplay } from "vuetify";

export default defineComponent({
  name: "ModalAddCustomer",
  props: {
    showModal: Boolean,
    showSearchResultsFromErp: {
      type: Boolean,
      default: true,
    },
    resultsModal: Boolean,
    modalTitle: String,
    modalType: String,
    addCustomerModalInput1: String,
    addCustomerModalLabel1: String,
    addCustomerModalInput2: String,
    addCustomerModalLabel2: String,
    modalActionButtonText: String,
    results: Array as PropType<Customer[]>,
  },
  data() {
    const { smAndDown } = useDisplay();
    return {
      smAndDown,
      localInput1: this.addCustomerModalInput1,
      localInput2: this.addCustomerModalInput2,
      filteredResults: [] as CustomerList[],
      vStyle: this.$store.state.vStyle,
    };
  },
  computed: {
    ...mapGetters(["softwareIntegration", "customerList"]),
    listHeightClass() {
      return {
        "is-expanded": this.filteredResults.length > 0,
      };
    },
  },
  watch: {
    localInput1(newValue) {
      this.filterZvooveCustomerList(newValue);
    },
    localInput2(newValue) {
      this.filterZvooveCustomerList(newValue);
    },
  },
  methods: {
    filterZvooveCustomerList(searchterm: string) {
      if (!this.showSearchResultsFromErp) return;
      if (
        this.softwareIntegration.zvooveOne ||
        this.softwareIntegration.pdHub
      ) {
        if (
          this.customerList.length > 0 &&
          searchterm &&
          searchterm.length >= 3
        ) {
          const filteredCustomers = this.customerList.filter((customer: any) =>
            customer.name.toLowerCase().includes(searchterm.toLowerCase())
          );
          this.filteredResults = filteredCustomers;
        } else {
          this.filteredResults = [];
        }
      }
    },
    displayCustomerNumber(customerNumber: string) {
      let integrationType;
      let modalTypeCondition;
      let modalTypeText;
      let emitEvent;

      if (this.softwareIntegration.zvooveOne) {
        integrationType = "zvooveOne";
        modalTypeCondition = ModalMode.zvooveOne;
        modalTypeText = `Kundennummer ${customerNumber} übernehmen?`;
        emitEvent = "addFromZvooveModal";
      } else if (this.softwareIntegration.pdHub) {
        integrationType = "pdHub";
        modalTypeCondition = ModalMode.pdHub;
        modalTypeText = `Kundennummer ${customerNumber} übernehmen?`;
        emitEvent = "addFromPdHubModal";
      }

      switch (this.modalType) {
        case modalTypeCondition: {
          this.confirmAction(
            modalTypeText ?? "",
            customerNumber,
            true,
            emitEvent
          );
          break;
        }
        default: {
          const defaultText =
            integrationType === "zvooveOne"
              ? "Aus ERP übernehmen?"
              : "Aus PD-Hub übernehmen?";
          this.confirmAction(defaultText, customerNumber, false, emitEvent);
          break;
        }
      }
    },

    confirmAction(
      message: string,
      customerNumber: string,
      isSubmit: boolean,
      emitEvent: any
    ) {
      DialogService.confirm(message, "Abbruch", "Ja").then((confirmed) => {
        if (confirmed) {
          this.localInput1 = customerNumber;
          if (isSubmit) {
            this.$emit("submit", {
              input1: this.localInput1,
              input2: this.localInput2,
            });
          } else {
            this.$emit(emitEvent);
            this.$emit("submit", {
              input1: this.localInput1,
              input2: this.localInput2,
            });
          }
          this.closeModal();
        }
      });
    },
    closeModal() {
      this.$emit("update:showModal", false);
    },
    closeResultsModal() {
      this.$emit("update:resultsModal", false);
    },
    submitModal() {
      this.$emit("submit", {
        input1: this.localInput1,
        input2: this.localInput2,
      });
      this.closeModal();
    },
    selectResult(result: Customer) {
      this.$emit("selectedResult", result);
      this.$emit("update:resultsModal", false);
    },
  },
});
</script>

<style scoped>
.scrollable-list {
  transition: max-height 0.5s ease-in-out;
  overflow-y: auto;
  max-height: 0;
}

.v-card-text.is-expanded .scrollable-list {
  max-height: 15rem;
}

.v-card-text.is-expanded {
  max-height: 30rem;
}

.v-card-text.is-contracted {
  max-height: 10rem;
}
</style>
: string | undefined: string | undefined: boolean: string | undefined
