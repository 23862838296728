import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, mergeProps as _mergeProps, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-bd236d7e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "d-flex" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AdminMenu = _resolveComponent("AdminMenu")!
  const _component_PdMasterHeader = _resolveComponent("PdMasterHeader")!
  const _component_v_img = _resolveComponent("v-img")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_card_subtitle = _resolveComponent("v-card-subtitle")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_list_item_title = _resolveComponent("v-list-item-title")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_menu = _resolveComponent("v-menu")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_AdminMenu),
    (_ctx.layout === _ctx.AppLayout.pdmaster)
      ? (_openBlock(), _createBlock(_component_PdMasterHeader, { key: 0 }))
      : _createCommentVNode("", true),
    _createVNode(_component_v_container, { fluid: true }, {
      default: _withCtx(() => [
        _createVNode(_component_v_row, null, {
          default: _withCtx(() => [
            _createVNode(_component_v_col, {
              cols: "12",
              md: "4"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_img, {
                  class: "mb-2",
                  width: 100,
                  "aspect-ratio": "1/1",
                  cover: "",
                  src: _ctx.assetsSettings
                }, null, 8, ["src"]),
                _createVNode(_component_v_card, {
                  variant: _ctx.vStyle.card.variant || undefined,
                  rounded: _ctx.vStyle.card.rounded || undefined,
                  border: _ctx.vStyle.card.border || undefined
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_card_title, null, {
                      default: _withCtx(() => [
                        _createTextVNode("Recurion.ai ist eine fortschrittliche KI")
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_card_subtitle, null, {
                      default: _withCtx(() => [
                        _createTextVNode(" die die Ihr Unternehmen revolutioniert! ")
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_card_text, null, {
                      default: _withCtx(() => [
                        _createTextVNode(" Sie übernimmt die Erstellung Nachrichten die speziell auf Ihr Unternehmen eingestellt sind, betreut somit Kandidaten, Kunden und Zeitarbeiter, generiert Angebote und Mitarbeiterprofile, schreibt Stellen aus und rekrutiert damit passende Kandidaten. ")
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_card_text, null, {
                      default: _withCtx(() => [
                        _createTextVNode(" Als digitaler Vertriebsassistent entlastet Recurion Vertriebsdisponenten von administrativen Aufgaben, sodass diese sich auf die Kommunikation mit Kunden und Kandidaten konzentrieren können. Dadurch werden Effizienz und Qualität in der erheblich gesteigert. ")
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_card_subtitle)
                  ]),
                  _: 1
                }, 8, ["variant", "rounded", "border"]),
                (_ctx.getUserRole() === 0)
                  ? (_openBlock(), _createBlock(_component_v_card, {
                      key: 0,
                      variant: _ctx.vStyle.card.variant || undefined,
                      rounded: _ctx.vStyle.card.rounded || undefined,
                      border: _ctx.vStyle.card.border || undefined,
                      class: "mt-5"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_card_title, null, {
                          default: _withCtx(() => [
                            _createTextVNode("uberAdmin ONLY")
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_v_card_text, null, {
                          default: _withCtx(() => [
                            (_ctx.companyConfigModel.apiKeys)
                              ? (_openBlock(), _createBlock(_component_v_text_field, {
                                  key: 0,
                                  variant: _ctx.vStyle.input.variant || undefined,
                                  rounded: _ctx.vStyle.input.rounded || undefined,
                                  "base-color": _ctx.vStyle.input.baseColor || undefined,
                                  color: _ctx.vStyle.input.color || undefined,
                                  modelValue: _ctx.companyConfigModel.apiKeys.openAi,
                                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.companyConfigModel.apiKeys.openAi) = $event)),
                                  label: "api key for openAi",
                                  class: "my-2"
                                }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue"]))
                              : _createCommentVNode("", true),
                            (_ctx.companyConfigModel.apiKeys)
                              ? (_openBlock(), _createBlock(_component_v_text_field, {
                                  key: 1,
                                  variant: _ctx.vStyle.input.variant || undefined,
                                  rounded: _ctx.vStyle.input.rounded || undefined,
                                  "base-color": _ctx.vStyle.input.baseColor || undefined,
                                  color: _ctx.vStyle.input.color || undefined,
                                  modelValue: _ctx.companyConfigModel.apiKeys.mistralAi,
                                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.companyConfigModel.apiKeys.mistralAi) = $event)),
                                  label: "api key for mistralAi",
                                  class: "my-2"
                                }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue"]))
                              : _createCommentVNode("", true),
                            _createVNode(_component_v_btn, {
                              variant: _ctx.vStyle.btn.variant || undefined,
                              rounded: _ctx.vStyle.btn.rounded || undefined,
                              border: _ctx.vStyle.btn.border || undefined,
                              onClick: _ctx.updateCompanyConfig,
                              color: "success"
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode("SET")
                              ]),
                              _: 1
                            }, 8, ["variant", "rounded", "border", "onClick"])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }, 8, ["variant", "rounded", "border"]))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }),
            _createVNode(_component_v_col, {
              cols: "12",
              md: "8"
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_1, [
                  _createVNode(_component_v_spacer),
                  _createVNode(_component_v_menu, null, {
                    activator: _withCtx(({ props }) => [
                      _createVNode(_component_v_btn, _mergeProps({
                        variant: _ctx.vStyle.btn.variant || undefined,
                        rounded: _ctx.vStyle.btn.rounded || undefined,
                        border: _ctx.vStyle.btn.border || undefined
                      }, props), {
                        default: _withCtx(() => [
                          _createVNode(_component_v_icon, null, {
                            default: _withCtx(() => [
                              _createTextVNode("fa-solid fa-bars")
                            ]),
                            _: 1
                          }),
                          _createTextVNode("Einstellungen")
                        ]),
                        _: 2
                      }, 1040, ["variant", "rounded", "border"])
                    ]),
                    default: _withCtx(() => [
                      _createVNode(_component_v_list, null, {
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredItems, (item, index) => {
                            return (_openBlock(), _createBlock(_component_v_list_item, {
                              key: index,
                              onClick: ($event: any) => (_ctx.setActiveComponent(item.component))
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_list_item_title, null, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(item.title), 1)
                                  ]),
                                  _: 2
                                }, 1024)
                              ]),
                              _: 2
                            }, 1032, ["onClick"]))
                          }), 128))
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.activeConfigComponent), {
                  modelValue: _ctx.aiData,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.aiData) = $event)),
                  onSave: _ctx.updateCompanyConfig
                }, null, 40, ["modelValue", "onSave"]))
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ], 64))
}