// 100% STORE-FREE certificate :) Bio :D Öko Vegan Halal BCACUJKDJj+++--0001234567890ß?FU Koscher
import axios from "axios";
import store from "../../store/store";
import moment from "moment";
import { Candidate } from "@/models/candidate.model";
import { CompanyConfigService } from "./company-config.service";
import { ApiConfig } from "@/models/company-config.model";
import DialogService from "@/services/dialog.service";
import { Customer, CustomerList } from "@/models/customer.model";
import { EmployeeService } from "./employee.service";
import { CustomerService } from "./customer.service";
import {
  L1CustomerTimelineEntry,
  L1EmployeeTimelineEntry,
} from "@/models/timeline.model";
import { PdHubAdapter } from "@/adapter/pdhub.adapter";
import {
  getCustomerStates,
  CustomerStates,
} from "@/enums/customer-states.enum";
import ToastService from "../toast.service";
import { Employee, EmployeeList } from "@/models/employee.model";
import { Assignment } from "@/models/assignment.model";
import { ErpFetchOption } from "@/enums/init-options.enum";

export class PdHubService {
  companyConfigService = CompanyConfigService.getInstance();
  apiKeys = {} as ApiConfig;

  private static instance: PdHubService;

  public static getInstance(): PdHubService {
    if (!PdHubService.instance) {
      PdHubService.instance = new PdHubService();
    }
    return PdHubService.instance;
  }

  async getCompanyConfig() {
    if (!(this.apiKeys && this.apiKeys.pdHubBaseUrl)) {
      this.apiKeys = (await this.companyConfigService.getConfig()).apiKeys;
    }
  }

  // Setting
  async pdHubBaseURL() {
    await this.getCompanyConfig();
    return this.apiKeys.pdHubBaseUrl;
  }

  async pdHubCustomerId() {
    await this.getCompanyConfig();
    const apiKeys = this.apiKeys;
    return apiKeys.pdHubCustomerId;
  }

  async pdHubL1Mandant() {
    await this.getCompanyConfig();
    const apiKeys = this.apiKeys;
    return apiKeys.pdHubL1Mandant;
  }

  async pdHubUserToken() {
    const userConfig = store.getters.user.config;
    const token = userConfig.pdHub.token;
    if (token === "") {
      DialogService.alert("PD-Hub Token nicht vorhanden");
      return null;
    }
    const tokenValidTill = userConfig.pdHub.tokenValidTill;

    if (token && tokenValidTill) {
      if (moment().isBefore(moment(tokenValidTill))) {
        return token;
      } else {
        DialogService.alert("PD-Hub Token abgelaufen");
        return null;
      }
    }
  }

  async header() {
    const token = await this.pdHubUserToken();
    if (!token) return null;

    return {
      Authorization: `Bearer ${token}`,
      "X-Customer-Id": await this.pdHubCustomerId(),
      "X-l3c-Mandant": await this.pdHubL1Mandant(),
    };
  }

  // Authorization
  async getAppKey(): Promise<string> {
    const url = `${await this.pdHubBaseURL()}/api/v1/authorize`;
    const body = {
      ClientId: this.apiKeys.pdHubClientId,
      ClientSecret: this.apiKeys.pdHubClientSecret,
    };
    try {
      const response = await axios.post(url, body);
      return response.data.Data.Token;
    } catch (error) {
      throw new Error("Failed to fetch App Key");
    }
  }

  async getUserToken(username: string, password: string): Promise<string> {
    try {
      const appToken = await this.getAppKey();
      const url = `${await this.pdHubBaseURL()}/api/v1/token`;
      const body = {
        Username: username,
        Password: password,
        ApplicationToken: appToken,
      };

      const response = await axios.post(url, body);
      return response.data.Data;
    } catch (error) {
      throw new Error("Failed to fetch User Token");
    }
  }

  //API workflow
  // ######################################################
  // Employee
  async getAllEmployees(filiale?: string): Promise<Employee[]> {
    const headers = await this.header();
    if (!headers) return [];
    if (!filiale) filiale = "";
    const customHeaders = {
      ...headers,
      "X-l3c-Filiale": filiale,
    };

    // Define the expands
    const expands = ["Staatsangehoerigkeit", "Adresse", "Geburtsdaten"];

    // Construct the expand query parameter
    const expandQuery =
      expands.length > 0 ? `$expand=${expands.join(",")}` : "";

    const url = `${await this.pdHubBaseURL()}/service/PDHub/personal/api/v1/Personal${
      expandQuery ? `?${expandQuery}` : ""
    }`;

    try {
      const response = await axios.get(url, { headers: customHeaders });
      return PdHubAdapter.transformToEmployeeModel(response.data.Data);
    } catch (error) {
      console.error("Error fetching employees:", error);
      return [];
    }
  }

  async getByEmployeeNumber(
    employeeNumber: string,
    filiale?: string
  ): Promise<Employee | null> {
    const headers = await this.header();
    if (!headers) return null;
    if (!filiale) filiale = "";
    const customHeaders = {
      ...headers,
      "X-l3c-Filiale": filiale,
    };

    const expands = ["Staatsangehoerigkeit", "Adresse", "Geburtsdaten"];
    const expandQuery =
      expands.length > 0 ? `$expand=${expands.join(",")}` : "";
    const filterQuery = `$filter=PersonalNr eq ${employeeNumber}`;
    const url = `${await this.pdHubBaseURL()}/service/PDHub/personal/api/v1/Personal?${expandQuery}&${filterQuery}`;

    try {
      const response = await axios.get(url, { headers: customHeaders });
      if (response.data.Data.length === 0) {
        return null; // No employee found
      }
      const employees = PdHubAdapter.transformToEmployeeModel(
        response.data.Data
      );
      return employees.length > 0 ? employees[0] : null;
    } catch (error) {
      console.debug("Error fetching employee by number:", error);
      return null;
    }
  }

  async getPlacements(
    employeeOrCustomerNumber: string,
    isCustomer?: boolean,
    isNoTimeFilter?: boolean
  ): Promise<Assignment[]> {
    const headers = await this.header();
    if (!headers) return [];

    const customHeaders = {
      ...headers,
    };

    let filterQuery = `$filter=PersonalNr eq ${employeeOrCustomerNumber}`;
    if (isCustomer)
      filterQuery = `$filter=KundenNr eq ${employeeOrCustomerNumber}`;
    const url = `${await this.pdHubBaseURL()}/service/PDHub/auftrag/api/v1/Einsaetze?${filterQuery}`;

    try {
      const response = await axios.get(url, { headers: customHeaders });
      if (response.data.Data.length === 0) {
        return []; // No assignments found
      }

      const assignments = PdHubAdapter.transformToAssignmentModel(
        response.data.Data,
        isCustomer,
        isNoTimeFilter
      );
      return assignments;
    } catch (error) {
      console.debug("Error fetching placements by employee number:", error);
      return [];
    }
  }

  async createTimelineEntryEmployee(
    timelineEntry: L1EmployeeTimelineEntry,
    filiale?: string
  ): Promise<void> {
    const headers = await this.header();
    if (!headers) return;
    if (!filiale) filiale = "";
    const customHeaders = {
      ...headers,
      "X-l3c-Filiale": filiale,
    };

    const url = `${await this.pdHubBaseURL()}/service/PDHub/personal/api/v1/Personal/KontaktAnlegen`;

    try {
      await axios.post(url, timelineEntry, {
        headers: customHeaders,
      });
    } catch (error: any) {
      if (error.response.data.type === "KontaktAnlegenException") {
        ToastService.showError("Kontaktart nicht hinterlegt");
      } else {
        ToastService.showError("Fehler beim Anlegen des Kontakts");
      }
      throw new Error("Failed to create timeline entry for employee");
    }
  }
  async addCandidate(candidate: Candidate, mandantUuid: string): Promise<any> {
    const headers = await this.header();
    if (!headers) return null;

    const customHeaders = {
      ...headers,
    };

    const url = `${await this.pdHubBaseURL()}/service/PDHub/personal/api/v1/Personal/BewerberAnlegen`;

    const pdHubCandidate = PdHubAdapter.candidateToPdHubCandidate(
      candidate,
      mandantUuid
    );

    try {
      const response = await axios.post(url, pdHubCandidate, {
        headers: customHeaders,
      });
      return response.data;
    } catch (error) {
      console.error("Error adding candidate:", error);
      return null;
    }
  }
  /* 
  WORKS ONLY WITH STATUS APPLICANT NOT WITH EMPLOYEES: maybe coming feature from PD-Hub
  async patchEmployee(employee: ZvooveEmployee): Promise<any> {
    const pdHubEmployee = PdHubAdapter.transformToPdHubEmployeeModel(employee);
    const headers = await this.header();
    if (!headers) return null;

    const url = `${await this.pdHubBaseURL()}/service/PDHub/personal/api/v1/Personal/BewerberAendern/${
      employee.id
    }`;

    try {
      const response = await axios.patch(url, pdHubEmployee, { headers });
      return response.data.Data;
    } catch (error) {
      console.error("Error patching employee in PD-Hub", error);
      throw error;
    }
  }
  */

  // ######################################################
  // Customer
  async getAllCustomers(filiale?: string): Promise<Customer[] | void> {
    const headers = await this.header();
    if (!headers) return [];
    if (!filiale) filiale = "";
    const customHeaders = {
      ...headers,
      "X-l3c-Filiale": filiale,
    };

    // Define the expands
    const expands = [
      "Adressen($expand=Staat)",
      "Ansprechpartner",
      "Bundesland",
    ];

    // Construct the expand query parameter
    const expandQuery =
      expands.length > 0 ? `$expand=${expands.join(",")}` : "";

    const url = `${await this.pdHubBaseURL()}/service/PDHub/kunden/api/v1/Kunden${
      expandQuery ? `?${expandQuery}` : ""
    }`;

    try {
      const response = await axios.get(url, { headers: customHeaders });
      return PdHubAdapter.transformToCustomerModel(response.data.Data);
    } catch (error) {
      console.error("Error fetching customers:", error);
      return [];
    }
  }

  async getByCustomerNumber(
    customerNumber: string,
    filiale?: string
  ): Promise<Customer | null> {
    const headers = await this.header();
    if (!headers) return null;
    if (!filiale) filiale = "";
    const customHeaders = {
      ...headers,
      "X-l3c-Filiale": filiale,
    };

    const expands = [
      "Adressen($expand=Staat)",
      "Ansprechpartner",
      "Bundesland",
    ];
    const expandQuery =
      expands.length > 0 ? `$expand=${expands.join(",")}` : "";
    const filterQuery = `$filter=kundennr eq ${customerNumber}`;
    const url = `${await this.pdHubBaseURL()}/service/PDHub/kunden/api/v1/Kunden?${expandQuery}&${filterQuery}`;

    try {
      const response = await axios.get(url, { headers: customHeaders });
      if (response.data.Data.length === 0) {
        return null;
      }
      const customers = PdHubAdapter.transformToCustomerModel(
        response.data.Data
      );
      return customers.length > 0 ? customers[0] : null;
    } catch (error) {
      console.error("Error fetching customer by number:", error);
      return null;
    }
  }

  async createCustomer(newCustomer: Customer): Promise<any> {
    if (
      !store.getters.getLoggedInMandantBranchNumbers ||
      store.getters.getLoggedInMandantBranchNumbers.length === 0
    ) {
      ToastService.showError(
        "keine eingeloggte Niederlassung um den Kunden anzulegen"
      );
      return Promise.resolve();
    }
    const pdHubCustomer = PdHubAdapter.transformToPdHubModel(newCustomer);
    const headers = await this.header();
    if (!headers) return null;

    const url = `${await this.pdHubBaseURL()}/service/PDHub/kunden/api/v1/Kunden/InteressentAnlegen`;

    try {
      const response = await axios.post(url, pdHubCustomer, { headers });
      return response.data.Data;
    } catch (error) {
      console.error("Error posting customer in PD-Hub", error);
      throw error;
    }
  }

  async createTimelineEntryCustomer(
    timelineEntry: L1CustomerTimelineEntry,
    filiale?: string
  ): Promise<void> {
    const headers = await this.header();
    if (!headers) return;
    if (!filiale) filiale = "";
    const customHeaders = {
      ...headers,
      "X-l3c-Filiale": filiale,
    };

    const url = `${await this.pdHubBaseURL()}/service/PDHub/kunden/api/v1/Kunden/KontaktAnlegen`;

    try {
      await axios.post(url, timelineEntry, {
        headers: customHeaders,
      });
    } catch (error: any) {
      if (error.response.data.type === "KontaktAnlegenException") {
        ToastService.showError("Kontaktart nicht hinterlegt");
      }
      console.error("Error creating timeline entry for customer:", error);
      throw new Error("Failed to create timeline entry for customer");
    }
  }

  async patchCustomer(customer: Customer): Promise<any> {
    const pdHubCustomer = PdHubAdapter.transformToPdHubCustomerModel(customer);
    const headers = await this.header();
    if (!headers) return null;

    const url = `${await this.pdHubBaseURL()}/service/PDHub/kunden/api/v1/Kunden/${
      customer.customerNumber
    }`;

    try {
      const response = await axios.patch(url, pdHubCustomer, { headers });
      await this.updateCustomerAddresses(customer);
      return response.data.Data;
    } catch (error) {
      console.error("Error patching customer in PD-Hub", error);
      throw error;
    }
  }

  async updateCustomerAddresses(customer: Customer): Promise<void> {
    const customerNumber = customer.customerNumber;
    const headers = await this.header();
    if (!headers) return;

    const url = `${await this.pdHubBaseURL()}/service/PDHub/kunden/api/v1/Kunden?$expand=Adressen&$filter=kundennr eq ${customerNumber}`;

    try {
      const response = await axios.get(url, { headers });
      const addresses = response.data.Data[0].Adressen;
      const postAddress = addresses.find(
        (address: any) => address.Typ === "Postanschrift"
      );
      const invoiceAddress = addresses.find(
        (address: any) => address.Typ === "Rechnungsanschrift"
      );

      if (postAddress) {
        await this.patchCustomerAddress(
          customer,
          postAddress.Nummer,
          "Postanschrift"
        );
      }
      if (invoiceAddress) {
        await this.patchCustomerAddress(
          customer,
          invoiceAddress.Nummer,
          "Rechnungsanschrift"
        );
      }
    } catch (error) {
      console.error("Error fetching addresses for customer in PD-Hub", error);
    }
  }

  async patchCustomerAddress(
    customer: Customer,
    addressNumber: number,
    addressType: string,
    filiale?: string
  ): Promise<any> {
    const headers = await this.header();
    if (!headers) return;

    const customerNumber = customer.customerNumber;
    const customHeaders = {
      ...headers,
      "X-l3c-Filiale": filiale || "",
    };

    const addressData = {
      Name1: customer.generalData.name,
      Name2: customer.generalData.name2 ?? "",
      Name3: customer.generalData.name3 ?? "",
      Strasse: customer.addressAndCommunication.street ?? "",
      Plz: customer.addressAndCommunication.postalCode ?? "",
      Ort: customer.addressAndCommunication.city ?? "",
      Telefon1: customer.addressAndCommunication.phone1 ?? "",
      Telefon2: customer.addressAndCommunication.phone2 ?? "",
      Telefax: "",
      Email: customer.addressAndCommunication.email ?? "",
      Homepage: customer.addressAndCommunication.website ?? "",
    };

    const url = `${await this.pdHubBaseURL()}/service/PDHub/kunden/api/v1/Kunden/${customerNumber}/adresse/${addressNumber}`;

    try {
      const response = await axios.patch(url, addressData, {
        headers: customHeaders,
      });
      return response.data.Data;
    } catch (error) {
      console.error(
        `Error patching ${addressType} address for customer in PD-Hub`,
        error
      );
      throw error;
    }
  }

  // ########################################################
  // Method to fetch and initialize PD-Hub data
  async fetchPdHubInitData(
    erpOptions: ErpFetchOption[],
    filiale?: string
  ): Promise<void> {
    let message = "";
    let errorMessage = "";
    let customerList: CustomerList[] = [];
    let employeeList: EmployeeList[] = [];
    let activeCustomers: Customer[] = [];
    let activeEmployees: Employee[] = [];
    let customers: Customer[] = [];
    let employees: Employee[] = [];

    try {
      if (
        erpOptions.includes(ErpFetchOption.CustomerList) ||
        erpOptions.includes(ErpFetchOption.Customers)
      ) {
        const fetchedCustomers = await this.getAllCustomers(filiale);
        customers = fetchedCustomers || [];
      }
      if (erpOptions.includes(ErpFetchOption.CustomerList))
        customerList = customers.map(PdHubAdapter.transformToCustomerList);
      if (erpOptions.includes(ErpFetchOption.Customers)) {
        const activeCustomerStatus =
          getCustomerStates(CustomerStates)[CustomerStates.Customer];
        activeCustomers = customers.filter(
          (customer: Customer) =>
            customer.generalData.status === activeCustomerStatus
        );
      }
    } catch (error) {
      console.error("Error fetching customers:", error);
      errorMessage += "Error fetching customers. ";
    }

    try {
      if (
        erpOptions.includes(ErpFetchOption.EmployeeList) ||
        erpOptions.includes(ErpFetchOption.Employees)
      ) {
        const fetchedEmployees = await this.getAllEmployees(filiale);
        employees = fetchedEmployees || [];
      }
      if (erpOptions.includes(ErpFetchOption.EmployeeList))
        employeeList = employees.map(PdHubAdapter.transformToEmployeeList);
      if (erpOptions.includes(ErpFetchOption.Employees)) {
        activeEmployees = employees.filter(
          (employee) => employee.employeeStatus === 2
        );
      }
    } catch (error) {
      console.error("Error fetching employees:", error);
      errorMessage += "Error fetching employees. ";
    }

    try {
      const employeeService = new EmployeeService();
      if (erpOptions.includes(ErpFetchOption.EmployeeList))
        await employeeService.saveList(employeeList);
      if (erpOptions.includes(ErpFetchOption.Employees))
        await employeeService.addAllEmployees(activeEmployees);
    } catch (error) {
      console.error("Error saving employees:", error);
      errorMessage += "Error saving employees. ";
    }

    try {
      const customerService = new CustomerService();
      if (erpOptions.includes(ErpFetchOption.CustomerList))
        await customerService.saveList(customerList as CustomerList[]);
      if (erpOptions.includes(ErpFetchOption.Customers))
        await customerService.addCustomers(customers);
    } catch (error) {
      console.error("Error saving customers:", error);
      errorMessage += "Error saving customers. ";
    }

    try {
      if (erpOptions.includes(ErpFetchOption.CustomerList))
        store.commit("SET_ZVOOVE_CUSTOMER_LIST", customerList);
      if (erpOptions.includes(ErpFetchOption.EmployeeList))
        store.commit("SET_ZVOOVE_EMPLOYEE_LIST", employeeList);
    } catch (error) {
      console.error("Error committing to store:", error);
      errorMessage += "Error committing to store. ";
    }

    message += `${customerList.length} Unternehmensdaten, `;
    message += `${employeeList.length} Personaldaten, `;
    message += `${activeCustomers.length} aktive Kunden und `;
    message += `${activeEmployees.length} aktive Mitarbeiter erhalten.`;

    ToastService.showReminder(message);
    if (errorMessage) ToastService.showError(errorMessage);
  }
  async fetchCustomerData(filiale?: string): Promise<Customer[]> {
    let activeCustomers: any[] = [];

    try {
      const fetchedCustomers = await this.getAllCustomers(filiale);
      const customers: Customer[] = fetchedCustomers || [];
      const activeCustomerStatus =
        getCustomerStates(CustomerStates)[CustomerStates.Customer];
      activeCustomers = customers.filter(
        (customer: Customer) =>
          customer.generalData.status === activeCustomerStatus
      );
    } catch (error) {
      console.error("Error fetching customers:", error);
      ToastService.showError("Error fetching customers.");
    }

    return activeCustomers;
  }
}
