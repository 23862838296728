//src/board-filters.enum.ts
export enum FilterCandidates {
  Lastname = "Nachname",
  ApplicationDate = "Bewerbungsdatum",
  ApplicationId = "Bewerbungs-ID",
  AppliedAs = "Beworben Als",
  Postcode = "Postleitzahl",
}

export enum FilterCustomers {
  Name = "Name",
  HasJobAds = "Anzahl ausgeschriebener Stellen",
  HasAssignments = "Anzahl Einsätze",
  CustomerNumber = "Kundennumer",
  LastEdited = "Letzte Bearbeitung",
  Postcode = "Postleitzahl",
}
