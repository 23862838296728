<template>
  <ValidateDeletionDialog
    :show="showValidateDeletionDialog"
    :email="deleteUserId"
    @validate="deleteUser"
    @update:show="updateValidateDeletionDialogVisibility"
  />
  <UserDialog
    :mandants="mandants"
    :show="showUserDialog"
    :editMode="editMode"
    :editUserModel="userModel"
    @update:show="updateDialogVisibility"
    @click:outside="handleBackgroundClick"
  />
  <AdminMenu />
  <PdMasterHeader v-if="layout === AppLayout.pdmaster"></PdMasterHeader>
  <v-container :fluid="true">
    <div class="d-flex flex-row-reverse mt-5">
      <v-btn
        :variant="vStyle.btn.variant || undefined"
        :rounded="vStyle.btn.rounded || undefined"
        :border="vStyle.btn.border || undefined"
        v-if="getUserRole() < 11"
        size="large"
        @click="addUser()"
      >
        <v-icon icon="fa-solid fa-plus" />
      </v-btn>
    </div>
    <v-row v-if="getUserRole() < 11" no-gutters>
      <v-col
        v-for="user in users"
        :key="user.email"
        :name="user.lastname"
        cols="12"
        sm="3"
      >
        <v-card
          :variant="vStyle.card.variant || undefined"
          :rounded="vStyle.card.rounded || undefined"
          :border="vStyle.card.border || undefined"
          :title="`${getSalutation(user.salutation)} ${
            user.forename || 'Daten'
          } ${user.lastname || 'eintragen'} `"
          :subtitle="user.email"
          class="usercard"
        >
          <v-card-text class="d-flex">
            <v-row>
              <v-col cols="12" sm="4">
                <div><i class="fa-solid fa-phone"></i>&nbsp;Telefon:</div>
                <div>
                  <i class="fa-solid fa-mobile-screen-button"></i
                  >&nbsp;&nbsp;Mobil:
                </div>
              </v-col>
              <v-col cols="12" sm="8">
                <div>{{ user.tel }}</div>
                <div>{{ user.mobilePhone }}</div>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions class="d-flex justify-space-between">
            <v-btn
              v-if="getUserRole() < 11"
              color="success"
              @click="editUser(user)"
              >Bearbeiten</v-btn
            >
            <v-btn
              v-if="getUserRole() < 6"
              color="abort"
              @click="removeUser(user)"
              >Löschen</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <div v-if="users.length === 0" class="no-entries">
      Keine Eintr&auml;ge vorhanden
    </div>
  </v-container>
</template>

<script lang="ts">
import { UserService } from "@/services/api/user.service";
import { MandantService } from "@/services/api/mandant.service";
import { User } from "@/models/user.model";
import AdminMenu from "@/components/menus/admin-menu.component.vue";
import UserDialog from "@/components/config/user/dialogs/UserDialog.vue";
import ValidateDeletionDialog from "@/components/modals/validate-deletion.modal.vue";
import { defineComponent } from "vue";
import { Salutation } from "@/enums/salutation.model";
import { Mandant } from "@/models/mandant.model";
import { UserHelperService } from "@/services/user-helper.service";
import PdMasterHeader from "@/components/header/PdMasterHeader.vue";
import { AppLayout } from "@/enums/app-layout.enum";

export default defineComponent({
  name: "ConfigUser",
  components: {
    AdminMenu,
    PdMasterHeader,
    UserDialog,
    ValidateDeletionDialog,
  },
  data() {
    return {
      AppLayout: AppLayout,
      layout: this.$store.getters.getEnv.VUE_APP_LAYOUT,
      deleteUserId: "",
      editMode: false,
      mandants: [] as Mandant[],
      mandantService: new MandantService(),
      showUserDialog: false,
      showValidateDeletionDialog: false,
      userModel: undefined as undefined | User,
      users: [] as User[],
      userService: new UserService(),
      vStyle: this.$store.state.vStyle,
    };
  },
  created(): void {
    this.loadUsers();
    this.loadMandants();
  },
  methods: {
    addUser() {
      this.editMode = false;
      this.userModel = Object.assign({});
      this.showUserDialog = true;
    },

    getUserRole() {
      const userService = UserHelperService.getInstance();
      const userRole = userService.getUserRole();
      return userRole;
    },

    editUser(user: User) {
      this.editMode = true;
      this.userModel = user;
      this.showUserDialog = true;
    },

    getSalutation(salutation: number): string {
      return salutation ? Salutation[salutation] : "Admin";
    },

    handleBackgroundClick() {
      this.showUserDialog = false;
    },

    async loadMandants() {
      await this.mandantService.getMandants().then((mandants: any) => {
        this.mandants = Object.assign(mandants);
      });
    },

    removeUser(user: User) {
      this.deleteUserId = user._id as string;
      this.showValidateDeletionDialog = true;
    },

    async deleteUser(id: string) {
      const result = await this.userService.removeUser(id);
      this.loadUsers();
      console.log(result);
    },

    loadUsers() {
      this.userService
        .getUsers()
        .then((users: User[]) => (this.users = Object.assign(users)));
    },

    updateDialogVisibility(newValue: boolean) {
      this.showUserDialog = newValue;
      this.loadUsers();
    },

    updateValidateDeletionDialogVisibility(newValue: boolean) {
      this.showValidateDeletionDialog = newValue;
      this.loadUsers();
    },
  },
});
</script>
<style scoped>
:root {
  --color-blue: #5a739c;
}

.no-entries {
  font-size: x-large;
  text-align: center;
  width: 100%;
}

.usercard {
  background-color: var(--color-item);
  margin-right: 1rem;
}

.card-content {
  font-size: 0.9rem;
  padding: 0 1rem;
  display: flex;
  justify-content: space-between;
}

.add-user {
  display: flex;
  justify-content: end;
  padding-right: 1rem;
}
</style>
