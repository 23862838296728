// show pdf file in a new tab
export function showPdf(data: any, fileName: string) {
  const url = window.URL.createObjectURL(new Blob([data]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", `${fileName}.pdf`);
  document.body.appendChild(link);
  link.click();

  document.body.removeChild(link);
  window.URL.revokeObjectURL(url);
}

export function blobToBase64(blob: Blob): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result as string);
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });
}

export function base64ToBlob(
  base64: string,
  mimeType = "application/pdf"
): Blob {
  // Entferne den Data-URL-Header, falls vorhanden
  const base64WithoutPrefix = base64.includes(",")
    ? base64.split(",")[1]
    : base64;

  // Dekodiere den Base64-String in eine Byte-Sequenz
  const byteCharacters = atob(base64WithoutPrefix);
  const byteNumbers = new Array(byteCharacters.length);

  // Erzeuge einen Uint8Array aus den Byte-Zahlen
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }

  const byteArray = new Uint8Array(byteNumbers);

  // Erstelle den Blob aus dem Byte-Array
  return new Blob([byteArray], { type: mimeType });
}

//maximum flexible if user creates tables inside tables in ckEditor:
export function convertColWidthsToMm(html: string): string {
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, "text/html");

  // Start processing with body and initial width (e.g., 195mm)
  const body = doc.body;
  const margin = 5;
  const a4Width = 210;
  const initialWidthMm = a4Width - margin;
  processElement(body, initialWidthMm);

  // Return updated HTML
  return doc.documentElement.outerHTML;
}

// Updates the context width for <figure> or <table> elements
function updateContextWidth(element: Element, contextWidthMm: number): number {
  let elementWidthMm = contextWidthMm; // Default to parent width

  const style = element.getAttribute("style");
  if (style) {
    const widthMatch = /width:\s*([\d.]+)(mm|%)/.exec(style);
    if (widthMatch) {
      const widthValue = parseFloat(widthMatch[1]);
      const widthUnit = widthMatch[2];

      if (widthUnit === "mm") {
        elementWidthMm = widthValue;
      } else if (widthUnit === "%") {
        elementWidthMm = (widthValue / 100) * contextWidthMm;
        elementWidthMm = Math.round(elementWidthMm * 100) / 100; // Round to two decimals
        element.setAttribute(
          "style",
          style.replace(/width:\s*[\d.]+%/, `width: ${elementWidthMm}mm`)
        );
      }
    }
  } else {
    // If no width style exists, assume 100% of contextWidth
    elementWidthMm = contextWidthMm;
    element.setAttribute("style", `width: ${elementWidthMm}mm`);
  }

  return elementWidthMm;
}

// Extracts column widths from a <colgroup> element and rounds them
function getColumnWidths(
  tableElement: Element,
  tableWidthMm: number
): number[] {
  const colWidths: number[] = [];
  const colgroup = tableElement.querySelector("colgroup");
  if (colgroup) {
    const cols = colgroup.querySelectorAll("col");
    cols.forEach((col) => {
      const style = col.getAttribute("style");
      let colWidthMm = Math.round(tableWidthMm / cols.length); // Default width, rounded

      if (style) {
        const widthMatch = /width:\s*([\d.]+)(mm|%)/.exec(style);
        if (widthMatch) {
          const widthValue = parseFloat(widthMatch[1]);
          const widthUnit = widthMatch[2];

          if (widthUnit === "mm") {
            colWidthMm = Math.round(widthValue); // Round to nearest mm
          } else if (widthUnit === "%") {
            colWidthMm = (widthValue / 100) * tableWidthMm;
            colWidthMm = Math.round(colWidthMm); // Round to nearest mm
            col.setAttribute(
              "style",
              style.replace(/width:\s*[\d.]+%/, `width: ${colWidthMm}mm`)
            );
          }
        }
      }
      colWidths.push(colWidthMm);
    });
  }

  return colWidths;
}

// Processes rows (tr) and cells (td, th) and updates their widths
function processRowElements(
  rowElement: Element,
  contextWidthMm: number,
  colWidths?: number[]
) {
  let colIndex = 0;
  Array.from(rowElement.children).forEach((cell) => {
    if (
      cell.tagName.toLowerCase() === "td" ||
      cell.tagName.toLowerCase() === "th"
    ) {
      const cellWidth =
        colWidths && colWidths[colIndex] !== undefined
          ? colWidths[colIndex]
          : contextWidthMm;
      processElement(cell, cellWidth);
      colIndex++;
    } else {
      processElement(cell, contextWidthMm, colWidths);
    }
  });
}

// Recursive function to process elements
function processElement(
  element: Element,
  contextWidthMm: number,
  colWidths?: number[]
) {
  if (
    element.tagName.toLowerCase() === "figure" &&
    element.classList.contains("table")
  ) {
    const figureWidthMm = updateContextWidth(element, contextWidthMm);
    Array.from(element.children).forEach((child) =>
      processElement(child, figureWidthMm)
    );
    return;
  }

  if (element.tagName.toLowerCase() === "table") {
    const tableWidthMm = updateContextWidth(element, contextWidthMm);
    const colWidths = getColumnWidths(element, tableWidthMm);
    element.setAttribute("style", `width: ${tableWidthMm}mm`); // Set table width in mm
    Array.from(element.children).forEach((child) =>
      processElement(child, tableWidthMm, colWidths)
    );
    return;
  }

  if (
    element.tagName.toLowerCase() === "tbody" ||
    element.tagName.toLowerCase() === "thead"
  ) {
    Array.from(element.children).forEach((child) =>
      processElement(child, contextWidthMm, colWidths)
    );
    return;
  }

  if (element.tagName.toLowerCase() === "tr") {
    processRowElements(element, contextWidthMm, colWidths);
    return;
  }

  if (
    element.tagName.toLowerCase() === "td" ||
    element.tagName.toLowerCase() === "th"
  ) {
    Array.from(element.children).forEach((child) =>
      processElement(child, contextWidthMm)
    );
    return;
  }

  Array.from(element.children).forEach((child) =>
    processElement(child, contextWidthMm)
  );
}
