export enum Routes {
  dashboard = "/dashboard",
  dataCleaning = "/config/data-cleaning",
  configCompany = "/config/company",
  configMandant = "/config/mandant",
  configUser = "/config/user",
  configAi = "/config/ai",
  configApi = "/config/software-integration",
  version = "/version",
  registration = "/registration",
  registrationSuccess = "/registration-success",
  recruiting = "/recruiting",
  dispostion = "/disposition",
  administration = "/data",
  subscription = "/subscription",
  logout = "/logout",
}
