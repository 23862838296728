// src/services/jobPoster.service.ts
import { AtsRecruitService } from "./api/api-integration-one.service";
import { ChatGPT, updateOpenAiConfig } from "./openai.service";
import { JobposterState } from "@/enums/jobposterstate.enum";
import { Mandant } from "@/models/mandant.model";
import { SpinnerService } from "@/services/spinner.service";
import { ZorstService } from "./zorst.service";
import DialogService from "@/services/dialog.service";
import store from "../store/store";
import ToastService from "./toast.service";
import { JobAdService } from "./api/job-ad.service";
import { jobAdType, JobLocation, PostingStatus } from "@/models/job-ad.model";

export class JobPosterService {
  private atsRecruitService = new AtsRecruitService();
  private AImodel = "gpt-3.5-turbo-16k";
  private collectiveAgreement = "GVP"; // TODO: in company config
  private templateData = ""; // later from the backend
  private weAre = ""; // later from the backend
  private mandants: Mandant[] = [];
  private zvooveRecruit = false;
  private jobAdService = new JobAdService();
  private locations = [] as JobLocation[];

  constructor() {
    updateOpenAiConfig();
    this.updateStoreVariables();
  }

  private getLocationById(id: string) {
    const location = this.locations.find(
      (location: JobLocation) => location._id === id
    );
    return location || null;
  }

  private updateStoreVariables() {
    this.templateData = JSON.stringify(
      store.state.company.aiData.template.jobAd,
      null,
      2
    ); // later from the backend
    this.weAre = store.state.company.aiData.prompt.company.weAre; // later from the backend
    this.mandants = store.state.company.mandants;
    this.zvooveRecruit = store.state.company.softwareIntegration.zvooveRecruit;
  }

  private getSalaryRange(
    salaryFrom: number | null,
    salaryTo: number | null,
    salaryPeriod: string
  ): string {
    let salaryRange = " ";

    if ((salaryFrom && salaryFrom > 0) || (salaryTo && salaryTo > 0)) {
      salaryRange = `${salaryFrom} bis ${salaryTo} ${salaryPeriod}`;
    }

    return salaryRange;
  }

  private delay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  public async generateJobAd(jobAd: {
    jobTitle: string;
    postalCode: number | null;
    city: string;
    tasks: string;
    profile: string;
    salaryFrom: number | null;
    salaryTo: number | null;
    salaryPeriod: string;
    mandantUuid: string;
  }) {
    SpinnerService.setHeaderSpinnerElement(1, "recurion0.gif");
    updateOpenAiConfig();

    jobAd.tasks = jobAd.tasks
      ? `, die Aufgaben der Stelle sind: ${jobAd.tasks}. Wenn die Aufgaben weniger als vier sind, ergänze bitte 4 typische Aufgaben, die zur Stellenbezeichnung des ${jobAd.jobTitle} passen.`
      : "";
    jobAd.profile = jobAd.profile
      ? `, die Anforderungen der Stelle sind: ${jobAd.profile}. Wenn die Anforderungen weniger als vier sind, ergänze bitte 4 typische Anforderungen, die zur Stellenbezeichnung des ${jobAd.jobTitle} passen.`
      : "";

    const prompt = `
      ${this.weAre}
      Bitte erstelle eine Stellenanzeige für die Position eines ${jobAd.jobTitle} in ${jobAd.postalCode} ${jobAd.city}${jobAd.tasks}${jobAd.profile} basierend auf der folgender Vorlage:
      ${this.templateData}
      Bitte passe jeden in der Vorlage vorhandenen Textabschnitt so an, dass der Sinn erhalten bleibt, attraktiv für Bewerber und suchmaschinenoptimiert ist. Passe auch den Titel '${jobAd.jobTitle}' so an, dass er kurz und attraktiv ist, gut klingt und (m/w/d) enthält! Der Titel der Stellenanzeige soll nur den Beruf enthalten und keine Angaben über Ort, Abteilung oder sonstiges!

      Gib deine Antwort exakt in folgenden JSON-Format aus, damit ein Programm die verarbeiten kann.
      Formatvorlage für Deine Antwort:
      {
        "Bezeichnung": "DEIN TEXT",
        "StellenzielHeader": "DEIN TEXT",
        "Stellenziel": "DEIN TEXT",
        "AufgabenHeader": "DEIN TEXT",
        "Aufgaben": "DEIN TEXT",
        "PerspektivenHeader": "DEIN TEXT",
        "Perspektiven": "DEIN TEXT",
        "UnternehmensbedeutungHeader": "DEIN TEXT",
        "Unternehmensbedeutung": "DEIN TEXT",
        "FachlicheAnforderungenHeader": "DEIN TEXT",
        "FachlicheAnforderungen": "DEIN TEXT",
        "PersoenlicheAnforderungenHeader": "DEIN TEXT",
        "PersoenlicheAnforderungen": "DEIN TEXT",
        "ArbeitgeberleistungHeader": "DEIN TEXT",
        "Arbeitgeberleistung": "DEIN TEXT"
      }
    `;
    try {
      const response = await ChatGPT({
        prompt: prompt,
        model: this.AImodel,
        temperature: 0.7,
        role: "user",
      });
      const generatedText = response.choices[0].message.content;

      const startIndex = generatedText.indexOf("{");
      const endIndex = generatedText.lastIndexOf("}") + 1;
      const jsonPart = generatedText.substring(startIndex, endIndex);
      const parsedResponse = JSON.parse(jsonPart);

      const mandant = this.mandants.find(
        (m: Mandant) => m.uuid === jobAd.mandantUuid
      );
      const salaryRange = this.getSalaryRange(
        jobAd.salaryFrom,
        jobAd.salaryTo,
        jobAd.salaryPeriod
      );
      const whatsApp = mandant?.whatsApp
        ? `Am besten direkt per Messenger bewerben: WhatsApp: 💬${mandant?.whatsApp}`
        : "";

      const generatedJobAd = {
        Bezeichnung: parsedResponse.Bezeichnung,
        ArbeitgebervorstellungHeader: salaryRange,
        Arbeitgebervorstellung: whatsApp,
        EinsatzortOrt: jobAd.city,
        EinsatzortPlz: jobAd.postalCode,
        EmailEingangskontoId: mandant?.EmailEingangskontoId,
        StellenzielHeader: parsedResponse.StellenzielHeader,
        Stellenziel: parsedResponse.Stellenziel,
        AufgabenHeader: parsedResponse.AufgabenHeader,
        Aufgaben: parsedResponse.Aufgaben,
        PerspektivenHeader: parsedResponse.PerspektivenHeader,
        Perspektiven: parsedResponse.Perspektiven,
        UnternehmensbedeutungHeader: parsedResponse.UnternehmensbedeutungHeader,
        Unternehmensbedeutung: parsedResponse.Unternehmensbedeutung,
        FachlicheAnforderungenHeader:
          parsedResponse.FachlicheAnforderungenHeader,
        FachlicheAnforderungen: parsedResponse.FachlicheAnforderungen,
        PersoenlicheAnforderungenHeader:
          parsedResponse.PersoenlicheAnforderungenHeader,
        PersoenlicheAnforderungen: parsedResponse.PersoenlicheAnforderungen,
        ArbeitgeberleistungHeader: parsedResponse.ArbeitgeberleistungHeader,
        Arbeitgeberleistung: parsedResponse.Arbeitgeberleistung,
        KontaktTextHeader: mandant?.name,
        KontaktText: mandant?.contact,
        Mandant: { ObjectUuid: jobAd.mandantUuid },
      };

      return generatedJobAd;
    } catch (error: any) {
      console.error(
        "Fehler beim Aufruf von ChatGPT:",
        error.response ? error.response.data : error.message
      );
      throw error;
    } finally {
      SpinnerService.clearHeaderSpinnerElement();
    }
  }

  public async postJobAdToZvoove(generatedJobAd: any) {
    const parameter = {
      Bezeichnung: generatedJobAd.Bezeichnung,
      ArbeitgebervorstellungHeader: generatedJobAd.ArbeitgebervorstellungHeader,
      Arbeitgebervorstellung: generatedJobAd.Arbeitgebervorstellung,
      EinsatzortOrt: generatedJobAd.EinsatzortOrt,
      EinsatzortPlz: generatedJobAd.EinsatzortPlz.toString(),
      EmailEingangskontoId: generatedJobAd.EmailEingangskontoId,
      StellenzielHeader: generatedJobAd.StellenzielHeader,
      Stellenziel: generatedJobAd.Stellenziel.replace(/\r?\n/g, "<br>"),
      AufgabenHeader: generatedJobAd.AufgabenHeader,
      Aufgaben: generatedJobAd.Aufgaben.replace(/\r?\n/g, "<br>"),
      PerspektivenHeader: generatedJobAd.PerspektivenHeader,
      Perspektiven: generatedJobAd.Perspektiven.replace(/\r?\n/g, "<br>"),
      UnternehmensbedeutungHeader: generatedJobAd.UnternehmensbedeutungHeader,
      Unternehmensbedeutung: generatedJobAd.Unternehmensbedeutung.replace(
        /\r?\n/g,
        "<br>"
      ),
      FachlicheAnforderungenHeader: generatedJobAd.FachlicheAnforderungenHeader,
      FachlicheAnforderungen: generatedJobAd.FachlicheAnforderungen.replace(
        /\r?\n/g,
        "<br>"
      ),
      PersoenlicheAnforderungenHeader:
        generatedJobAd.PersoenlicheAnforderungenHeader,
      PersoenlicheAnforderungen:
        generatedJobAd.PersoenlicheAnforderungen.replace(/\r?\n/g, "<br>"),
      ArbeitgeberleistungHeader: generatedJobAd.ArbeitgeberleistungHeader,
      Arbeitgeberleistung: generatedJobAd.Arbeitgeberleistung.replace(
        /\r?\n/g,
        "<br>"
      ),
      KontaktTextHeader: generatedJobAd.KontaktTextHeader,
      KontaktText: generatedJobAd.KontaktText,
      Mandant: generatedJobAd.Mandant,
      EinsatzortLand: "Deutschland",
      EinsatzortLandIso002: "DE",
      Tarifvertrag: this.collectiveAgreement,
    };

    try {
      const createResponse = await this.atsRecruitService.postStelleCreate(
        parameter
      );

      if (
        store.state.jobPosterState.jobAdPublishHomepage &&
        createResponse.StelleUuid
      ) {
        const publishParameter = { StelleUuid: createResponse.StelleUuid };
        await this.atsRecruitService.publishStelle(publishParameter);
      }
      return createResponse.StelleUuid;
    } catch (error) {
      console.error("Fehler beim Posten der Stellenanzeige zu Zvoove:", error);
      throw error;
    }
  }

  public async jobPostingLoop(type: jobAdType, mandants?: string[]) {
    this.updateStoreVariables();

    if (store.state.jobPosterState.postingIsCancelled) {
      store.commit("TOGGLE_POSTING_IS_CANCELLED", false);
    }
    store.commit("RESET_POSTED_JOB_ADS");

    this.locations = await this.jobAdService.getLocations();

    let allAds = await this.jobAdService.getRequestedJobAds(type, mandants);
    const totalJobAds = allAds.length;

    ToastService.showReminder(
      `🚀 Ich bin super motiviert und habe derzeit ${totalJobAds} Stellen zum Ausschreiben. 🤖`
    );
    store.commit("SET_TOTAL_JOB_ADS", totalJobAds);

    while (allAds.length > 0) {
      // need new status if many clients posting:
      allAds = await this.jobAdService.getRequestedJobAds(type, mandants);

      if (allAds.length === 0) {
        DialogService.alert("Keine weiteren Stellenanzeigen zum Ausschreiben.");
        store.commit("SET_STATUS", JobposterState.completed);
        break;
      }

      if (type === jobAdType.jobmatrix) this.shuffleArray(allAds);

      const jobAd = allAds[0];

      if (store.state.jobPosterState.postingIsCancelled) {
        store.commit("SET_STATUS", JobposterState.break);
        break;
      }

      while (store.state.jobPosterState.postingIsPaused) {
        store.commit("SET_STATUS", JobposterState.paused);
        await this.delay(1000);
      }

      store.commit("SET_STATUS", JobposterState.posting);

      try {
        if (jobAd._id) {
          const location = await this.getLocationById(jobAd.locationId);
          const adToGenerate = {
            jobTitle: jobAd.title,
            postalCode:
              typeof location?.postalCode === "number"
                ? location.postalCode
                : null,
            city: location?.city ?? "",
            tasks: jobAd.tasks ?? "",
            profile: jobAd.profile ?? "",
            salaryFrom: jobAd.salaryFrom,
            salaryTo: jobAd.salaryTo,
            salaryPeriod: jobAd.salaryPeriod,
            mandantUuid: location?.mandants?.[0] ?? "",
          };

          const generatedJobAd = await this.generateJobAd(adToGenerate);

          if (this.zvooveRecruit) {
            try {
              const recruitJobAdUuid = await this.postJobAdToZvoove(
                generatedJobAd
              );
              jobAd.status = PostingStatus.posted;
              jobAd.uuid = recruitJobAdUuid;

              if (
                (store.state.jobPosterState.jobAdPublishOnBfA ||
                  jobAd.supportedBfAJobAd) &&
                jobAd.categoriesBfA
              ) {
                const zorstService = ZorstService.getInstance();
                const zvooveJobAdLink = `${store.state.company.softwareIntegration.zvooveRecruitLink}/stelle/${recruitJobAdUuid}/publish/shop/free`;
                const zorstJobAdData = {
                  openJobAdLink: zvooveJobAdLink,
                  categoriesBfA: jobAd.categoriesBfA,
                  salary: `${jobAd.salaryFrom} bis ${jobAd.salaryTo} ${jobAd.salaryPeriod}`,
                  placement: "Arbeitnehmerüberlassung",
                  collectiveAgreement: this.collectiveAgreement,
                };
                await zorstService.ZorstPublishJobAdInZvooveRecruit(
                  zorstJobAdData,
                  jobAd.supportedBfAJobAd
                );
              }

              await this.jobAdService.updateJobAd(jobAd);

              allAds = await this.jobAdService.getRequestedJobAds(
                type,
                mandants
              );
              store.commit("POSTED_JOB_ADS", totalJobAds - allAds.length);

              if (store.state.jobPosterState.postedJobAds === totalJobAds) {
                store.commit("SET_STATUS", JobposterState.completed);
                break;
              }
            } catch (error) {
              ToastService.showError(
                "Fehler beim Posten der Stellenanzeige zu Zvoove: " + error
              );
            }
          }
        }
      } catch (error) {
        ToastService.showError(
          "Fehler beim Verarbeiten der Stellenanzeige: " + error
        );
      }
    }
  }

  private shuffleArray(array: any[]): void {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
  }
}
